import React, { useState } from "react";
import Title from "../../components/shared/Title/Title";
import TicketForm from "../../forms/TicketForm";

import { useToasts } from "react-toast-notifications";

import { useHistory } from "react-router-dom";
import axios from "axios";

const NewTicket = () => {
  const { addToast } = useToasts();
  const history = useHistory();

  // Estat amb dades per defecte
  const [ticket, setTicket] = useState({
    assumpte: "",
    descripcio: "",
    telefon: "",
    email: "",
    idPrioritat: "2",
    idServei: "",
    idSubServei: "",
    idEstat: "1",
    contacte: "",
    idAssignat: "",
    involucrats: "",
    idGrup: "",
    idClient: "0",
    idInformador: "2",
    dataPlanificacio: "",
    idCreador: JSON.parse(localStorage.getItem("userLogged")).id,
  });

  // console.log(ticket);
  const [adjunts, setAdjunts] = useState();
  // Al fer clic al botó de Cancelar
  const handleCancel = () => {
    history.push("/tickets");
  };

  const uploadAdjunts = (idTicket) => {
    if (adjunts.length === 0) return

    const formData = new FormData();

    for (let i = 0; i <= adjunts.length; i++) {
      formData.append("adjunts[]", adjunts[i]);
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/tiquet/${idTicket}/adjunts/`,
        formData,
        {
          auth: {
            username: process.env.REACT_APP_API_USER,
            password: process.env.REACT_APP_API_PWD,
          },
          headers: {
            token: JSON.parse(localStorage.getItem("userLogged")).token,
            idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        history.push(`/ticket/${res.data}/`);
      })
      .catch((error) => {
        console.log(error);
      });

    setTicket({
      ...ticket,
      adjunts: formData,
    });
  };

  // Al fer submit del formulari
  const handleSubmit = () => {
    // Insertem el nou ticket a la base de dades (amb les dades del formulari)
     document.getElementById("btnCrearTicket").disabled = true

    const formData = new FormData();

    formData.append("assumpte", ticket.assumpte)
    formData.append("descripcio", ticket.descripcio)
    formData.append("telefon", ticket.telefon)
    formData.append("email", ticket.email)
    formData.append("idPrioritat", ticket.idPrioritat)
    formData.append("idServei", ticket.idServei)
    formData.append("idSubServei", ticket.idSubServei)
    formData.append("idEstat", ticket.idEstat)
    formData.append("contacte", ticket.contacte)
    formData.append("idAssignat", ticket.idAssignat)
    formData.append("involucrats", JSON.stringify(ticket.involucrats))
    formData.append("idGrup", ticket.idGrup)
    formData.append("idClient", ticket.idClient)
    formData.append("idInformador", ticket.idInformador)
    formData.append("dataPlanificacio", ticket.dataPlanificacio)
    formData.append("idCreador", ticket.idCreador)

    if (adjunts) {
      console.log(adjunts.length)
      for (let i = 0; i < adjunts.length; i++) {
        formData.append("adjunts[]", adjunts[i]);
      }
    }


    console.log(formData);
    axios
      .post(
        process.env.REACT_APP_API_URL + "api/tiquet/",formData,
        {
          auth: {
            username: process.env.REACT_APP_API_USER,
            password: process.env.REACT_APP_API_PWD,
          },
          headers: {
            token: JSON.parse(localStorage.getItem("userLogged")).token,
            idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
            "content-type": "multipart/form-data"

          },
        }
      )
      .then((res) => {
        // Si existeixen arxius adjunts, penjar arxius
        if (adjunts) {
          uploadAdjunts(res.data);
        } else {
          // Redireccionem a la pàgina del ticket creat
           history.push(`/ticket/${res.data}/`);
        }

        // TOAST OK
        addToast("Tiquet creat correctament", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((error) => {
        console.log(error);
        document.getElementById("btnCrearTicket").disabled = false
        // TOAST ERROR
        addToast("S'ha produït un error", {
          appearance: "error",
        });
      });
  };

  return (
    <>
      <Title title="Tickets" title2="Crear" titleLink="/tickets" />
      <TicketForm
        ticket={ticket}
        setTicket={setTicket}
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        adjunts={adjunts}
        setAdjunts={setAdjunts}
      />
    </>
  );
};

export default NewTicket;

import React, { useState, useEffect } from "react";
import { CgSpinner } from "react-icons/cg";

const AlbaraZammadInternalArticles = ({
	articles,
	setAlbara,
	albara,
	logoZammad,
}) => {
	const handleSubmit = (note) => {
		console.log(
			"🚀 ~ file: AlbaraZammadInternalArticles.js:11 ~ handleSubmit ~ note:",
			note.body
		);
		console.log(
			"🚀 ~ file: AlbaraZammadInternalArticles.js:11 ~ handleSubmit ~ note:",
			albara.incidencia
		);
		if (note) {
			let text = note?.body?.replace(/<br\s*\/?>/gi, "\n");
			text = text?.replace(/(<([^>]+)>)/gi, "");
			setAlbara((prevState) => ({
				...prevState,
				incidencia:
					prevState?.incidencia && prevState?.incidencia !== ""
						? prevState?.incidencia + "\n \n" + text
						: text,
			}));
		}

		// deleteNote(note);
		disableNote(note);
	};

	// const deleteNote = (note) => {
	// 	const currentNoteIndex = articles.findIndex((nt) => nt.id === note.id);
	// 	articles.splice(currentNoteIndex, 1);
	// };

	const disableNote = (note) => {
		const currentNote = document.querySelector(`[data-note="${note.id}"]`);
		currentNote.style.opacity = '0.5';
		currentNote.style.cursor = "not-allowed";
		currentNote.style.pointerEvents = 'none';
	};

	const parseHTML = (htmlText) => {
		const parser = new DOMParser();
		const doc = parser.parseFromString(htmlText, "text/html");
		return doc.body.textContent.replace(/\n/g, "") || "";
	};

	return articles?.length > 0 ? (
		articles?.map((note) => {
			console.log(
				"🚀 ~ file: AlbaraZammadInternalArticles.js:38 ~ articles?.map ~ note:",
				note
			);
			const datetime = new Date(note?.created_at);
			const year = datetime.getFullYear();
			const month = datetime.getMonth() + 1;
			const day = datetime.getDate();
			const hour = datetime.getHours();
			const minute = datetime.getMinutes();

			return (
				note?.preferences?.perform_origin !== "trigger" && (
					<div
						className="mb-2 transition-all duration-100 ease-in transform cursor-pointer notesDiv hover:-translate-x-5 w-12/12"
						key={note.id}
						data-note={note.id}
						style={{
							background:
								"repeating-linear-gradient(45deg, #fcd9c9, #fcd9c9 5px, #feede6 5px, #feede6 10px)",
							backgroundSize: "14px 14px",
							backgroundPosition: "-1px",
							padding: "5px",
							borderRadius: "5px",
							fontFamily: "Montserrat, sans",
							backfaceVisibility: "hidden"
						}}
					>
						<div
							className="relative grid h-auto grid-cols-12 transition-all duration-100 ease-in transform border-2 rounded cursor-pointer hover:bg-white min-h-32 text-amber-900 border-amber-900 bg-amber-50 w-12/12"
							onClick={() => {
								handleSubmit(note);
							}}
						>
							<div className="flex items-center justify-center w-full h-full col-span-2 bg-amber-900">
								<img
									src={logoZammad}
									alt="logo"
									className="w-12 h-12 overflow-visible bg-white rounded-full select-none ring-2 ring-amber-600"
								/>
							</div>

							<div
								className="relative flex flex-col justify-center w-full h-full col-span-10 px-4 py-8 break-words place-self-center justify-self-start"
							>
								{note?.content_type === 'text/html' ? parseHTML(note?.body) : note?.body}
								{/* {parseHTML(note?.body)} */}
							</div>

							<span className="select-none absolute top-0 right-0 flex items-center justify-center px-2 py-0.5 text-sm font-semibold text-white rounded-bl w-36 bg-amber-900">
								{(day < 10 ? "0" : "") +
									day +
									"-" +
									(month < 10 ? "0" : "") +
									month +
									"-" +
									year +
									" " +
									(hour < 10 ? "0" : "") +
									hour +
									":" +
									(minute < 10 ? "0" : "") +
									minute}
							</span>
						</div>
					</div>
				)
			);
		})
	) : (
		<div className="w-12/12">
			<div className="relative grid h-auto grid-cols-12 mb-2 border-2 rounded min-h-24 text-amber-900 border-amber-900 bg-amber-50 w-12/12">
				<div className="flex items-center justify-center w-full h-full col-span-2 bg-amber-900">
					<img
						src={logoZammad}
						alt="logo"
						className="w-12 h-12 overflow-visible bg-white rounded-full select-none ring-2 ring-amber-600"
					/>
				</div>

				<div
					className={`${
						articles ? "justify-start" : "justify-center"
					} relative flex flex-row items-center w-full col-span-10 px-4 py-4 place-self-center justify-self-start`}
				>
					{articles ? (
						<span>No s'han trobat registres!</span>
					) : (
						<CgSpinner size="40" className="animate-spin" />
					)}
				</div>

				<span className="select-none h-6 absolute top-0 right-0 flex items-center justify-center px-2 py-0.5 text-sm font-semibold text-white rounded-bl w-36 bg-amber-900"></span>
			</div>
		</div>
	);
};
export default AlbaraZammadInternalArticles;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Button, Container, Row, Col, InputGroup } from "react-bootstrap";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";
import moment from "moment";
import DatePicker from "react-datepicker";

import { useToasts } from "react-toast-notifications";

const TicketForm = ({
  ticket,
  setTicket,
  handleCancel,
  handleSubmit,
  adjunts,
  setAdjunts,
}) => {
  const { addToast } = useToasts();

  // Estat amb dades desplegables
  const [empreses, setEmpreses] = useState();
  const [informadors, setInformadors] = useState();
  const [serveis, setServeis] = useState();

  const [subServeis, setSubServeis] = useState();
  const [estats, setEstats] = useState();
  const [prioritats, setPrioritats] = useState();
  const [tecnics, setTecnics] = useState();
  const [involucrats, setInvolucrats] = useState();
  const [grups, setGrups] = useState();

  // Al carregar la screen
  useEffect(() => {
    // Carreguem les dades dels desplegables
    // PETICIÓ API EMPRESES
    const apiEmpreses = axios.get(
      process.env.REACT_APP_API_URL + "api/empreses/",
      {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      }
    );

    // INFORMADORS
    const apiInformadors = axios.get(
      process.env.REACT_APP_API_URL + "api/informadors/",
      {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      }
    );

    // PRIORITATS
    const apiPrioritats = axios.get(
      process.env.REACT_APP_API_URL + "api/prioritats/",
      {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      }
    );

    // PETICIÓ API SERVEIS
    const apiServeis = axios.get(
      process.env.REACT_APP_API_URL + "api/serveis/",
      {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      }
    );

    // PETICIO API SUBSERVEIS
    const apiSubServeis = axios.get(
      `${process.env.REACT_APP_API_URL}api/serveis/subserveis/`,
      {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      }
    );
    // ESTATS
    const apiEstats = axios.get(process.env.REACT_APP_API_URL + "api/estats/", {
      auth: {
        username: process.env.REACT_APP_API_USER,
        password: process.env.REACT_APP_API_PWD,
      },
      headers: {
        token: JSON.parse(localStorage.getItem("userLogged")).token,
        idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
      },
    });

    // PETICIÓ API TECNICS
    const apiTecnics = axios.get(
      process.env.REACT_APP_API_URL + "api/tecnics/",
      {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      }
    );

    // PETICIÓ API GRUPS
    const apiGrups = axios.get(process.env.REACT_APP_API_URL + "api/grups/", {
      auth: {
        username: process.env.REACT_APP_API_USER,
        password: process.env.REACT_APP_API_PWD,
      },
      headers: {
        token: JSON.parse(localStorage.getItem("userLogged")).token,
        idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
      },
    });

    axios
      .all([
        apiEmpreses,
        apiInformadors,
        apiPrioritats,
        apiServeis,
        apiSubServeis,
        apiEstats,
        apiTecnics,
        apiGrups,
      ])
      .then(
        axios.spread((...responses) => {
          const responseEmpreses = responses[0].data;
          const responseInformadors = responses[1].data;
          const responsePrioritats = responses[2].data;
          const responseServeis = responses[3].data;
          const responseSubServeis = responses[4].data;
          const responseEstats = responses[5].data;
          const responseTecnics = responses[6].data;
          const responseGrups = responses[7].data;

          /* EMPRESES SELECT OPTIONS */
          const empresesList = [];
          responseEmpreses.forEach((empresa) => {
            empresesList.push({
              value: empresa.id,
              label: empresa.nom,
            });
          });
          setEmpreses(empresesList);

          /* INFORMADORS SELECT OPTIONS */
          const informadorsList = [];
          responseInformadors.forEach((informador) => {
            informadorsList.push({
              value: informador.id,
              label: informador.nom,
            });
          });
          setInformadors(informadorsList);

          /* PRIORITATS SELECT OPTIONS */
          const prioritatsList = [];
          responsePrioritats.forEach((prioritat) => {
            prioritatsList.push({
              value: prioritat.id,
              label: prioritat.nom,
            });
          });
          setPrioritats(prioritatsList);

          /* SERVEIS SELECT OPTIONS */
          const serveisList = [];
          responseServeis.forEach((servei) => {
            serveisList.push({
              value: servei.id,
              label: servei.nom,
            });
          });
          setServeis(serveisList);

          /* SUBSERVEIS SELECT OPTIONS */
          const subServeisList = [];
          responseSubServeis.forEach((subServei) => {
            subServeisList.push({
              value: subServei.id,
              label: subServei.nom,
            });
          });
          setSubServeis(subServeisList);

          /* ESTATS SELECT OPTIONS */
          const estatsList = [];
          responseEstats.forEach((estat) => {
            estatsList.push({
              value: estat.id,
              label: estat.nom,
            });
          });
          setEstats(estatsList);

          /* TECNICS SELECT OPTIONS */
          const tecnicsList = [
            {
              value: "null",
              label: "Sense assignar",
            },
          ];
          responseTecnics.forEach((tecnic) => {
            tecnicsList.push({
              value: tecnic.id,
              label: tecnic.nom,
            });
          });
          setTecnics(tecnicsList);

          /* INVOLUCRATS SELECT OPTIONS */
          const involucratsList = [];
          responseTecnics.forEach((involucrat) => {
            involucratsList.push({
              value: involucrat.id,
              label: involucrat.nom,
            });
          });
          setInvolucrats(involucratsList);

          /* GRUPS SELECT OPTIONS */
          const grupsList = [];
          responseGrups.forEach((grup) => {
            grupsList.push({
              value: grup.id,
              label: grup.nom,
            });
          });
          setGrups(grupsList);
        })
      )
      .catch((errors) => {
        // TOAST ERROR
        addToast("S'ha produït un error", {
          appearance: "error",
        });
      });
    // eslint-disable-next-line
  }, []);

  // HANDLE SELECCIONAR EMPRESA
  const handleClient = (client) => {
    // Carreguem les dades de l'empresa client seleccionada

    getDadesEmpresa(client.value);

    setTicket({
      ...ticket,
      idClient: client.value,
    });
  };
  // HANDLE SELECCIONAR INFORMADOR
  const handleInformador = (informador) => {
    setTicket({
      ...ticket,
      idInformador: informador.value,
    });
  };

  // HANDLE SELECCIONAR PRIORITAT
  const handlePrioritat = (prioritat) => {
    setTicket({
      ...ticket,
      idPrioritat: prioritat.value,
    });
  };

  // HANDLE SELECCIONAR SERVEI
  const handleServei = (servei) => {
    getDadesServei(servei.value);

    setTicket({
      ...ticket,
      idServei: servei.value,
    });
  };
  // HANDLE SELECCIONAR SUBSERVEI
  const handleSubServei = (subServei) => {
    setTicket({
      ...ticket,
      idSubServei: subServei.value,
    });
  };

  // HANDLE SELECCIONAR ESTAT
  const handleEstat = (estat) => {
    setTicket({
      ...ticket,
      idEstat: estat.value,
    });
  };

  // HANDLE SELECCIONAR ASSIGNAT
  const handleAssignat = (assignat) => {
    setTicket({
      ...ticket,
      idAssignat: assignat.value,
    });
  };
  // HANDLE SELECCIONAR INVOLUCRATS
  const handleInvolucrats = (involucrats) => {
    setTicket({
      ...ticket,
      involucrats: involucrats,
    });
  };
  // HANDLE SELECCIONAR GRUP
  const handleGrup = (grup) => {
    setTicket({
      ...ticket,
      idGrup: grup.value,
    });
  };
  const getDadesEmpresa = (idClient) => {
    // PETICIÓ API EMPRESES
    axios
      .get(`${process.env.REACT_APP_API_URL}api/empresa/${idClient}/`, {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      })
      .then((res) => {
        // Guardar dades de l'Empresa Seleccionada (Responsable, Contacte, Telefon...)
        setTicket({
          ...ticket,
          idClient: res.data.id,
          telefon: res.data.telefon1 || res.data.telefon2,
          contacte: res.data.contacte,
          idAssignat: res.data.responsable.id,
          email: res.data.email,
        });
      });
  };
  const getDadesServei = (idServei) => {
    // Guardem les dades de serveis/subserveis dins del estat
    axios
      .get(`${process.env.REACT_APP_API_URL}api/servei/${idServei}/`, {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      })
      .then((res) => {
        // Carreguem la llista de serveis per a buscar els subserveis
        // Guardem els subserveis del servei seleccionat
        const itemList = [];
        res.data.subserveis.forEach((subServei) => {
          itemList.push({
            value: subServei.id,
            label: subServei.nom,
          });
        });
        setSubServeis(itemList);
      });
  };

  // VALIDAR CAMPS
  const validarCamps = () => {
    let campsValidats = true;
    // eslint-disable-next-line
    Object.keys(ticket).map((key) => {
      // console.log(key);
      if (key !== "dataPlanificacio" && key !== "involucrats") {
        if (ticket[key] === "" || ticket[key] === 0) {
          campsValidats = false;
        }
      }
    });

    if (campsValidats) {
      handleSubmit();
    } else {
      addToast(
        "Error al creat tiquet. Revisa que tots els camps estiguin plens.",
        {
          appearance: "error",
        }
      );
    }
    
  };

  // VALIDAR ADJUNTS
  const validarAdjunts = (e) => {
    // Validem que sigui menor a 30MB (limit de tamany arxiu)
    const adjunts = e.target.files;
    const limit = 31457280; // 30MB

    // Recorrem tots els adjunts i si trobem un que supera el límit, mostrem error i fem return
    for (let i = 0; i < adjunts.length; i++) {
      if (adjunts[i].size > limit) {
        addToast(
          `Error al adjuntar fitxers. El límit és ${limit / 1024 / 1024}MB`,
          {
            appearance: "error",
          }
        );
        e.target.value = null;
        return;
      }
    }
    setAdjunts(adjunts);
  };

  return (
    <Form className='mt-6' encType="multipart/form-data" id="mailForm">
      <Container>
        <Row>
          <Col sm={8}>
            <Form.Group controlId="exampleForm.ControlSelect1">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">
                    Client
                  </InputGroup.Text>
                </InputGroup.Prepend>
                {empreses ? (
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    name="idClient"
                    className="select"
                    value={empreses.filter(
                      (empresa) => empresa.value === ticket.idClient
                    )}
                    onChange={handleClient}
                    options={empreses}
                    placeholder="Seleccionar client"
                  />
                ) : (
                  ""
                )}
              </InputGroup>
            </Form.Group>

            <Form.Group controlId="exampleForm.ControlTextarea1">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">
                    Assumpte
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  name="assumpte"
                  value={ticket.assumpte}
                  onChange={(e) =>
                    setTicket({
                      ...ticket,
                      assumpte: e.target.value,
                    })
                  }
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className='hidden lg:block  ' controlId="exampleForm.ControlTextarea1">
              <CKEditor
                editor={ClassicEditor}
                name="descripcio"

                data={ticket.descripcio ? ticket.descripcio : ""}
                onInit={(editor) => {
                  editor.editing.view.focus();
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setTicket({
                    ...ticket,
                    descripcio: data,
                  });
                }}
                config={{
                  removePlugins: ["Heading"],
                  ckfinder: {
                    uploadUrl: `${process.env.REACT_APP_API_URL}ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images&responseType=json`,
                  },
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                name="adjunts[]"
                type="file"
                multiple
                onChange={(e) => validarAdjunts(e)}
                className='hidden lg:block'
              />
            </Form.Group>
            <div
            className='hidden lg:block'
              style={{
                textAlign: "center",
                marginTop: "2rem",
              }}
            >
              <Button
                variant="primary"
                type="button"
                id="btnCrearTicket"
                onClick={() => {
                  validarCamps();
                }}
              >
                Crear ticket
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button variant="light" type="button" onClick={handleCancel}>
                Cancelar
              </Button>
            </div>
          </Col>
          <Col sm={4}>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <InputGroup>
                <InputGroup.Prepend size="sm">
                  <InputGroup.Text id="inputGroupPrepend">
                    Contacte
                  </InputGroup.Text>
                </InputGroup.Prepend>

                <Form.Control
                  size="sm"
                  type="text"
                  name="contacte"

                  defaultValue={ticket.contacte ? ticket.contacte : ""}
                  onChange={(e) =>
                    setTicket({
                      ...ticket,
                      contacte: e.target.value,
                    })
                  }
                />
              </InputGroup>
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <InputGroup>
                <InputGroup.Prepend size="sm">
                  <InputGroup.Text id="inputGroupPrepend">
                    Correu electrònic
                  </InputGroup.Text>
                </InputGroup.Prepend>

                <Form.Control
                  size="sm"
                  type="text"
                  defaultValue={ticket.email ? ticket.email : ""}
                  name="email"

                  onChange={(e) =>
                    setTicket({
                      ...ticket,
                      email: e.target.value,
                    })
                  }
                />
              </InputGroup>
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">
                    Telèfon
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  size="sm"
                  type="text"
                  defaultValue={ticket.telefon ? ticket.telefon : ""}
                  name="telefon1"
                  onChange={(e) =>
                    setTicket({
                      ...ticket,
                      telefon: e.target.value,
                    })
                  }
                />
              </InputGroup>
            </Form.Group>
            <hr />
            <Form.Group controlId="exampleForm.ControlSelect1">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">
                    Canal
                  </InputGroup.Text>
                </InputGroup.Prepend>
                {informadors ? (
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    className="select"
                    options={informadors}
                    value={informadors.filter(
                      (informador) => informador.value === ticket.idInformador
                    )}
                    placeholder="Seleccionar informador"
                    onChange={handleInformador}
                  />
                ) : (
                  ""
                )}
              </InputGroup>
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlSelect1">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">
                    Prioritat
                  </InputGroup.Text>
                </InputGroup.Prepend>
                {prioritats ? (
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    className="select"
                    options={prioritats}
                    value={prioritats.filter(
                      (prioritat) => prioritat.value === ticket.idPrioritat
                    )}
                    placeholder="Seleccionar prioritat"
                    onChange={handlePrioritat}
                  />
                ) : (
                  ""
                )}
              </InputGroup>
            </Form.Group>

            <Form.Group controlId="exampleForm.ControlSelect1">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">
                    Servei
                  </InputGroup.Text>
                </InputGroup.Prepend>
                {serveis ? (
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    className="select"
                    value={serveis.filter(
                      (servei) => servei.value === ticket.idServei
                    )}
                    onChange={handleServei}
                    options={serveis}
                    placeholder="Seleccionar servei"
                  />
                ) : (
                  ""
                )}
              </InputGroup>
            </Form.Group>

            {/* ********** SUBSERVEI ********** */}
            {subServeis && ticket.idServei ? (
              <Form.Group controlId="exampleForm.ControlSelect1">
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text id="inputGroupPrepend">
                      Sub Servei
                    </InputGroup.Text>
                  </InputGroup.Prepend>

                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    className="select"
                    value={subServeis.filter(
                      (subServei) => subServei.value === ticket.idSubServei
                    )}
                    onChange={handleSubServei}
                    options={subServeis}
                    placeholder="Seleccionar sub servei"
                  />
                </InputGroup>
              </Form.Group>
            ) : (
              ""
            )}

            <hr />
            {/* ********** GRUP ASSIGNAT ********** */}
            {grups ? (
              <Form.Group controlId="exampleForm.ControlSelect1">
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text id="inputGroupPrepend">
                      Grup
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    className="select"
                    value={grups.filter((grup) => grup.value === ticket.idGrup)}
                    onChange={handleGrup}
                    options={grups}
                    placeholder="Seleccionar grup"
                  />
                </InputGroup>
              </Form.Group>
            ) : (
              ""
            )}

            {/* ********** ASSIGNAT ********** */}
            {tecnics ? (
              <Form.Group controlId="exampleForm.ControlSelect1">
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text id="inputGroupPrepend">
                      Assignat
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    className="select"
                    value={tecnics.filter(
                      (tecnic) => tecnic.value === ticket.idAssignat
                    )}
                    onChange={handleAssignat}
                    options={tecnics}
                    placeholder="Seleccionar tècnic"
                  />
                </InputGroup>
              </Form.Group>
            ) : (
              ""
            )}

            {/* ********** INVOLUCRATS ********** */}
            {involucrats ? (
              <Form.Group controlId="exampleForm.ControlSelect1">
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text id="inputGroupPrepend">
                      Involucrats
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    className="select"
                    isMulti
                    /* value={tecnics.filter(
                      (tecnic) => tecnic.value === ticket.idAssignat
                    )} */
                    onChange={handleInvolucrats}
                    options={involucrats}
                    placeholder="Opcional"
                  />
                </InputGroup>
              </Form.Group>
            ) : (
              ""
            )}
            <hr />
            {/* ********** DATA PLANIFICACIO ********** */}
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">
                    Data planificació
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <DatePicker
                  onChange={(date) => {
                    let dataPlanificacio = null;
                    if (date) {
                      dataPlanificacio = moment(date).format("X");
                    }
                    setTicket({
                      ...ticket,
                      dataPlanificacio: dataPlanificacio,
                    });
                  }}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Opcional"
                  className="form-control"
                  minDate={new Date()}
                  selected={
                    ticket.dataPlanificacio
                      ? new Date(ticket.dataPlanificacio * 1000)
                      : ""
                  }
                />
              </InputGroup>
            </Form.Group>

            <Form.Group controlId="exampleForm.ControlSelect1">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">
                    Estat
                  </InputGroup.Text>
                </InputGroup.Prepend>
                {estats ? (
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    className="select"
                    options={estats}
                    value={estats.filter(
                      (estat) => estat.value === ticket.idEstat
                    )}
                    placeholder="Seleccionar estat"
                    onChange={handleEstat}
                  />
                ) : (
                  ""
                )}
              </InputGroup>
            </Form.Group>
            <Form.Group className='lg:hidden' controlId="exampleForm.ControlTextarea1">
              <CKEditor
                editor={ClassicEditor}
                name="descripcio"

                data={ticket.descripcio ? ticket.descripcio : ""}
                onInit={(editor) => {
                  editor.editing.view.focus();
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setTicket({
                    ...ticket,
                    descripcio: data,
                  });
                }}
                config={{
                  removePlugins: ["Heading"],
                  ckfinder: {
                    uploadUrl: `${process.env.REACT_APP_API_URL}ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images&responseType=json`,
                  },
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                name="adjunts[]"
                type="file"
                multiple
                onChange={(e) => validarAdjunts(e)}
                className='lg:hidden'
              />
            </Form.Group>
            <div
              style={{
                textAlign: "center",
                marginTop: "2rem",
              }}
            >
              <Button
                variant="primary"
                type="button"
                id="btnCrearTicket"
                onClick={() => {
                  validarCamps();
                }}
              >
                Crear ticket
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button variant="light" type="button" onClick={handleCancel}>
                Cancelar
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

export default TicketForm;

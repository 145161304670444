import React, { useState, useEffect } from "react";
import Title from "../../components/shared/Title/Title";
import EmailForm from "../../forms/EmailForm";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import axios from "axios";

const NewEmail = () => {
    const { addToast } = useToasts();

    const history = useHistory();

    // Estat amb dades per defecte
    const [email, setEmail] = useState({
        destinatari: "",
        cc: "",
        bcc: "",
        assumpte: "",
        descripcio: "",
        idAssignat: JSON.parse(localStorage.getItem("userLogged")).id,
        idCreador: JSON.parse(localStorage.getItem("userLogged")).id,
    });
    // console.log(email);
    const [adjunts, setAdjunts] = useState();

    // Al fer clic al botó de Cancelar
    const handleCancel = () => {
        history.push("/tickets");
    };

    const [tecnics, setTecnics] = useState();

    useEffect(() => {
        const apiTecnics = axios.get(
            process.env.REACT_APP_API_URL + "api/tecnics/",
            {
                auth: {
                    username: process.env.REACT_APP_API_USER,
                    password: process.env.REACT_APP_API_PWD,
                },
                headers: {
                    token: JSON.parse(localStorage.getItem("userLogged")).token,
                    idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
                },
            }
        )
        apiTecnics.then(res => {
            const tecnicsList = [];
            tecnicsList.push({
                value: null,
                label: "Sense assignar",
            });
            res.data.forEach((tecnic) => {
                tecnicsList.push({
                    value: tecnic.id,
                    label: tecnic.nom,
                });
            });
            setTecnics(tecnicsList);
        })
    }, [])


    // Penjem adjunts
    const uploadAdjunts = (idTicket) => {
        if (adjunts.length === 0) return

        const formData = new FormData();

        for (let i = 0; i <= adjunts.length; i++) {
            formData.append("adjunts[]", adjunts[i]);
        }

        axios
            .post(
                `${process.env.REACT_APP_API_URL}api/tiquet/${idTicket}/adjunts/`,
                formData,
                {
                    auth: {
                        username: process.env.REACT_APP_API_USER,
                        password: process.env.REACT_APP_API_PWD,
                    },
                    headers: {
                        token: JSON.parse(localStorage.getItem("userLogged")).token,
                        idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
                        "content-type": "multipart/form-data",
                    },
                }
            )
            .then((res) => {
                // console.log(res.data);
                history.push(`/ticket/${res.data}/`);
            })
            .catch((error) => {
                console.log(error);
            });

        setEmail({
            ...email,
            adjunts: formData,
        });
    };

    // Al fer submit del formulari
    const handleSubmit = () => {
        document.getElementById("btnSendEmail").disabled = true
        const formData = new FormData();

        formData.append("destinatari", email.destinatari)
        formData.append("cc", JSON.stringify(email.cc))
        formData.append("bcc", JSON.stringify(email.bcc))
        formData.append("assumpte", email.assumpte)
        formData.append("idAssignat", email.idAssignat)
        formData.append("idCreador", email.idCreador)
        formData.append("descripcio", email.descripcio)
        if (adjunts) {
            console.log(adjunts.length)
            for (let i = 0; i < adjunts.length; i++) {
                formData.append("adjunts[]", adjunts[i]);
            }
        }


        console.log(formData);
        // Insertem el nou ticket a la base de dades (amb les dades del formulari)
        axios
            .post(
                process.env.REACT_APP_API_URL + "api/email/", formData,

                {
                    auth: {
                        username: process.env.REACT_APP_API_USER,
                        password: process.env.REACT_APP_API_PWD,
                    },
                    headers: {
                        token: JSON.parse(localStorage.getItem("userLogged")).token,
                        idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
                        "content-type": "multipart/form-data"
                    },
                }
            )
            .then((res) => {
                // Si existeixen arxius adjunts, penjar arxius
                if (adjunts) {
                    try {
                        uploadAdjunts(res.data.idTiquet);
                    } catch (error) {
                        addToast("Email enviat correctament, adjunt no desat", {
                            appearance: "error",
                        });
                    }
                } else {
                    addToast("Email enviat correctament", {
                        appearance: "success",
                        autoDismiss: true,
                    });
                    // Redireccionem a la pàgina del ticket creat
                    history.push(`/ticket/${res.data.idTiquet}/`);
                }

                // TOAST OK

            })
            .catch((error) => {
                document.getElementById("btnSendEmail").disabled = false

                console.log(error);
                // TOAST ERROR
                addToast("S'ha produït un error", {
                    appearance: "error",
                });
            });
    };

    return (
        <>
            <Title title="Tickets" title2="Crear email" titleLink="/tickets" />
            <EmailForm
                email={email}
                setEmail={setEmail}
                setAdjunts={setAdjunts}
                handleCancel={handleCancel}
                handleSubmit={handleSubmit}
                tecnics={tecnics}
            />
        </>
    );
};

export default NewEmail;

import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Moment from "react-moment";

import { useToasts } from "react-toast-notifications";

import axios from "axios";

export default ({ recurrent, loadRecurrents }) => {
  const { addToast } = useToasts();
  // console.log(recurrent);
  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  // const dataActual = Math.floor(Date.now() / 1000);

  const handleDelete = (id) => {
    // PETICIÓ API SERVEIS
    axios
      .delete(`${process.env.REACT_APP_API_URL}api/tiquet/recurrent/${id}/`, {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      })
      .then((res) => {
        handleClose();
        loadRecurrents();
        // TOAST OK
        addToast("Tiquet recurrent eliminat correctament", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((error) => {
        console.log(error);
        // TOAST ERROR
        addToast("S'ha produït un error", {
          appearance: "error",
        });
      });
  };

  const [show, setShow] = useState(false);

  const handleClose = (e) => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  return (
    <tr  key={recurrent.id}>
      <Link
        to={"/ticket-recurrent/" + recurrent.id + "/log/"}
        style={{ width: "100%", display: "contents" }}
      >
        <td className="xs:border-t-0 w-48 flex flex-col">
          <span className='font-semibold'>{recurrent.client}</span>
          <span className="lg:hidden ">{recurrent.assumpte}</span>
        </td>
        <td className="hidden lg:table-cell">{recurrent.assumpte}</td>

        <td className="hidden lg:table-cell">
          {recurrent.servei}
          <br />
          {recurrent.subServei}
        </td>

        <td className="hidden lg:table-cell">
          <Moment format="DD/MM/YYYY" unix>
            {recurrent.dataInici}
          </Moment>
        </td>
        <td className='hidden lg:table-cell'>
          {recurrent.dataCaducitat ? (
            <Moment format="DD/MM/YYYY" unix>
              {recurrent.dataCaducitat}
            </Moment>
          ) : (
            ""
          )}
        </td>
        <td className="hidden lg:table-cell">
          {capitalize(recurrent.intervalFrequencia)}
        </td>
        <td>
          {recurrent.assignat ? (
            <>
              {recurrent.fotoAssignat != null ? (
                <img
                  style={{
                    width: 30,

                    border: "1px solid #ced9e8",
                  }}
                  alt={recurrent.assignat}
                  src={`${process.env.REACT_APP_API_URL}/sat/ct/imatges/imatges-tecnics/${recurrent.fotoAssignat}`}
                />
              ) : (
                ""
              )}
              &nbsp;
              {recurrent.assignat}
            </>
          ) : (
            "Sense Asignar"
          )}
        </td>
        <td>{capitalize(recurrent.estat.replace("_", " "))}</td>
      </Link>
      <td className=''>
        <i
          onClick={(e) => {
            e.stopPropagation();
            handleShow();
          }}
          className="fa fa-trash"
          style={{ fontSize: "1.5rem", cursor: "pointer" }}
          aria-hidden="true"
        ></i>
        <Modal
          show={show}
          onHide={(e) => {
            e.stopPropagation();
            handleClose();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Eliminar ticket recurrent</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Estàs segur d'eliminar la recurrència{" "}
            <strong>
              {recurrent.assumpte} ({recurrent.client})
            </strong>
            ?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="grey"
              onClick={(e) => {
                e.stopPropagation();
                handleClose();
              }}
            >
              Cancel·lar
            </Button>
            <Button
              variant="danger"
              onClick={(e) => {
                e.stopPropagation();
                handleDelete(recurrent.id);
              }}
            >
              Eliminar
            </Button>
          </Modal.Footer>
        </Modal>
      </td>
    </tr>
  );
};

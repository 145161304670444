import React, { useState, useEffect } from "react";
import { CgSpinner } from "react-icons/cg";
import { AiFillStar } from "react-icons/ai";

const AlbaraZammadTimeArticles = ({
	articles,
	setAlbara,
	albara,
	logoZammad,
	getZammadUserName,
	getCurrentMinutes,
	setCurrentMinutes,
	gestinetMinutes
}) => {
	const handleSubmit = (note, operation) => {
		if (note) {
			const accumulatedMinutesTarget = getCurrentMinutes + operation;

			const realMinutesTarget = gestinetMinutes.reduce(function (prev, curr) {
				if (curr?.value === accumulatedMinutesTarget) {
					return curr;
				} else {
					return curr?.value - 25 <= accumulatedMinutesTarget
						? curr
						: prev;
				}
			})
			setCurrentMinutes((prevState) => prevState + operation);

			setAlbara((prevState) => ({
				...prevState,
				minuts_facturats: realMinutesTarget.value
			}));

			// console.log(
			// 	"🚀 ~ file: AlbaraZammadTimeArticles.js:20 ~ setAlbara ~ prevState.minuts_facturats + operation:",
			// 	albara.minuts_facturats + operation
			// );
		}

		// deleteNote(note);
		disableNote(note);
	};

	// const deleteNote = (note) => {
	// 	const currentNoteIndex = articles.findIndex((nt) => nt.id === note.id);
	// 	articles.splice(currentNoteIndex, 1);
	// };

	const disableNote = (note) => {
		const currentNote = document.querySelector(`[data-time="${note.id}"]`);
		currentNote.style.opacity = "0.5";
		currentNote.style.pointerEvents = "none";
	};

	return articles?.length > 0 ? (
		articles?.map((note) => {
			const operation =
				note.value_from !== 0 && note.value_from !== "" && note.value_from
					? Number(note.value_to) - Number(note.value_from)
					: Number(note.value_to) - 0;

			const datetime = new Date(note?.created_at);
			const year = datetime.getFullYear();
			const month = datetime.getMonth() + 1;
			const day = datetime.getDate();
			const hour = datetime.getHours();
			const minute = datetime.getMinutes();

			return (
				operation > 0 && (
					<div
						className="mb-2 transition-all duration-100 ease-in transform cursor-pointer timeDiv hover:translate-x-5 w-12/12"
						key={note.id}
						data-time={note.id}
						style={{
							background:
								"repeating-linear-gradient(45deg, #fcd9c9, #fcd9c9 5px, #feede6 5px, #feede6 10px)",
							backgroundSize: "14px 14px",
							backgroundPosition: "-1px",
							padding: "5px",
							borderRadius: "5px",
							fontFamily: "Montserrat, sans",
							backfaceVisibility: "hidden",
						}}
					>
						<div
							className={`transition-all duration-100 ease-in transform hover:bg-white relative grid min-h-32 grid-cols-12 text-teal-900 border-2 rounded border-cyan-900 bg-cyan-50 w-12/12
							${
								albara.minuts_facturats + operation <= 1440
									? "cursor-pointer"
									: "cursor-not-allowed"
							}`}
							onClick={() =>
								albara.minuts_facturats + operation <= 1440 &&
								handleSubmit(note, operation)
							}
						>
							<div className="flex items-center justify-center w-full h-full col-span-2 bg-cyan-900">
								<img
									src={logoZammad}
									alt="logo"
									className="w-12 h-12 overflow-visible bg-white rounded-full select-none ring-2 ring-cyan-600"
								/>
							</div>

							<div className="relative flex flex-row items-center justify-start w-full col-span-10 pl-4 break-words select-none place-self-center justify-self-start">
								<div
									className={`${
										note?.created_by_user?.firstname +
											" " +
											note?.created_by_user?.lastname !==
											getZammadUserName && "hidden"
									}`}
								>
									<AiFillStar
										size="20"
										className="mr-1 text-amber-500 animate-pulse"
									/>
								</div>

								<div>
									{note?.created_by_user ? (
										<>
											<b>
												{note?.created_by_user?.firstname +
													" " +
													note?.created_by_user?.lastname}
											</b>
										</>
									) : (
										<CgSpinner size="20" className="animate-spin" />
									)}{" "}
									ha fet{" "}
									<b>
										{note.value_from !== 0 &&
										note.value_from !== "" &&
										note.value_from
											? Number(note.value_to) - Number(note.value_from)
											: Number(note.value_to) - 0}{" "}
										min
									</b>
									.
								</div>
							</div>

							<span className="select-none absolute top-0 right-0 flex items-center justify-center px-2 py-0.5 text-sm font-semibold text-white rounded-bl w-36 bg-cyan-900">
								{(day < 10 ? "0" : "") +
									day +
									"-" +
									(month < 10 ? "0" : "") +
									month +
									"-" +
									year +
									" " +
									(hour < 10 ? "0" : "") +
									hour +
									":" +
									(minute < 10 ? "0" : "") +
									minute}
							</span>
						</div>
					</div>
				)
			);
		})
	) : (
		<div className="w-12/12">
			<div className="relative grid h-auto grid-cols-12 mb-2 border-2 rounded min-h-24 text-cyan-900 border-cyan-900 bg-cyan-50 w-12/12">
				<div className="flex items-center justify-center w-full h-full col-span-2 bg-cyan-900">
					<img
						src={logoZammad}
						alt="logo"
						className="w-12 h-12 overflow-visible bg-white rounded-full select-none ring-2 ring-cyan-600"
					/>
				</div>

				<div
					className={`${
						articles ? "justify-start" : "justify-center"
					} relative flex flex-row items-center w-full col-span-10 px-4 py-4 place-self-center justify-self-start`}
				>
					{articles ? (
						<span>No s'han trobat registres!</span>
					) : (
						<CgSpinner size="40" className="animate-spin" />
					)}
				</div>

				<span className="select-none h-6 absolute top-0 right-0 flex items-center justify-center px-2 py-0.5 text-sm font-semibold text-white rounded-bl w-36 bg-cyan-900"></span>
			</div>
		</div>
	);
	// 	<Container>
	// 		<Row>
	// 			{ticketNotes.map((note, i) => {
	// 				if (note && note.text) {
	// 					return (
	// 						<span
	// 							className="col-8"
	// 							onClick={(e) => {
	// 								handleSubmit(note);
	// 							}}
	// 						>
	// 							{" "}
	// 							<AlbaraNote note={note} key={note.id}></AlbaraNote>
	// 						</span>
	// 					);
	// 				}
	// 				return null;
	// 			})}
	// 		</Row>
	// 	</Container>
	// );
};
export default AlbaraZammadTimeArticles;

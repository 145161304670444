import React from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";
import Moment from "react-moment";
import parse from "html-react-parser";

const LogItem = ({
  nomOperador,
  fotoOperador,
  missatge,
  missatgesTractats,
  data,
}) => {
  return (
    <Container>
      <Alert key={data} variant="info" style={{ padding: 10 }}>
        <Row>
          <Col sm={1}>
            <img
              src={`${process.env.REACT_APP_API_URL}/sat/ct/imatges/imatges-tecnics/${fotoOperador}`}
              title={nomOperador}
              alt={nomOperador}
              style={{ width: 40 }}
            />
          </Col>
          <Col sm={9} style={{ paddingLeft: 0 }}>
            {nomOperador} <strong>{missatge}</strong>
            <br />
            <small>
              <Moment format="DD/MM/YYYY HH:mm" unix>
                {data}
              </Moment>
            </small>
          </Col>
        </Row>
        {missatgesTractats ? (
          <Row style={{ marginTop: 15 }}>
            <Col>
              <ul>
                {missatgesTractats.map((missatgeTractat, i) => {
                  return <li key={i}>{parse(missatgeTractat)}</li>;
                })}
              </ul>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </Alert>
    </Container>
  );
};

export default LogItem;

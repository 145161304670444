import React from "react";
import { Form, Button, Container, Row, Col, InputGroup } from "react-bootstrap";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";
import { useToasts } from "react-toast-notifications";

// import { useToasts } from "react-toast-notifications";
import CreatableInput from "../components/shared/CreatableInput/CreatableInput";

const EmailForm = ({
  email,
  setEmail,
  handleCancel,
  handleSubmit,
  adjunts,
  setAdjunts,

  tecnics
}) => {
  const { addToast } = useToasts();
  // VALIDAR CAMPS
  /* const validarCamps = () => {
    var campsValidats = true;
    // eslint-disable-next-line
    Object.keys(email).map((key) => {
      if (key !== "dataPlanificacio") {
        if (email[key] === "" || email[key] === 0) {
          campsValidats = false;
        }
      }
    });

    if (campsValidats) {
      handleSubmit();
    } else {
      addToast(
        "Error al creat tiquet. Revisa que tots els camps estiguin plens.",
        {
          appearance: "error",
        }
      );
    }
    return;
  }; */

  // VALIDAR ADJUNTS
  const validarAdjunts = (e) => {
    // Validem que sigui menor a 30MB (limit de tamany arxiu)
    const adjunts = e.target.files;
    const limit = 31457280; // 30MB

    // Recorrem tots els adjunts i si trobem un que supera el límit, mostrem error i fem return
    for (let i = 0; i < adjunts.length; i++) {
      if (adjunts[i].size > limit) {
        addToast(
          `Error al adjuntar fitxers. El límit és ${limit / 1024 / 1024}MB`,
          {
            appearance: "error",
          }
        );
        e.target.value = null;
        return;
      }
    }
    console.log(adjunts)
    setAdjunts(adjunts)
  };

  return (
    <Form encType="multipart/form-data" id="mailForm">
      <Container className='mt-4'>
        <Row>
          <Col sm={12}>
            <Form.Group controlId="exampleForm.ControlSelect1">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">
                    Destinatari
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  name="destinatari"
                  onChange={(e) =>
                    setEmail({
                      ...email,
                      destinatari: e.target.value,
                    })
                  }
                />
              </InputGroup>
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlSelect1">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">CC</InputGroup.Text>
                </InputGroup.Prepend>

                <CreatableInput
                  name="cc"
                  onChange={(res) => {
                    console.log(res);
                    setEmail({
                      ...email,
                      cc: res,
                    });
                  }}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group controlId="exampleForm.ControlSelect1">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">BCC</InputGroup.Text>
                </InputGroup.Prepend>
                <CreatableInput
                  name="bcc"
                  onChange={(res) => {
                    setEmail({
                      ...email,
                      bcc: res,
                    });
                  }}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group controlId="exampleForm.selectGroup">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">
                    Técnic assignat
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Select
                  name="idAsignat"
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                  options={tecnics}
                  value={tecnics?.filter(
                    (tecnic) => tecnic.value === email.idAssignat
                  )}
                  onChange={(e) =>
                    setEmail({
                      ...email,
                      idAssignat: e.value,
                    })
                  }
                  className="select"
                  placeholder="Seleccionar técnic"
                />
              </InputGroup>
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">
                    Assumpte
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  name="assumpte"
                  type="text"
                  value={email.assumpte}
                  onChange={(e) =>
                    setEmail({
                      ...email,
                      assumpte: e.target.value,
                    })
                  }
                />
              </InputGroup>
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <CKEditor
                name="descripcio"
                editor={ClassicEditor}
                data={email.descripcio ? email.descripcio : ""}
                onInit={(editor) => {
                  editor.editing.view.focus();
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setEmail({
                    ...email,
                    descripcio: data,
                  });
                }}
                config={{
                  removePlugins: ["Heading"],
                  ckfinder: {
                    uploadUrl: `${process.env.REACT_APP_API_URL}ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images&responseType=json`,
                  },
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                name="adjunts[]"
                type="file"
                multiple
                onChange={(e) => validarAdjunts(e)}
              /* onChange={(e) => validarAdjunts(e)} */
              />
            </Form.Group>
            <div
              style={{
                textAlign: "center",
                marginTop: "2rem",
              }}
            >
              <Button
                variant="primary"
                type="button"
                id="btnSendEmail"
                onClick={() => {
                  handleSubmit();
                  /* validarCamps(); */
                }}
              >
                Enviar email
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button variant="light" type="button" onClick={handleCancel}>
                Cancelar
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

export default EmailForm;

import React from "react";
import Title from "../../components/shared/Title/Title";

import TicketList from "../../components/Tickets/Filters/Filters";

const Filters = () => {
  return (
    <>
      <Title
        title="Tickets"
        titleLink="/tickets"
        title2="Filtres"
        title2Link="/filtres"
      />
      <TicketList isRecurrent={false} />
    </>
  );
};

export default Filters;



import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import AlbaraNote from "../AlbaraNote/AlbaraNote";


const AlbaraSuggestionsNotes = ({ notes, setAlbara, albara }) => {
    const [ticketNotes,] = useState(notes)
    const handleSubmit = ((note) => {

        if (note) {
            let text =  note.text.replace( /<br\s*\/?>/ig, '\n');
            text= text.replace( /(<([^>]+)>)/ig, '');
            setAlbara({
                ...albara,
                incidencia: albara?.incidencia +"\n" + text,
                minuts_facturats:  Math.floor((Number(albara.minuts_facturats)+Number(note.minuts) + 29) / 30) * 30 ,
            })
        }
        
        deleteNote(note)

    })
    const deleteNote = (note) =>{
        const currentNoteIndex = ticketNotes.findIndex((nt) => nt.id === note.id)
       ticketNotes.splice(currentNoteIndex,1)
    }
    useEffect(() => {
    }, [ticketNotes])
    return (
        <Container 
            >
            <Row>
                {ticketNotes.map((note, i) => {
                    if(note && note.text){
                        return<span className="col-8" onClick={(e) => { handleSubmit(note) }} > <AlbaraNote note={note} key={note.id} ></AlbaraNote></span>
                    }
                    return null;
                })}
            </Row>
        </Container>
    )
}
export default AlbaraSuggestionsNotes;
import React, { useEffect, useState } from "react";
import { Badge, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import Select from "react-select";
import axios from "axios";
import { useToasts } from "react-toast-notifications";

export default ({
  ticket,
  handleCheckbox,
  empreses,
  tecnics,
  estats,
  serveis,
  tickets,
  setTickets,
  serveisList,
  loadTickets,
}) => {
  const { addToast } = useToasts();
  const [subServeis, setSubServeis] = useState();

  // Si canvia la pàgina seleccionant el paginador, carregar tiquets d'aquella pàgina i amb aquells filtres

  const getSubServeis = (idServei) => {
    if (serveis === undefined) return;
    const listSubServeis = [];
    const tempServei = serveis?.find((s) => s.value === String(idServei));
    tempServei.subServeis.forEach((subServei) => {
      listSubServeis.push({
        value: subServei.id,
        label: subServei.nom,
        color: tempServei.color,
      });
    });

    setSubServeis(listSubServeis);
  };

  useEffect(() => {
    // Guardem les dades de serveis/subserveis dins del estat
    getSubServeis(ticket?.idServei);
    // eslint-disable-next-line
  }, []);

  const handleCanvi = (idTicket, canvi, tipus) => {
    // Guardem el array del state en una array nova
    const ticketsNew = [...tickets];
    // Busquem el index dins l'array
    const arrayIndex = ticketsNew.findIndex((item) => item.id === idTicket);
    // Depenent del tipus, actualitem uns camps o uns altres
    switch (tipus) {
      case "assignat":
        ticketsNew[arrayIndex].idAssignat = `${canvi.value}`;
        ticketsNew[arrayIndex].assignat = `${canvi.label}`;
        ticketsNew[arrayIndex].fotoAssignat = `${canvi.foto}`;
        break;
      case "estat":
        ticketsNew[arrayIndex].idEstat = `${canvi.value}`;
        ticketsNew[arrayIndex].estat = `${canvi.label}`;
        break;
      case "client":
        ticketsNew[arrayIndex].idClient = `${canvi.value}`;
        ticketsNew[arrayIndex].client = `${canvi.label}`;
        break;
      case "servei":
        ticketsNew[arrayIndex].idServei = `${canvi.value}`;
        ticketsNew[arrayIndex].servei = `${canvi.label}`;
        ticketsNew[arrayIndex].color = `${canvi.color}`;
        // Reinicialitzem el subservei
        ticketsNew[arrayIndex].idSubServei = "";
        ticketsNew[arrayIndex].subServei = "-";
        getSubServeis(canvi.value);

        break;
      case "subServei":
        ticketsNew[arrayIndex].idSubServei = `${canvi.value}`;
        ticketsNew[arrayIndex].subServei = `${canvi.label}`;
        break;
      default:
        break;
    }
    // Assignem l'estat de tickets amb el nou array amb els canvis aplicats
    setTickets(ticketsNew);

    const ticketSave = ticketsNew[arrayIndex];

    axios
      .put(
        `
        ${process.env.REACT_APP_API_URL}api/tiquet/${idTicket}/`,
        { ticket: ticketSave },
        {
          auth: {
            username: process.env.REACT_APP_API_USER,
            password: process.env.REACT_APP_API_PWD,
          },
          headers: {
            token: JSON.parse(localStorage.getItem("userLogged")).token,
            idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
          },
        }
      )
      .then((res) => {
        loadTickets(false);
        // TOAST GUARDAT OK
        addToast("Ticket modificat correctament", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((error) => {
        console.log(error);
        // TOAST ERROR
        addToast("S'ha produït un error", {
          appearance: "error",
        });
      });
  };

  return (
    <tr
      className={` font-rob ${
        ticket.idAssignat === JSON.parse(localStorage.getItem("userLogged")).id
          ? "bg-blue-100 hover:bg-blue-200 hover:border-blue-200"
          : "hover:bg-gray-200 hover:border-gray-300"
      }
      hover:shadow-lg  lg:border border-white `}
      key={ticket.id}
     
    >
      <td className="hidden  lg:table-cell">
        <Form.Check
          onClick={(e) => handleCheckbox(e, ticket.id, ticket.assumpte)}
          className="check-list"
        />
      </td>
      <td className='hidden lg:table-cell'>
      {ticket.client ? ticket.client : ticket.contacte}
      <Select
          options={empreses}
          className="inline-select-container "
          classNamePrefix="inline-select"
          
          placeholder=""
          styles={{
            control: (base) => ({
              ...base,
              border: 0,
              boxShadow: "none",
            }),
          }}
          onChange={(client) => {
            handleCanvi(ticket.id, client, "client");
          }}
        />
      </td>
      <Link
        to={"/ticket/" + ticket.id}
        className='lg:hidden'
       >
      <td className="flex flex-col   lg:table-cell">
        <span className="text-sm xs:font-bold lg:font-rob-semi">
          {ticket.client ? ticket.client : ticket.contacte}
        </span>
        <span className="lg:hidden ">
          {ticket.assumpte.slice(0,1).toUpperCase()}
          {ticket.assumpte.slice(1, 30)}
          {ticket.assumpte.length > 30 && "..."}
        </span>
        <span className="lg:hidden font-light">
          <Moment format="DD/MM/YYYY HH:mm" unix>
            {ticket.data}
          </Moment>
        </span>
        
        <Select
          options={empreses}
          className="inline-select-container xs:w-28"
          classNamePrefix="inline-select"
         
          placeholder=""
          styles={{
            control: (base) => ({
              ...base,
              border: 0,
              boxShadow: "none",
            }),
          }}
          onChange={(client) => {
            handleCanvi(ticket.id, client, "client");
          }}
        />
      </td>
      </Link>
      <Link
        to={"/ticket/" + ticket.id}
        style={{
          width: "100%",
          display: "contents",
        }}
      >
        <td className="hidden  lg:table-cell">
          <span className="text-sm font-semibold">
            {ticket.assumpte.slice(0, 1).toUpperCase()}
            {ticket.assumpte.slice(1, 50).toLowerCase()}
            {ticket.assumpte.length > 50 && "..."}
          </span>
          &nbsp;
          <span className="font-rob-light text-gray-500">#{ticket.id}</span>
          <br />
          <small
            className="font-rob-light text-gray-500"
            style={{ fontSize: "90%" }}
          >
            Creat el:&nbsp;
            <Moment format="DD/MM/YYYY HH:mm" unix>
              {ticket.data}
            </Moment>
          </small>
          <small
            className={`font-rob-light ${ticket.respostes > 0 ? 'inline' : 'hidden'} text-gray-500 ml-4`}
            style={{ fontSize: "90%" }}
          >
            Contestat el:&nbsp;
            <Moment format="DD/MM/YYYY HH:mm" unix>
              {ticket.clientDataResposta}
            </Moment>
          </small>
          {ticket.clientResposta === "1" ? (
            <>
              &nbsp;
              <Badge  variant="danger">Resposta client</Badge>
            </>
          ) : (
            ""
          )}
          {ticket.respostes !== "0" ? (
            <>
              &nbsp;
              <Badge variant="primary">
                {ticket.respostes}{" "}
                {ticket.respostes === "1" ? "acció" : "accions"}
              </Badge>
            </>
          ) : (
            ""
          )}
          {ticket.isCombinat === "1" && (
            <>
              &nbsp;
              <Badge variant="success">{"Tiquet combinat"}</Badge>
            </>
          )}
          {/* (ticket.dataResposta !== null && ticket.dataResposta < dataActual) ||
        (ticket.dataCaducitat !== null && ticket.dataCaducitat < dataActual) ? (
          <>
            {ticket.dataResposta !== null &&
            ticket.dataResposta !== 0 &&
            ticket.dataResposta !== "0" &&
            ticket.dataResposta < dataActual ? (
              <>
                &nbsp;
                <Badge variant="light">Primera resposta vençuda</Badge>
              </>
            ) : (
              ""
            )}

            {ticket.dataCaducitat !== null &&
            ticket.dataCaducitat < dataActual ? (
              <>
                &nbsp;
                <Badge variant="danger">Ticket caducat</Badge>
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        ) */}
        </td>
      </Link>

      <td className="hidden lg:table-cell">
        {/* TODO: CANVIAR COLORS DELS TAGS DEPENENT DEL SERVEI PRINCIPAL! */}
        <Badge
          className="badge"
          style={{
            backgroundColor: ticket.color,
            fontSize: "inherit",
            padding: "3px 5px",
            marginBottom: "2px",
          }}
        >
          {ticket.servei}
        </Badge>
        <Select
          options={serveis}
          className="inline-select-container-top"
          classNamePrefix="inline-select"
          placeholder=""
          styles={{
            control: (base) => ({
              ...base,
              border: 0,
              boxShadow: "none",
            }),
          }}
          onChange={(servei) => {
            handleCanvi(ticket.id, servei, "servei");
          }}
        />
        <br />
        <Badge
          style={{
            backgroundColor: ticket.color,
            fontSize: "inherit",
            padding: "3px 5px",
          }}
        >
          {ticket.subServei}
        </Badge>
        {
          <Select
            options={subServeis}
            className="inline-select-container-bottom"
            classNamePrefix="inline-select"
            placeholder=""
            styles={{
              control: (base) => ({
                ...base,
                border: 0,
                boxShadow: "none",
              }),
            }}
            onChange={(subServei) => {
              handleCanvi(ticket.id, subServei, "subServei");
            }}
          />
        }
      </td>
      <td className="hidden lg:table-cell">
        <Badge
          style={{ fontSize: "inherit" }}
          variant={
            ticket.prioritat === "Alta"
              ? "danger"
              : ticket.prioritat === "Mitja"
              ? "warning"
              : ticket.prioritat === "Baixa"
              ? "success"
              : ""
          }
        >
          {ticket.prioritat}
        </Badge>
      </td>
      <td >
        {ticket.assignat ? (
          <div className="flex justify-center lg:justify-start items-center">
            {ticket.fotoAssignat != null ? (
              <img
                className="rounded-full object-cover"
                loading="lazy"
                style={{
                  width: 30,
                  height: 30,
                  
                }}
                alt={ticket.assignat}
                src={`${process.env.REACT_APP_API_URL}/sat/ct/imatges/imatges-tecnics/${ticket.fotoAssignat}`}
              />
            ) : (
              ""
            )}
            &nbsp;
            <span className="hidden lg:block">{ticket.assignat} </span>
          </div>
        ) : (
          <span className="text-sm xs:text-xs">Per assignar</span>
        )}
        <Select
          options={tecnics}
          className="inline-select-container "
          classNamePrefix="inline-select"
          placeholder=""
          styles={{
            control: (base) => ({
              ...base,
              border: 0,
              boxShadow: "none",
            }),
          }}
          onChange={(assignat) => {
            handleCanvi(ticket.id, assignat, "assignat");
          }}
        />
      </td>

      <td className="hidden lg:table-cell">
        {ticket.estat}
        {estats ? (
          <Select
            options={estats}
            className="inline-select-container"
            classNamePrefix="inline-select"
            placeholder=""
            styles={{
              control: (base) => ({
                ...base,
                border: 0,
                boxShadow: "none",
              }),
            }}
            onChange={(estat) => {
              handleCanvi(ticket.id, estat, "estat");
            }}
          />
        ) : (
          ""
        )}
      </td>
    </tr>
  );
};

import React from "react";
import { Form, Button, Container, Row, Col, InputGroup } from "react-bootstrap";
import logo from "../assets/images/logo-gestinet.png";

const LoginForm = ({ login, setLogin, handleSubmit }) => {
  return (
    <Form className="login"  >
      <Container >
        <img
          src={logo}
          alt="Gestinet"
          style={{ width: 250, marginBottom: "3%" }}
        ></img>
        <h4 style={{ fontSize: "20px", marginBottom: "6%" }}>Suport tècnic</h4>
        <Row>
          <Col sm={12}>
            <Form.Group controlId="loginForm.usuari">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="grupUsuari">Usuari</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  value={login.usuari}
                  onChange={(e) =>
                    setLogin({ ...login, usuari: e.target.value })
                  }
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Form.Group controlId="loginForm.pwd">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="grupPwd">Contrasenya</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="password"
                  value={login.pwd}
                  onChange={(e) => setLogin({ ...login, pwd: e.target.value })}
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Button variant="primary" type="submit" onClick={handleSubmit}>
              Iniciar sessió
            </Button>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

export default LoginForm;

import React, { useState } from "react";
import Title from "../../components/shared/Title/Title";
import MaterialList from "./MaterialList/MaterialList";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useToasts } from "react-toast-notifications";

const ImputarMaterial = ({ ticket, albara }) => {
  const { addToast } = useToasts();
  const history = useHistory();

  const [materialEntregat, setMaterialEntregat] = useState([]);
  console.log(materialEntregat);

  /* const updateTicketStatus = () => {
    const ticketEstat = { idEstat: 4 };
    axios
      .put(
        `${process.env.REACT_APP_API_URL}api/tiquet/${ticket}/`,
        { ticket: ticketEstat },
        {
          auth: {
            username: process.env.REACT_APP_API_USER,
            password: process.env.REACT_APP_API_PWD,
          },
          headers: {
            token: JSON.parse(localStorage.getItem("userLogged")).token,
            idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
          },
        }
      )
      .then((res) => {
        //Redireccionem a la pàgina de tickets
        history.push(`/tickets/`);
        //TOAST OK
        addToast("Albarà creat correctament", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((error) => {
        console.log(error);
        //TOAST ERROR
        addToast("S'ha produït un error", {
          appearance: "error",
        });
      });
  }; */

  const handleSubmit = () => {
    document.getElementById("btnEndAndSendMail").disabled = true
    // Insertem la nova resposta a la base de dades (amb les dades del formulari al state)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/albara/imputar/${albara}/`,
        {
          materialEntregat,
        },
        {
          auth: {
            username: process.env.REACT_APP_API_USER,
            password: process.env.REACT_APP_API_PWD,
          },
          headers: {
            token: JSON.parse(localStorage.getItem("userLogged")).token,
            idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
          },
        }
      )
      .then((res) => {
        // Canviem el estat del ticket a resolt.
        // updateTicketStatus();
        // Redireccionem a la pàgina de tickets
        history.push(`/tickets/`);
        // TOAST OK
        addToast("Albarà creat correctament", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((error) => {
        console.log(error);
        // TOAST ERROR
        addToast("S'ha produït un error", {
          appearance: "error",
        });
        document.getElementById("btnEndAndSendMail").disabled = false
      });
  };
  return (
    <>
      {ticket > 0 ? (
        <Title
          title="Tiquets"
          title2={`#${ticket}`}
          title3={`Albarà #${albara} » Imputar material`}
          titleLink="/tickets"
          title2Link={`/ticket/${ticket}`}
        />
      ) : (
        ""
      )}
      <h2>Material imputat no entregat</h2>
      <MaterialList
        material="imputat"
        albara={albara}
        materialEntregat={materialEntregat}
        setMaterialEntregat={setMaterialEntregat}
      />

      <h2>Material genèric</h2>
      <MaterialList
        material="generic"
        materialEntregat={materialEntregat}
        setMaterialEntregat={setMaterialEntregat}
      />

      <h2>Material entregat</h2>
      <MaterialList
        material="entregat"
        materialEntregat={materialEntregat}
        setMaterialEntregat={setMaterialEntregat}
      />
      <div style={{ textAlign: "center", marginTop: "30px" }}>
        <Button id="btnEndAndSendMail" variant="primary" type="submit" onClick={handleSubmit}>
          Finalitzar i enviar email al client
        </Button>
      </div>
    </>
  );
};

export default ImputarMaterial;

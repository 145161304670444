import React, { useState, useEffect } from "react";

import { Container, Row, Col, Modal, Button, Form, Accordion, Card } from "react-bootstrap";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import TicketHeader from "../../TicketHeader/TicketHeader";

import { useToasts } from "react-toast-notifications";
import axios from "axios";

export default ({ ticket, resposta }) => {
  const { addToast } = useToasts();

  const [showEdit, setShowEdit] = useState(false);

  /* ELIMINAR NOTA */
  const [showDelete, setShowDelete] = useState(false);
  const [idDefault, ] = useState(ticket.respostes[ticket.respostes.length-1].id || 0);
  const handleDeleteClose = () => setShowDelete(false);
  const handleDeleteShow = () => setShowDelete(true);

  // Funció que connecta amb l'API de eliminar nota
  useEffect(() => {
   
    swapIcon()
  }, [ticket.respostes])

  const handleDelete = () => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}api/resposta/${resposta.id}`, {
        data: { resposta },
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      })
      .then((res) => {
        handleDeleteClose();
        addToast("Nota eliminada correctament", {
          appearance: "success",
          autoDismiss: true,
        });
        window.location.reload(false);
      });
  };

  /* EDITAR NOTA */
  const handleEdit = () => {
    axios
      .put(
        `${process.env.REACT_APP_API_URL}api/resposta/${resposta.id}/`,
        { resposta },
        {
          auth: {
            username: process.env.REACT_APP_API_USER,
            password: process.env.REACT_APP_API_PWD,
          },
          headers: {
            token: JSON.parse(localStorage.getItem("userLogged")).token,
            idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
          },
        }
      )
      .then((res) => {
        // Tanquem l'edició
        setShowEdit(false);
        // TOAST OK
        addToast("Nota modificada correctament", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((error) => {
        console.log(error);
        // TOAST ERROR
        addToast("S'ha produït un error", {
          appearance: "error",
        });
      });
  };
  const swapIcon = (e) => {
  
    setTimeout(() => {
      const elementShow = document.getElementsByClassName("collapse")
      for (const element of elementShow) {
        if(element.classList.contains("show")){
          element.parentElement.firstChild.firstChild.firstChild.className = "fa fa-minus"
        }else{
          element.parentElement.firstChild.firstChild.firstChild.className = "fa fa-plus"
        }
      }
    }, 400);
  }
  return (
    <>
      {/* MODAL ELIMINAR NOTA */}
      <Modal show={showDelete} onHide={handleDeleteClose}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar nota</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          <p>Vols eliminar la nota?</p>
          {/* <Button variant="secondary" onClick={handleGenerarAlbara}> */}
          <Button variant="secondary" onClick={handleDelete}>
            Si, eliminar definitivament
          </Button>
          &nbsp;
          {/* <Button variant="primary" onClick={handleTancarTiquet}> */}
          <Button variant="primary" onClick={handleDeleteClose}>
            No, cancelar
          </Button>
        </Modal.Body>
      </Modal>
      {console.log(idDefault)}
      <Accordion defaultActiveKey={idDefault}>
        <Card style={{
          backgroundColor:
            resposta.idEmissor === "-1"
              ? "rgb(224 255 220)"
              : resposta.tipus === "1"
                ? "#eff5fb"
                : resposta.tipus === "2"
                  ? "#fff7f0"
                  : resposta.tipus === "3"
                    ? "#feffec"
                    : "",
          padding: 15,
          paddingTop: 15,
          marginTop: 15,
        }} >
          <Accordion.Toggle as={"div"} eventKey={resposta.id} style={{ cursor: "pointer" }} onClick={(e) => swapIcon(e)}  >

            <TicketHeader ticket={ticket} resposta={resposta} isResposta={true} />

          </Accordion.Toggle>
          <Accordion.Collapse eventKey={resposta.id} >

            <Container

              style={{
                backgroundColor:
                  resposta.idEmissor === "-1"
                    ? "rgb(224 255 220)"
                    : resposta.tipus === "1"
                      ? "#eff5fb"
                      : resposta.tipus === "2"
                        ? "#fff7f0"
                        : resposta.tipus === "3"
                          ? "#feffec"
                          : "",
                padding: 15,
                paddingTop: 15,
                marginTop: 15,
                border:
                  resposta.idEmissor === "-1"
                    ? "1px solid rgb(206 222 204)"
                    : resposta.tipus === "1"
                      ? "1px solid #b8dbff"
                      : resposta.tipus === "2"
                        ? "1px solid #ffbebe"
                        : resposta.tipus === "3"
                          ? "1px solid #f5e093"
                          : "",
                textAlign: "justify",
              }}
            >


              <Row>

                {resposta.idEmissor ===
                  JSON.parse(localStorage.getItem("userLogged")).id ||
                  JSON.parse(localStorage.getItem("userLogged")).nivell === "1" ? (

                  <div className="icon-ticket" style={{ fontSize: "1.25rem" }}>
                    <i className="fa fa-pencil" onClick={() => setShowEdit(true)}></i>
                    &nbsp;&nbsp;
                    <i
                      className="fa fa-trash"
                      onClick={() => handleDeleteShow(true)}
                    ></i>
                  </div>
                ) : (
                  ""
                )}
                {/* EDICIO CAPÇALERA TICKET */}
                {showEdit ? (
                  <>
                    <Form.Group
                      controlId="exampleForm.ControlTextarea1"
                      style={{ width: "98%", margin: "0 1%" }}
                    >
                      <CKEditor
                        editor={ClassicEditor}
                        data={resposta.text ? resposta.text : ""}
                        onInit={(editor) => {
                          editor.editing.view.focus();
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          resposta.text = data;
                        }}
                        config={{
                          removePlugins: ["Heading"],
                          ckfinder: {
                            uploadUrl: `${process.env.REACT_APP_API_URL}ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images&responseType=json`,
                          },
                        }}
                      />
                    </Form.Group>
                    <div
                      style={{ textAlign: "center", marginBottom: 15, width: "100%" }}
                    >
                      <Button
                        variant="primary"
                        type="submit"
                        onClick={(e) => {
                          handleEdit(e);
                        }}
                      >
                        Actualitzar dades
                      </Button>
                      &nbsp;&nbsp;&nbsp;
                      <Button
                        variant="light"
                        type="button"
                        onClick={() => setShowEdit(false)}
                      >
                        Cancelar
                      </Button>
                    </div>
                  </>
                ) : (
                  <Col sm={12} dangerouslySetInnerHTML={{ __html: resposta.text }} />
                )}
              </Row>
              {/* ADJUNTS */}
              {resposta.adjunts
                ? resposta.adjunts.map((adjunt, i) => {
                  // console.log(adjunt);
                  return (
                    <p key={i} className="adjunts-container">
                      <img src={`/images/extensions/other.png`} alt="extension" />
                      <a
                        target="_BLANK"
                        rel="noopener noreferrer"
                        href={`${process.env.REACT_APP_API_URL}${adjunt.ruta}/${adjunt.nom}`}
                      >
                        {adjunt.nom}
                      </a>
                    </p>
                  );
                })
                : ""}
              {resposta.horesDedicades || resposta.minutsDedicats ? (
                <Row>
                  <Col sm={12} style={{ textAlign: "right", color: "grey" }}>
                    <small>
                      Temps invertit:{" "}
                      <strong>
                        {resposta.horesDedicades
                          ? resposta.horesDedicades === 1
                            ? resposta.horesDedicades + " hora"
                            : resposta.horesDedicades + " hores"
                          : ""}{" "}
                        {resposta.minutsDedicats
                          ? resposta.minutsDedicats + " minuts"
                          : ""}
                      </strong>
                    </small>
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </Container>
          </Accordion.Collapse>
        </Card>
      </Accordion>

    </>
  );
};

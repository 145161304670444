import React from "react";
// import { useHistory } from "react-router-dom";
// import Title from "../../components/shared/Title/Title";

import TicketList from "../../components/Tickets/TicketList/TicketList";

const Tickets = () => {
  // const history = useHistory();

  // const handleClick = () => {
  //   history.push("/nou-ticket");
  // };
  // const handleSplit = () => {
  //   history.push("/nou-email");
  // };
  

  return (
    <>
      {/* <Title
        button="Crear ticket"
        buttonAction={handleClick}
        splitButton="Crear email"
        splitButtonAction={handleSplit}
       
        title="Tickets"
      /> */}
      <TicketList isRecurrent={false} />
      
    </>
  );
};

export default Tickets;

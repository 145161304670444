import React from "react";
import { Container, Form, InputGroup, Button, Row, Col } from "react-bootstrap";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";

import axios from "axios";

import atera from "../../../assets/images/atera.svg";

import TicketHeader from "./TicketHeader/TicketHeader";

export default ({
  ticket,
  setTicket,
  handleSubmit,
  handleEdit,
  showEdit,
  empreses,
}) => {
  // HANDLE SELECCIONAR EMPRESA
  const handleClient = (client, canviarDades) => {
    if (canviarDades) {
      // Carreguem les dades de l'empresa client seleccionada
      getDadesEmpresa(client.value);
    }

    setTicket({
      ...ticket,
      idClient: client.value,
      client: client.label,
    });
  };

  const getDadesEmpresa = (idClient) => {
    // PETICIÓ API EMPRESES
    axios
      .get(`${process.env.REACT_APP_API_URL}api/empresa/${idClient}/`, {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      })
      .then((res) => {
        // Guardar dades de l'Empresa Seleccionada (Responsable, Contacte, Telefon...)
        setTicket({
          ...ticket,
          idClient: res.data.id,
          telefon: res.data.telefon1,
          contacte: res.data.contacte,
          email: res.data.email,
        });
      });
  };

  // console.log(ticket.idClient);
  return ticket ? (
    <>
      <Container
      className=' '
        style={{
          backgroundColor: "#fafafa",
          padding: 15,
          border: "1px solid #ced9e8",
          overflowY: "auto"
        }}
      >
        <Row>
          <Col sm={10} style={{ marginBottom: "0.5rem" }}>
            {showEdit && empreses ? (
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">
                    Client
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Select
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                  className="select"
                  options={empreses}
                  value={empreses.filter(
                    (empresa) => empresa.value === ticket.idClient
                  )}
                  placeholder="Seleccionar client"
                  onChange={(client) => {
                    handleClient(client, false);
                  }}
                />
              </InputGroup>
            ) : ticket.client ? (
              <h3>{ticket.client}</h3>
            ) : (
              ""
            )}
          </Col>
          <Col sm={2} style={{ textAlign: "right" }}>
            {ticket.url_jedi ? (
              <a
                href={ticket.url_jedi}
                rel="noopener noreferrer"
                target="_BLANK"
                title="Accedir al JEDI"
              >
                <i
                  className="fa fa-book"
                  style={{ fontSize: "25px", color: "#34588d" }}
                ></i>
              </a>
            ) : (
              ""
            )}
            {ticket.url_atera ? (
              <a
                href={ticket.url_atera}
                rel="noopener noreferrer"
                target="_BLANK"
                title="Accedir a ATERA"
              >
                <img
                  src={atera}
                  alt="atera"
                  style={{
                    width: "22px",
                    float: "right",
                    marginLeft: "15px",
                  }}
                />
              </a>
            ) : (
              ""
            )}
          </Col>
        </Row>

        <TicketHeader
          key={ticket.id}
          ticket={ticket}
          setTicket={setTicket}
          showEdit={showEdit}
        />

        {showEdit ? (
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id="inputGroupPrepend">
                  Assumpte
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                value={ticket.assumpte}
                maxLength="100"
                onChange={(assumpte) => {
                  setTicket({
                    ...ticket,
                    assumpte: assumpte.target.value,
                  });
                }}
              />
            </InputGroup>
          </Form.Group>
        ) : (
          <p style={{ fontSize: "1.1rem" }}>
            <strong>{ticket.assumpte}</strong>
          </p>
        )}

        {showEdit ? (
          <>
            <Form.Group controlId="exampleForm.ControlTextarea1">
            
              <InputGroup.Prepend>
                <InputGroup.Text id="inputGroupPrepend">Dades</InputGroup.Text>
              </InputGroup.Prepend>
              <CKEditor
                editor={ClassicEditor}
                data={ticket.descripcio ? ticket.descripcio : ""}
                onInit={(editor) => {
                  editor.editing.view.focus();
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setTicket({
                    ...ticket,
                    descripcio: data,
                  });
                }}
                config={{
                  removePlugins: ["Heading"],
                  ckfinder: {
                    uploadUrl: `${process.env.REACT_APP_API_URL}ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images&responseType=json`,
                  },
                }}
              />
            </Form.Group>
    

            <div style={{ textAlign: "center", marginBottom: 15 }}>
              <Button
                variant="primary"
                type="submit"
                onClick={(e) => {
                  handleSubmit(e);
                  handleEdit();
                  window.scrollTo(0, 0); // Anar al principi de la pàgina
                  // window.location.reload(false);
                }}
              >
                Actualitzar dades
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button variant="light" type="button" onClick={handleEdit}>
                Cancelar
              </Button>
            </div>
          </>
        ) : (
          <p
            style={{ textAlign: "justify" }}
            dangerouslySetInnerHTML={{ __html: ticket.descripcio }}
          />
        )}

        {/* ADJUNTS */}
        {ticket.adjunts
          ? ticket.adjunts.map((adjunt,i ) => {
              // console.log(adjunt);
              return (
                <p key={i} className="adjunts-container">
                  {/* <img
                    src={`/images/extensions/${adjunt.extensio}.png`}
                    alt="extension"
                  /> */}
                  <a
                    target="_BLANK"
                    rel="noopener noreferrer"
                    href={`${process.env.REACT_APP_API_URL}${adjunt.ruta}/${adjunt.nom}`}
                  >
                    {adjunt.nom}
                  </a>
                </p>
              );
            })
          : ""}
      </Container>
    </>
  ) : (
    ""
  );
};

import React, { useState, useEffect } from "react";
import "tailwindcss/tailwind.css";
import axios from "axios";
import { Form, Button, Container, Row, Col, InputGroup } from "react-bootstrap";
import Select, { components } from "react-select";

import arrowDownIcon from "../assets/images/arrow-down.svg";
import { useToasts } from "react-toast-notifications";
import { CgSpinner } from "react-icons/cg";
import { BsTrash } from "react-icons/bs";

const AlbaraZammadForm = ({
	albara,
	setAlbara,
	handleSubmit,
	zammadTicketId,
	zammadUserId,
	zammadUserName,
	zammadOrganizationId,
	zammadTicketTimeUnit,
	zammadUserTimeUnit,
	gestinetEmpreses,
	gestinetServeis,
	gestinetSubServeis,
	gestinetTecnics,
	gestinetMinutes,
	getCurrentMinutes,
	setCurrentMinutes,
}) => {
	const { addToast } = useToasts();

	const [getFilteredSubServeis, setFilteredSubServeis] = useState(
		albara.idServei
			? gestinetSubServeis.filter(
					(sub) => Number(sub.idServei) === Number(albara.idServei)
			  )
			: gestinetSubServeis
	);
	const [getTecnicEmptyError, setTecnicEmptyError] = useState(false);
	const [getEmpresaEmptyError, setEmpresaEmptyError] = useState(false);
	const [getIncidenciaEmptyError, setIncidenciaEmptyError] = useState(false);
	const [getServeiEmptyError, setServeiEmptyError] = useState(false);
	const [getSubServeiEmptyError, setSubServeiEmptyError] = useState(false);
	const [getMinutsFacturatsEmptyError, setMinutsFacturatsEmptyError] =
		useState(false);
	const [getScrollState, setScrollState] = useState(false);

	const handleSubServeis = (idServei) => {
		const filteredSubServeis = gestinetSubServeis.filter(
			(sub) => Number(sub.idServei) === Number(idServei)
		);

		setFilteredSubServeis(filteredSubServeis);
	};

	const validationSchema = (e) => {
		if (
			albara.idTicket &&
			albara.idTicket !== null &&
			albara.idTicket !== "" &&
			albara.idTecnic &&
			albara.idTecnic !== null &&
			albara.idTecnic !== "" &&
			albara.empresa &&
			albara.empresa !== null &&
			albara.empresa !== "" &&
			albara.incidencia &&
			albara.incidencia !== null &&
			albara.incidencia !== "" &&
			albara.incidencia.trim() !== "" &&
			albara.idServei &&
			albara.idServei !== null &&
			albara.idServei !== "" &&
			albara.idSubServei &&
			albara.idSubServei !== null &&
			albara.idSubServei !== "" &&
			albara.minuts_facturats &&
			albara.minuts_facturats !== null &&
			albara.minuts_facturats !== "" &&
			albara.minuts_facturats !== 0
		) {
			console.log(
				"🚀 ~ file: AlbaraZammadForm.js:76 ~ validationSchema ~ albara:",
				albara
			);
			handleSubmit(e);
		} else {
			addToast("Els camps obligatoris no poden estar buits!", {
				appearance: "error",
				autoDismiss: true,
			});
		}

		if (albara.idTecnic && albara.idTecnic !== null && albara.idTecnic !== "") {
			setTecnicEmptyError(false);
		} else {
			setTecnicEmptyError(true);
		}

		if (albara.empresa && albara.empresa !== null && albara.empresa !== "") {
			setEmpresaEmptyError(false);
		} else {
			setEmpresaEmptyError(true);
		}

		if (
			albara.incidencia &&
			albara.incidencia !== null &&
			albara.incidencia !== "" &&
			albara.incidencia.trim() !== ""
		) {
			setIncidenciaEmptyError(false);
		} else {
			setIncidenciaEmptyError(true);
		}

		if (albara.idServei && albara.idServei !== null && albara.idServei !== "") {
			setServeiEmptyError(false);
		} else {
			setServeiEmptyError(true);
		}

		if (
			albara.idSubServei &&
			albara.idSubServei !== null &&
			albara.idSubServei !== ""
		) {
			setSubServeiEmptyError(false);
		} else {
			setSubServeiEmptyError(true);
		}

		if (
			albara.minuts_facturats &&
			albara.minuts_facturats !== null &&
			albara.minuts_facturats !== "" &&
			albara.minuts_facturats !== 0
		) {
			setMinutsFacturatsEmptyError(false);
		} else {
			setMinutsFacturatsEmptyError(true);
		}
	};

	const DropdownIndicator = (props) => {
		return (
			<components.DropdownIndicator {...props}>
				<CgSpinner size={20} className="animate-spin" />
			</components.DropdownIndicator>
		);
	};

	useEffect(() => {
		const textAlbaraInput = document.getElementById("textAlbaraInput");
		if (textAlbaraInput.scrollHeight > textAlbaraInput.clientHeight) {
			!getScrollState && setScrollState(true);
		} else {
			getScrollState && setScrollState(false);
		}
	}, [albara?.incidencia]);

	return gestinetEmpreses && gestinetTecnics ? (
		<Form>
			<Container>
				<Row>
					{/* COLUMNA 1 */}
					<Col sm={8}>
						{/* TÈCNIC */}
						<div className="items-center block w-full pb-4 print:bg-white">
							<label
								className="block uppercase col-start-full font-zammad-sans"
								htmlFor="name"
								style={{
									display: "block",
									textAlign: "start",
									color: "#A0A3A6",
									fontSize: "13px",
									letterSpacing: "0.1em",
									fontWeight: "400",
									lineHeight: "1.45",
									marginBottom: "5px",
								}}
							>
								TÈCNIC
							</label>

							{gestinetTecnics !== null &&
							gestinetTecnics.length > 0 &&
							albara?.idTecnic !== null ? (
								<Select
									className="flex-grow rounded-l-none"
									onChange={(e) => {
										getTecnicEmptyError && setTecnicEmptyError(false);
										setAlbara((prevState) => ({
											...prevState,
											idTecnic: e.id,
										}));
									}}
									placeholder="-"
									options={gestinetTecnics}
									getOptionLabel={(o) => o.nom}
									getOptionValue={(o) => o.id}
									styles={{
										control: (baseStyles, state) => ({
											...baseStyles,
											backgroundColor: state.isDisabled
												? getTecnicEmptyError
													? "#FEF2F2"
													: "#F2F2F2"
												: getTecnicEmptyError
												? "#FEF2F2"
												: "#F9FAFB",
											// border: state.isFocused
											// 	? getTecnicEmptyError
											// 		? "2px solid #F87171"
											// 		: "2px solid #9ca3af"
											// 	: getTecnicEmptyError
											// 	? "2px solid #FCA5A5"
											// 	: "2px solid #e6e6e6",
											border: state.isFocused
												? getTecnicEmptyError
													? "2px solid #F87171"
													: "2px solid #4cafe1"
												: getTecnicEmptyError
												? "2px solid #FCA5A5"
												: "2px solid #e6e6e6",
											borderTopLeftRadius: "3px",
											borderTopRightRadius: "3px",
											borderBottomRightRadius: "3px",
											borderBottomLeftRadius: "3px",
											boxShadow: state.isFocused ? "0 0 0 3px #d6eaf5" : "none",
											"&:hover": {
												border: getTecnicEmptyError
													? "2px solid #F87171"
													: "2px solid #4cafe1",
											},
											margin: "0px",
											minHeight: "fit-content",
											fontFamily: "Montserrat, sans-serif",
											letterSpacing: "0.05em",
											fontSize: "14px",
											cursor: state.isDisabled ? "not-allowed" : "pointer",
										}),
										option: (baseStyles, state) => ({
											...baseStyles,
											color: "#333333",
											backgroundColor: state.isFocused && "#d1d5db",
											"&:hover": {
												backgroundColor: "#f3f4f6",
											},
										}),
										valueContainer: (baseStyles, state) => ({
											...baseStyles,
											padding: "7px 12px",
											margin: "0px",
											color: "#585856",
										}),
										dropdownIndicator: (baseStyles, state) => ({
											...baseStyles,
											padding: "2px 8px",
											opacity: "0.39",
											color: state.isDisabled
												? "#D6D6D6"
												: getTecnicEmptyError
												? "#FCA5A5"
												: "#a0a3a6",
											"&:hover": {
												color:
													!state.isDisabled && getTecnicEmptyError && "#F87171",
											},
										}),
										indicatorSeparator: (baseStyles) => ({
											...baseStyles,
											display: "none",
										}),
										// Fixes the overlapping problem:
										menu: (provided) => ({
											...provided,
											zIndex: 9999,
										}),
									}}
									value={gestinetTecnics.filter(
										(tecnic) => Number(tecnic.id) === Number(albara.idTecnic)
									)}
									isDisabled={zammadUserId}
								/>
							) : (
								<Select
									components={{ DropdownIndicator }}
									className="flex-grow rounded-l-none"
									placeholder=""
									styles={{
										control: (baseStyles, state) => ({
											...baseStyles,
											border: "2px solid #d1d5db",
											borderTopLeftRadius: 0,
											borderBottomLeftRadius: 0,
											boxShadow: "none",
											margin: "0px",
											minHeight: "fit-content",
											cursor: "not-allowed",
										}),
										valueContainer: (baseStyles, state) => ({
											...baseStyles,
											padding: "0px 8px",
											margin: "0px",
										}),
										dropdownIndicator: (baseStyles, state) => ({
											...baseStyles,
											padding: "2px 8px",
											color: "#D6D6D6",
										}),
										// Fixes the overlapping problem:
										menu: (provided) => ({
											...provided,
											zIndex: 9999,
										}),
									}}
									isDisabled
								/>
							)}
						</div>

						{/* CLIENT */}
						<div className="items-center block w-full pb-4 print:bg-white">
							<label
								className="block uppercase col-start-full font-zammad-sans"
								htmlFor="name"
								style={{
									display: "block",
									textAlign: "start",
									color: "#A0A3A6",
									fontSize: "13px",
									letterSpacing: "0.1em",
									fontWeight: "400",
									lineHeight: "1.45",
									marginBottom: "5px",
								}}
							>
								CLIENT
							</label>

							{gestinetEmpreses !== null &&
							gestinetEmpreses.length > 0 &&
							albara?.empresa !== null ? (
								<Select
									className="flex-grow rounded-l-none"
									onChange={(e) => {
										getEmpresaEmptyError && setEmpresaEmptyError(false);
										setAlbara((prevState) => ({
											...prevState,
											empresa: e.id,
										}));
									}}
									placeholder="-"
									options={gestinetEmpreses}
									getOptionLabel={(o) => o.nom}
									getOptionValue={(o) => o.id}
									styles={{
										control: (baseStyles, state) => ({
											...baseStyles,
											backgroundColor: state.isDisabled
												? getEmpresaEmptyError
													? "#FEF2F2"
													: "#F2F2F2"
												: getEmpresaEmptyError
												? "#FEF2F2"
												: "#F9FAFB",
											// border: state.isFocused
											// 	? getEmpresaEmptyError
											// 		? "2px solid #F87171"
											// 		: "2px solid #9ca3af"
											// 	: getEmpresaEmptyError
											// 	? "2px solid #FCA5A5"
											// 	: "2px solid #e6e6e6",
											border: state.isFocused
												? getEmpresaEmptyError
													? "2px solid #F87171"
													: "2px solid #4cafe1"
												: getEmpresaEmptyError
												? "2px solid #FCA5A5"
												: "2px solid #e6e6e6",
											borderTopLeftRadius: "3px",
											borderTopRightRadius: "3px",
											borderBottomRightRadius: "3px",
											borderBottomLeftRadius: "3px",
											boxShadow: state.isFocused ? "0 0 0 3px #d6eaf5" : "none",
											"&:hover": {
												border: getEmpresaEmptyError
													? "2px solid #F87171"
													: "2px solid #4cafe1",
											},
											margin: "0px",
											minHeight: "fit-content",
											fontFamily: "Montserrat, sans-serif",
											letterSpacing: "0.05em",
											fontSize: "14px",
											cursor: state.isDisabled ? "not-allowed" : "pointer",
										}),
										option: (baseStyles, state) => ({
											...baseStyles,
											color: "#333333",
											backgroundColor: state.isFocused && "#d1d5db",
											"&:hover": {
												backgroundColor: "#f3f4f6",
											},
										}),
										valueContainer: (baseStyles, state) => ({
											...baseStyles,
											padding: "7px 12px",
											margin: "0px",
											color: "#585856",
										}),
										dropdownIndicator: (baseStyles, state) => ({
											...baseStyles,
											padding: "2px 8px",
											opacity: "0.39",
											color: state.isDisabled
												? "#D6D6D6"
												: getEmpresaEmptyError
												? "#FCA5A5"
												: "#a0a3a6",
											"&:hover": {
												color:
													!state.isDisabled &&
													getEmpresaEmptyError &&
													"#F87171",
											},
										}),
										indicatorSeparator: (baseStyles) => ({
											...baseStyles,
											display: "none",
										}),
										// Fixes the overlapping problem:
										menu: (provided) => ({
											...provided,
											zIndex: 9999,
										}),
									}}
									value={gestinetEmpreses.filter(
										(empresa) => Number(empresa.id) === Number(albara.empresa)
									)}
									isDisabled={zammadOrganizationId}
								/>
							) : (
								<Select
									components={{ DropdownIndicator }}
									className="flex-grow rounded-l-none"
									placeholder=""
									styles={{
										control: (baseStyles, state) => ({
											...baseStyles,
											border: state.isFocused
												? "2px solid #9ca3af"
												: "2px solid #d1d5db",
											borderTopLeftRadius: 0,
											borderBottomLeftRadius: 0,
											boxShadow: state.isFocused ? "none" : "none",
											"&:hover": {
												border: "2px solid #9ca3af",
											},
											margin: "0px",
											minHeight: "fit-content",
											cursor: state.isDisabled ? "not-allowed" : "pointer",
										}),
										option: (baseStyles, state) => ({
											...baseStyles,
											color: "#333333",
											backgroundColor: state.isFocused && "#d1d5db",
											"&:hover": {
												backgroundColor: "#f3f4f6",
											},
										}),
										valueContainer: (baseStyles, state) => ({
											...baseStyles,
											padding: "0px 8px",
											margin: "0px",
										}),
										dropdownIndicator: (baseStyles, state) => ({
											...baseStyles,
											padding: "2px 8px",
											color: "#D6D6D6",
										}),
										// Fixes the overlapping problem:
										menu: (provided) => ({
											...provided,
											zIndex: 9999,
										}),
									}}
									isDisabled
								/>
							)}
						</div>

						{/* TEXT ALBARÀ */}
						<div className="items-center block w-full pb-4 print:bg-white">
							<label
								className="block uppercase col-start-full font-zammad-sans"
								htmlFor="name"
								style={{
									display: "block",
									textAlign: "start",
									color: "#A0A3A6",
									fontSize: "13px",
									letterSpacing: "0.1em",
									fontWeight: "400",
									lineHeight: "1.45",
									marginBottom: "5px",
								}}
							>
								TEXT ALBARÀ
							</label>

							<div className="relative">
								<Form.Control
									id="textAlbaraInput"
									as="textarea"
									className={`shadow-none outline-none resize-none text-start transition-all duration-300
									${
										getIncidenciaEmptyError &&
										(!albara?.incidencia || albara?.incidencia.trim() === "")
											? "bg-red-50 border-red-300 hover:border-red-400 focus:border-red-400"
											: "hover:border-zammad-blue focus:shadow-input-zammad focus:border-zammad-blue"
									}`}
									style={{
										backgroundColor: "#F9FAFB",
										border: "2px solid #e6e6e6",
										borderRadius: "3px",
									}}
									onChange={(e) => {
										console.log("🚀 ~ file: AlbaraZammadForm.js:522 ~ e:", e);
										getIncidenciaEmptyError && setIncidenciaEmptyError(false);
										const incidenciaValue = e.target.value;

										(!incidenciaValue || incidenciaValue.trim() === "") &&
											setIncidenciaEmptyError(true);

										setAlbara((prevState) => ({
											...prevState,
											incidencia: incidenciaValue,
										}));

										// console.log("🚀 ~ file: AlbaraZammadForm.js:535 ~ e.current.scrollHeight:", e.current)
										// console.log("🚀 ~ file: AlbaraZammadForm.js:535 ~ e.current.scrollHeight:", e.currentTarget)
										// console.log("🚀 ~ file: AlbaraZammadForm.js:535 ~ e.current.scrollHeight:", e.currentTarget.scrollHeight)
										// console.log("🚀 ~ file: AlbaraZammadForm.js:535 ~ e.current.scrollHeight:", e.currentTarget.clientHeight)

										if (
											e.currentTarget.scrollHeight >
											e.currentTarget.clientHeight
										) {
											!getScrollState && setScrollState(true);
										} else {
											getScrollState && setScrollState(false);
										}
									}}
									rows="10"
									value={albara?.incidencia}
								/>

								<button
									className={`absolute top-0 right-0 p-1.5 mt-2 transition-all duration-100 ${
										getScrollState ? "mr-6" : "mr-2"
									} text-white bg-red-800 border-2 hover:shadow border-red-800 rounded shadow-none outline-none hover:bg-red-100 hover:text-red-800`}
									type="button"
									onClick={() => {
										setAlbara((prevState) => ({
											...prevState,
											incidencia: "",
											minuts_facturats: 0,
										}));

										setCurrentMinutes(0);

										setScrollState(false);

										const notesDivs = document.querySelectorAll(".notesDiv");
										for (const note of notesDivs) {
											note.style.opacity = "1";
											note.style.pointerEvents = "auto";
										}

										const timeDivs = document.querySelectorAll(".timeDiv");
										for (const time of timeDivs) {
											time.style.opacity = "1";
											time.style.pointerEvents = "auto";
										}
									}}
								>
									<BsTrash size={25} />
								</button>
							</div>
						</div>

						{/* BOTONS 'Crear albarà' I 'Cancelar' */}
						<div
							style={{
								textAlign: "center",
								marginTop: "2rem",
							}}
						>
							<Button
								id="btnCrearAlbara"
								// className="mr-2 border-2 shadow-none outline-none hover:bg-blue-100 hover:text-blue-900"
								className="mr-2 border-2 shadow-none outline-none"
								variant="primary"
								type="button"
								onClick={(e) => validationSchema(e)}
								// onClick={(e) => {
								// 	console.log("Albara per facturar: ", albara)
								// }}
							>
								Crear albarà
							</Button>
							<Button
								// className="border-2 shadow-none outline-none hover:bg-gray-100 hover:text-gray-900"
								className="border-2 shadow-none outline-none"
								variant="light"
								type="button"
								onClick={() =>
									(window.location.href = `https://zammad.gestinet.com/#ticket/zoom/${zammadTicketId}`)
								}
							>
								Cancelar
							</Button>
						</div>
					</Col>

					{/* COLUMNA 2 */}
					<Col sm={4}>
						{/* SERVEI */}
						<div className="items-center block w-full pb-4 print:bg-white">
							<label
								className="block uppercase col-start-full font-zammad-sans"
								htmlFor="name"
								style={{
									display: "block",
									textAlign: "start",
									color: "#A0A3A6",
									fontSize: "13px",
									letterSpacing: "0.1em",
									fontWeight: "400",
									lineHeight: "1.45",
									marginBottom: "5px",
								}}
							>
								Servei
							</label>

							{gestinetServeis !== null &&
							gestinetServeis.length > 0 &&
							albara?.idServei !== null ? (
								<Select
									className="flex-grow rounded-l-none"
									onChange={(e) => {
										getServeiEmptyError && setServeiEmptyError(false);
										setAlbara((prevState) => ({
											...prevState,
											idServei: e.id,
											idSubServei: 0,
										}));
										handleSubServeis(e.id);
									}}
									placeholder="-"
									options={gestinetServeis}
									getOptionLabel={(o) => o.nom}
									getOptionValue={(o) => o.id}
									styles={{
										control: (baseStyles, state) => ({
											...baseStyles,
											backgroundColor: state.isDisabled
												? getServeiEmptyError
													? "#FEF2F2"
													: "#F2F2F2"
												: getServeiEmptyError
												? "#FEF2F2"
												: "#F9FAFB",
											// border: state.isFocused
											// 	? getServeiEmptyError
											// 		? "2px solid #F87171"
											// 		: "2px solid #9ca3af"
											// 	: getServeiEmptyError
											// 	? "2px solid #FCA5A5"
											// 	: "2px solid #e6e6e6",
											border: state.isFocused
												? getServeiEmptyError
													? "2px solid #F87171"
													: "2px solid #4cafe1"
												: getServeiEmptyError
												? "2px solid #FCA5A5"
												: "2px solid #e6e6e6",
											borderTopLeftRadius: "3px",
											borderTopRightRadius: "3px",
											borderBottomRightRadius: "3px",
											borderBottomLeftRadius: "3px",
											boxShadow: state.isFocused ? "0 0 0 3px #d6eaf5" : "none",
											"&:hover": {
												border: getServeiEmptyError
													? "2px solid #F87171"
													: "2px solid #4cafe1",
											},
											margin: "0px",
											minHeight: "fit-content",
											fontFamily: "Montserrat, sans-serif",
											letterSpacing: "0.05em",
											fontSize: "14px",
											cursor: state.isDisabled ? "not-allowed" : "pointer",
										}),
										option: (baseStyles, state) => ({
											...baseStyles,
											color: "#333333",
											backgroundColor: state.isFocused && "#d1d5db",
											"&:hover": {
												backgroundColor: "#f3f4f6",
											},
										}),
										valueContainer: (baseStyles, state) => ({
											...baseStyles,
											padding: "7px 12px",
											margin: "0px",
											color: "#585856",
										}),
										dropdownIndicator: (baseStyles, state) => ({
											...baseStyles,
											padding: "2px 8px",
											opacity: "0.39",
											color: state.isDisabled
												? "#D6D6D6"
												: getServeiEmptyError
												? "#FCA5A5"
												: "#a0a3a6",
											"&:hover": {
												color:
													!state.isDisabled && getServeiEmptyError && "#F87171",
											},
										}),
										indicatorSeparator: (baseStyles) => ({
											...baseStyles,
											display: "none",
										}),
										// Fixes the overlapping problem:
										menu: (provided) => ({
											...provided,
											zIndex: 9999,
										}),
									}}
									value={gestinetServeis.filter(
										(servei) => Number(servei.id) === Number(albara.idServei)
									)}
								/>
							) : (
								<Select
									components={{ DropdownIndicator }}
									className="flex-grow rounded-l-none"
									placeholder=""
									styles={{
										control: (baseStyles, state) => ({
											...baseStyles,
											border: state.isFocused
												? "2px solid #9ca3af"
												: "2px solid #d1d5db",
											borderTopLeftRadius: 0,
											borderBottomLeftRadius: 0,
											boxShadow: state.isFocused ? "none" : "none",
											"&:hover": {
												border: "2px solid #9ca3af",
											},
											margin: "0px",
											minHeight: "fit-content",
											cursor: state.isDisabled ? "not-allowed" : "pointer",
										}),
										option: (baseStyles, state) => ({
											...baseStyles,
											color: "#333333",
											backgroundColor: state.isFocused && "#d1d5db",
											"&:hover": {
												backgroundColor: "#f3f4f6",
											},
										}),
										valueContainer: (baseStyles, state) => ({
											...baseStyles,
											padding: "0px 8px",
											margin: "0px",
										}),
										dropdownIndicator: (baseStyles, state) => ({
											...baseStyles,
											padding: "2px 8px",
											color: "#D6D6D6",
										}),
										// Fixes the overlapping problem:
										menu: (provided) => ({
											...provided,
											zIndex: 9999,
										}),
									}}
									isDisabled
								/>
							)}
						</div>

						{/* SUBSERVEI */}
						<div className="items-center block w-full pb-10 print:bg-white">
							<label
								className="block uppercase col-start-full font-zammad-sans"
								htmlFor="name"
								style={{
									display: "block",
									textAlign: "start",
									color: "#A0A3A6",
									fontSize: "13px",
									letterSpacing: "0.1em",
									fontWeight: "400",
									lineHeight: "1.45",
									marginBottom: "5px",
								}}
							>
								Sub Servei
							</label>

							{getFilteredSubServeis !== null &&
							getFilteredSubServeis.length > 0 &&
							albara?.idSubServei !== null ? (
								<Select
									className="flex-grow rounded"
									onChange={(e) => {
										getSubServeiEmptyError && setSubServeiEmptyError(false);
										setAlbara((prevState) => ({
											...prevState,
											idSubServei: e.id,
										}));
									}}
									placeholder="-"
									options={getFilteredSubServeis}
									getOptionLabel={(o) => o.nom}
									getOptionValue={(o) => o.id}
									styles={{
										control: (baseStyles, state) => ({
											...baseStyles,
											backgroundColor: state.isDisabled
												? getSubServeiEmptyError
													? "#FEF2F2"
													: "#F2F2F2"
												: getSubServeiEmptyError
												? "#FEF2F2"
												: "#F9FAFB",
											// border: state.isFocused
											// 	? getSubServeiEmptyError
											// 		? "2px solid #F87171"
											// 		: "2px solid #9ca3af"
											// 	: getSubServeiEmptyError
											// 	? "2px solid #FCA5A5"
											// 	: "2px solid #e6e6e6",
											border: state.isFocused
												? getSubServeiEmptyError
													? "2px solid #F87171"
													: "2px solid #4cafe1"
												: getSubServeiEmptyError
												? "2px solid #FCA5A5"
												: "2px solid #e6e6e6",
											borderTopLeftRadius: "3px",
											borderTopRightRadius: "3px",
											borderBottomRightRadius: "3px",
											borderBottomLeftRadius: "3px",
											boxShadow: state.isFocused ? "0 0 0 3px #d6eaf5" : "none",
											"&:hover": {
												border: getSubServeiEmptyError
													? "2px solid #F87171"
													: "2px solid #4cafe1",
											},
											margin: "0px",
											minHeight: "fit-content",
											fontFamily: "Montserrat, sans-serif",
											letterSpacing: "0.05em",
											fontSize: "14px",
											cursor: state.isDisabled ? "not-allowed" : "pointer",
										}),
										option: (baseStyles, state) => ({
											...baseStyles,
											color: "#333333",
											backgroundColor: state.isFocused && "#d1d5db",
											"&:hover": {
												backgroundColor: "#f3f4f6",
											},
										}),
										valueContainer: (baseStyles, state) => ({
											...baseStyles,
											padding: "7px 12px",
											margin: "0px",
											color: "#585856",
										}),
										dropdownIndicator: (baseStyles, state) => ({
											...baseStyles,
											padding: "2px 8px",
											opacity: "0.39",
											color: state.isDisabled
												? "#D6D6D6"
												: getSubServeiEmptyError
												? "#FCA5A5"
												: "#a0a3a6",
											"&:hover": {
												color:
													!state.isDisabled &&
													getSubServeiEmptyError &&
													"#F87171",
											},
										}),
										indicatorSeparator: (baseStyles) => ({
											...baseStyles,
											display: "none",
										}),
										// Fixes the overlapping problem:
										menu: (provided) => ({
											...provided,
											zIndex: 9999,
										}),
									}}
									value={getFilteredSubServeis.filter(
										(subServei) => subServei.id === albara.idSubServei
									)}
								/>
							) : (
								<Select
									components={{ DropdownIndicator }}
									className="flex-grow rounded-l-none"
									placeholder=""
									styles={{
										control: (baseStyles, state) => ({
											...baseStyles,
											border: state.isFocused
												? "2px solid #9ca3af"
												: "2px solid #d1d5db",
											borderTopLeftRadius: 0,
											borderBottomLeftRadius: 0,
											boxShadow: state.isFocused ? "none" : "none",
											"&:hover": {
												border: "2px solid #9ca3af",
											},
											margin: "0px",
											minHeight: "fit-content",
											cursor: state.isDisabled ? "not-allowed" : "pointer",
										}),
										option: (baseStyles, state) => ({
											...baseStyles,
											color: "#333333",
											backgroundColor: state.isFocused && "#d1d5db",
											"&:hover": {
												backgroundColor: "#f3f4f6",
											},
										}),
										valueContainer: (baseStyles, state) => ({
											...baseStyles,
											padding: "0px 8px",
											margin: "0px",
										}),
										dropdownIndicator: (baseStyles, state) => ({
											...baseStyles,
											padding: "2px 8px",
											color: "#D6D6D6",
										}),
										// Fixes the overlapping problem:
										menu: (provided) => ({
											...provided,
											zIndex: 9999,
										}),
									}}
									isDisabled
								/>
							)}
						</div>

						{/* TEMPS TOTAL INVERTIT */}
						<div
							className="relative w-full mb-3 h-fit"
							style={{
								background:
									"repeating-linear-gradient(45deg, #fcd9c9, #fcd9c9 5px, #feede6 5px, #feede6 10px)",
								backgroundSize: "14px 14px",
								backgroundPosition: "-1px",
								padding: "5px",
								borderRadius: "5px",
								fontFamily: "Montserrat, sans",
							}}
						>
							{!zammadTicketTimeUnit ? (
								<CgSpinner size={38} className="animate-spin" />
							) : (
								<div
									className="flex flex-col items-start justify-center w-full h-full"
									style={{
										border: "1px solid #feede6",
										padding: "10px 20px",
										background: "white",
										borderRadius: "2px",
										boxShadow: "0 0 1px rgba(0,0,0,0.06) inset",
									}}
								>
									<Row className={`${!zammadTicketTimeUnit && "hidden"}`}>
										<Col
											sm={12}
											style={{
												textAlign: "center",
												color: "grey",
											}}
										>
											<span style={{ fontSize: "14px", color: "#585856" }}>
												Temps total invertit:{" "}
												<strong
													className={`${
														(!zammadTicketTimeUnit.time ||
															zammadTicketTimeUnit.time === null ||
															zammadTicketTimeUnit.time === "") &&
														"hidden"
													}`}
												>
													{zammadTicketTimeUnit.hours
														? zammadTicketTimeUnit.hours === 1
															? zammadTicketTimeUnit.hours + " hora"
															: zammadTicketTimeUnit.hours + " hores"
														: ""}{" "}
													{zammadTicketTimeUnit.minutes
														? zammadTicketTimeUnit.minutes + " minuts"
														: ""}
												</strong>
												<strong
													className={`${
														zammadTicketTimeUnit.time &&
														zammadTicketTimeUnit.time !== null &&
														zammadTicketTimeUnit.time !== "" &&
														"hidden"
													}`}
												>
													{"0 hores 0 minuts"}
												</strong>
											</span>
										</Col>
									</Row>
								</div>
							)}
						</div>

						{/* TEMPS TOTAL INVERTIT (Facturador Actual) */}
						<div
							className="relative w-full mb-3 h-fit"
							style={{
								background:
									"repeating-linear-gradient(45deg, #fcd9c9, #fcd9c9 5px, #feede6 5px, #feede6 10px)",
								backgroundSize: "14px 14px",
								backgroundPosition: "-1px",
								padding: "5px",
								borderRadius: "5px",
								fontFamily: "Montserrat, sans",
							}}
						>
							{!zammadTicketTimeUnit ? (
								<CgSpinner size={38} className="animate-spin" />
							) : (
								<div
									className="flex flex-col items-start justify-center w-full h-full"
									style={{
										border: "1px solid #feede6",
										padding: "10px 20px",
										background: "white",
										borderRadius: "2px",
										boxShadow: "0 0 1px rgba(0,0,0,0.06) inset",
									}}
								>
									<Row
										className={`${
											(!zammadUserId || !zammadUserTimeUnit) && "hidden"
										}`}
									>
										<Col
											sm={12}
											style={{
												textAlign: "center",
												color: "grey",
											}}
										>
											<span style={{ fontSize: "14px", color: "#585856" }}>
												<b>{zammadUserName}</b>:{" "}
												<strong
													className={`${
														(!zammadUserTimeUnit.time ||
															zammadUserTimeUnit.time === null ||
															zammadUserTimeUnit.time === "") &&
														"hidden"
													}`}
												>
													{zammadUserTimeUnit.hours
														? zammadUserTimeUnit.hours === 1
															? zammadUserTimeUnit.hours + " hora"
															: zammadUserTimeUnit.hours + " hores"
														: ""}{" "}
													{zammadUserTimeUnit.minutes
														? zammadUserTimeUnit.minutes + " minuts"
														: ""}
												</strong>
												<strong
													className={`${
														zammadUserTimeUnit.time &&
														zammadUserTimeUnit.time !== null &&
														zammadUserTimeUnit.time !== "" &&
														"hidden"
													}`}
												>
													{"0 hores 0 minuts"}
												</strong>
											</span>
										</Col>
									</Row>
								</div>
							)}
						</div>

						{/* MINUTS A FACTURAR */}
						<div className="items-center block w-full pb-3 print:bg-white">
							<label
								className="flex flex-row items-center justify-between w-full uppercase col-start-full font-zammad-sans"
								htmlFor="name"
								style={{
									display: "block",
									textAlign: "start",
									color: "#A0A3A6",
									fontSize: "13px",
									letterSpacing: "0.1em",
									fontWeight: "400",
									lineHeight: "1.45",
									marginBottom: "5px",
								}}
							>
								<span>MINUTS A FACTURAR</span>
								<span
									className="inline-flex items-center justify-center px-2 py-1 ml-2 text-xs font-bold leading-none text-white rounded-full"
									style={{ backgroundColor: "#164E63" }}
								>
									({getCurrentMinutes} min)
								</span>
							</label>

							{gestinetMinutes ? (
								<Select
									id="minutesAlbaraInput"
									className="flex-grow rounded-l-none"
									onChange={(e) => {
										console.log(
											"🚀 ~ file: AlbaraZammadForm.js:1148 ~ eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee:",
											e.value
										);
										getMinutsFacturatsEmptyError &&
											e.value !== 0 &&
											setMinutsFacturatsEmptyError(false);
										e.value === 0 && setMinutsFacturatsEmptyError(true);

										setAlbara({
											...albara,
											minuts_facturats: e.value,
										});

										setCurrentMinutes(e.value);

										// console.log(
										// 	"🚀 ~ file: AlbaraZammadForm.js:907 ~ e.value:",
										// 	e.value
										// );
									}}
									placeholder="Seleccionar..."
									options={gestinetMinutes}
									getOptionLabel={(o) => o.label}
									getOptionValue={(o) => o.value}
									styles={{
										control: (baseStyles, state) => ({
											...baseStyles,
											backgroundColor: state.isDisabled
												? getMinutsFacturatsEmptyError
													? "#FEF2F2"
													: "#F2F2F2"
												: getMinutsFacturatsEmptyError
												? "#FEF2F2"
												: "#F9FAFB",
											// border: state.isFocused
											// 	? getMinutsFacturatsEmptyError
											// 		? "2px solid #F87171"
											// 		: "2px solid #9ca3af"
											// 	: getMinutsFacturatsEmptyError
											// 	? "2px solid #FCA5A5"
											// 	: "2px solid #e6e6e6",
											border: state.isFocused
												? getMinutsFacturatsEmptyError
													? "2px solid #F87171"
													: "2px solid #4cafe1"
												: getMinutsFacturatsEmptyError
												? "2px solid #FCA5A5"
												: "2px solid #e6e6e6",
											borderTopLeftRadius: "3px",
											borderTopRightRadius: "3px",
											borderBottomRightRadius: "3px",
											borderBottomLeftRadius: "3px",
											boxShadow: state.isFocused ? "0 0 0 3px #d6eaf5" : "none",
											"&:hover": {
												border: getMinutsFacturatsEmptyError
													? "2px solid #F87171"
													: "2px solid #4cafe1",
											},
											margin: "0px",
											minHeight: "fit-content",
											fontFamily: "Montserrat, sans-serif",
											letterSpacing: "0.05em",
											fontSize: "14px",
											cursor: state.isDisabled ? "not-allowed" : "pointer",
										}),
										option: (baseStyles, state) => ({
											...baseStyles,
											color: "#333333",
											backgroundColor: state.isFocused && "#d1d5db",
											"&:hover": {
												backgroundColor: "#f3f4f6",
											},
										}),
										valueContainer: (baseStyles, state) => ({
											...baseStyles,
											padding: "7px 12px",
											margin: "0px",
											color: "#585856",
										}),
										dropdownIndicator: (baseStyles, state) => ({
											...baseStyles,
											padding: "2px 8px",
											opacity: "0.39",
											color: state.isDisabled
												? "#D6D6D6"
												: getMinutsFacturatsEmptyError
												? "#FCA5A5"
												: "#a0a3a6",
											"&:hover": {
												color:
													!state.isDisabled &&
													getMinutsFacturatsEmptyError &&
													"#F87171",
											},
										}),
										indicatorSeparator: (baseStyles) => ({
											...baseStyles,
											display: "none",
										}),
										// Fixes the overlapping problem:
										menu: (provided) => ({
											...provided,
											zIndex: 9999,
										}),
									}}
									value={gestinetMinutes.filter(
										(minutes) => minutes.value === albara.minuts_facturats
									)}
								/>
							) : (
								<Select
									components={{ DropdownIndicator }}
									className="flex-grow rounded-l-none"
									placeholder="Seleccionar..."
									styles={{
										control: (baseStyles, state) => ({
											...baseStyles,
											border: "2px solid #d1d5db",
											borderTopLeftRadius: 0,
											borderBottomLeftRadius: 0,
											boxShadow: "none",
											margin: "0px",
											minHeight: "fit-content",
											cursor: "not-allowed",
										}),
										valueContainer: (baseStyles, state) => ({
											...baseStyles,
											padding: "0px 8px",
											margin: "0px",
										}),
										dropdownIndicator: (baseStyles, state) => ({
											...baseStyles,
											padding: "2px 8px",
											color: "#D6D6D6",
										}),
										// Fixes the overlapping problem:
										menu: (provided) => ({
											...provided,
											zIndex: 9999,
										}),
									}}
									isDisabled
								/>
							)}
						</div>
					</Col>
				</Row>
			</Container>
		</Form>
	) : null;
};

export default AlbaraZammadForm;

import {useEffect, useState} from "react";
import Title from "../../components/shared/Title/Title";
import GlobalStats from '../../components/Dashboard/GlobalStats'
import axios from 'axios'
import ClientsStats from "../../components/Dashboard/ClientsStats";
import TecnicStats from "../../components/Dashboard/TecnicStats";
const Dashboard = () => {
  const [globalStats, setGlobalStats] = useState([])
  const [operatorStats, setOperatorStats] = useState([])
  const [clientsStats, setClientsStats] = useState([])
  const [tecnicStats, setTecnicStats] = useState([])
  useEffect(() => {
   getStats()
    getOperatorStats()
    getClientsStats()
    getTecnicStats()
  }, []);
  
  const getStats = async () => {
    const statsApi = await axios.get(
      process.env.REACT_APP_API_URL + "api/tiquets/dashboard",
      {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
       
      }
    );
    
      setGlobalStats(statsApi.data)
  }

  const getOperatorStats = async () => {
    const statsApi = await axios.get(
      process.env.REACT_APP_API_URL + "api/tiquets/dashboard/" + JSON.parse(localStorage.getItem("userLogged")).id,
      {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
       
      }
    );
      setOperatorStats(statsApi.data)
      
  }

  const getClientsStats = async () => {
    const statsApi = await axios.get(
      process.env.REACT_APP_API_URL + "api/tiquets/dashboard/empreses",
      {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
       
      }
    );
      setClientsStats(statsApi.data)
      
  }
  console.log(tecnicStats)
  const getTecnicStats = async () => {
    const statsApi = await axios.get(
      process.env.REACT_APP_API_URL + "api/tiquets/dashboard/tecnics" ,
      {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
       
      }
    );
      setTecnicStats(statsApi.data)
      
  }
  return <>
 <div className='grid bg-bootstrap rounded mt-2 grid-cols-2'>
  <div className='flex flex-col '>
  {operatorStats && <GlobalStats data={operatorStats} title='Els teus tickets' /> }

  {globalStats && <GlobalStats data={globalStats} title='Tickets globals' />} 


  </div>
  {clientsStats && <ClientsStats data={clientsStats}  /> }
 </div>
  <div className='flex text-2xl font-semibold justify-center my-12 mr-4 '>
    <div >
      <span className='px-3 mr-1 bg-green-500'></span>
    <span>Assignats</span>

    </div>
    <div className='mx-16'>
    <span className='px-3 mr-1 bg-yellow-500'></span>

    <span>Oberts</span>

    </div>

    <div>
    <span className='px-3 mr-1 bg-red-500'></span>

    <span>Pendents</span>

    </div>
  </div>
  <div className='grid 2xl:grid-cols-5  grid-cols-4  mx-16 justify-center text-center  gap-10  '>
 
  {tecnicStats && tecnicStats.map(tecnic => <div className='flex justify-center' key={tecnic.idAssignat}><TecnicStats  data={tecnic} /></div> ) }


 </div>
  </>
};

export default Dashboard;

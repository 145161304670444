import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";

import axios from "axios";
import LoginFormZammad from "../../forms/LoginFormZammad";

const LoginZammad = ({ currentUrl }) => {
  const { addToast } = useToasts();

  const [login, setLogin] = useState({ usuari: "", pwd: "" });

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(
        process.env.REACT_APP_API_URL + "api/auth/login/",
        { login },
        {
          auth: {
            username: process.env.REACT_APP_API_USER,
            password: process.env.REACT_APP_API_PWD,
          },
        }
      )
      .then(function (response) {
        // Convertim JSON object a Stringify per guardar i ho guardem a la sessió
        localStorage.setItem("userLogged", JSON.stringify(response.data));
        window.location.href = currentUrl;
      })
      .catch(function (error) {
        console.log(error);
        // TOAST ERROR
        addToast("S'ha produït un error", {
          appearance: "error",
        });
      });
  };

  return (
    <>
      <LoginFormZammad
        login={login}
        setLogin={setLogin}
        handleSubmit={handleSubmit}
      ></LoginFormZammad>
    </>
  );
};

export default LoginZammad;

import React, { useState } from "react";
import Title from "../../components/shared/Title/Title";
import TicketRecurrentForm from "../../forms/TicketRecurrentForm";

import { useToasts } from "react-toast-notifications";

import { useHistory } from "react-router-dom";
import axios from "axios";

const NewTicketRecurrent = () => {
  const { addToast } = useToasts();
  const history = useHistory();

  // Estat amb dades per defecte
  const [ticketRecurrent, setTicketRecurrent] = useState({
    idClient: "0",
    assumpte: "",
    dades: "",
    idServei: "",
    idSubServei: "",
    textAlbara: "",
    idAssignat: "",
    frequencia: [],
    intervalFrequencia: "",
    frequenciaSelected: new Date(),
    dataInici: new Date() / 1000,
    idCreador: JSON.parse(localStorage.getItem("userLogged")).id,
    minuts: "60",
  });

  // Al fer clic al botó de Cancelar
  const handleCancel = () => {
    history.push("/tickets-recurrents");
  };

  // Al fer submit del formulari
  const handleSubmit = () => {
    // Insertem el nou ticket a la base de dades (amb les dades del formulari)
    document.getElementById("btnCreateRecurrentTicket").disabled = true
    axios
      .post(
        process.env.REACT_APP_API_URL + "api/tiquet/recurrent/",
        { ticketRecurrent },
        {
          auth: {
            username: process.env.REACT_APP_API_USER,
            password: process.env.REACT_APP_API_PWD,
          },
          headers: {
            token: JSON.parse(localStorage.getItem("userLogged")).token,
            idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
          },
        }
      )
      .then((res) => {
        // Redireccionem a la pàgina del ticket creat
        history.push(`/tickets-recurrents/`);
        // TOAST OK
        addToast("Tiquet recurrent creat correctament", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((error) => {
        document.getElementById("btnCreateRecurrentTicket").disabled = false
        console.log(error);
        // TOAST ERROR
        addToast("S'ha produït un error", {
          appearance: "error",
        });
      });
  };

  return (
    <>
      <Title
        title="Tickets"
        titleLink="/tickets"
        title2="Tickets recurrents"
        title2Link="/tickets-recurrents"
        title3="Crear"
      />
      <TicketRecurrentForm
        ticketRecurrent={ticketRecurrent}
        setTicketRecurrent={setTicketRecurrent}
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default NewTicketRecurrent;

import React from "react";

import ReactPaginate from "react-paginate";
const resultsPerPage = 25;

const Pagination = ({ totalRows, handlePagination }) => {
  return (
    <ReactPaginate
      previousLabel={"Anterior"}
      nextLabel={"Següent"}
      breakLabel={"..."}
      breakClassName={"break-me"}
      pageCount={totalRows / resultsPerPage}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={handlePagination}
      containerClassName={"pagination"}
      subContainerClassName={"pages pagination"}
      activeClassName={"active"}
    />
  );
};

export default Pagination;

import React, { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import moment from "moment";
import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  Modal,
  Button,
  FormControl,
} from "react-bootstrap";
import Select from "react-select";
import axios from "axios";
import DatePicker from "react-datepicker";
import {AiOutlineDelete, AiOutlineSave} from 'react-icons/ai'
import {FiFilter} from 'react-icons/fi'
const FiltersBar = ({
  loadTickets,
  setSearchTerm,
  filter,
  setFilter,
  empreses,
  estats,
  serveis,
  tecnics,
  involucrats,
}) => {
  const { addToast } = useToasts();

  // Ensenyar i amagar filtres
  const [mostrarFiltres, setMostrarFiltres] = useState();

  // Estat amb dades desplegables
  const [filtres, setFiltres] = useState();
  const [informadors, setInformadors] = useState();
  const [subServeis, setSubServeis] = useState();
  const [prioritats, setPrioritats] = useState();
  const [grups, setGrups] = useState();

  // Al carregar la screen
  useEffect(() => {
    // INFORMADORS
    const apiInformadors = axios.get(
      process.env.REACT_APP_API_URL + "api/informadors/",
      {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      }
    );

    // PRIORITATS
    const apiPrioritats = axios.get(
      process.env.REACT_APP_API_URL + "api/prioritats/",
      {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      }
    );

    // PETICIÓ API SERVEIS

    // PETICIO API SUBSERVEIS
    const apiSubServeis = axios.get(
      `${process.env.REACT_APP_API_URL}api/serveis/subserveis/`,
      {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      }
    );

    // ESTATS

    // PETICIÓ API TECNICS

    // PETICIÓ API GRUPS
    const apiGrups = axios.get(process.env.REACT_APP_API_URL + "api/grups/", {
      auth: {
        username: process.env.REACT_APP_API_USER,
        password: process.env.REACT_APP_API_PWD,
      },
      headers: {
        token: JSON.parse(localStorage.getItem("userLogged")).token,
        idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
      },
    });

    // PETICIÓ API FILTRES
    const apiFiltres = axios.get(
      process.env.REACT_APP_API_URL + "api/filtres/",
      {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      }
    );

    axios
      .all([apiInformadors, apiPrioritats, apiSubServeis, apiGrups, apiFiltres])
      .then(
        axios.spread((...responses) => {
          const responseInformadors = responses[0].data;
          const responsePrioritats = responses[1].data;
          const responseSubServeis = responses[2].data;
          const responseGrups = responses[3].data;
          const responseFiltres = responses[4].data;

          /* EMPRESES SELECT OPTIONS */

          /* INFORMADORS SELECT OPTIONS */
          const informadorsList = [];
          responseInformadors.forEach((informador) => {
            informadorsList.push({
              value: informador.id,
              label: informador.nom,
            });
          });
          setInformadors(informadorsList);

          /* PRIORITATS SELECT OPTIONS */
          const prioritatsList = [];
          responsePrioritats.forEach((prioritat) => {
            prioritatsList.push({
              value: prioritat.id,
              label: prioritat.nom,
            });
          });
          setPrioritats(prioritatsList);

          /* SUBSERVEIS SELECT OPTIONS */
          const subServeisList = [];
          responseSubServeis.forEach((subServei) => {
            subServeisList.push({
              value: subServei.id,
              label: `${subServei.nom} (${subServei.nomServei})`,
            });
          });
          setSubServeis(subServeisList);

          /* GRUPS SELECT OPTIONS */
          const grupsList = [];
          responseGrups.forEach((grup) => {
            grupsList.push({
              value: grup.id,
              label: grup.nom,
            });
          });
          setGrups(grupsList);

          /* FILTRES SELECT OPTIONS */
          if (responseFiltres) {
            const filtresPrivats = [];
            const filtresPublics = [];
            responseFiltres.forEach((filtre) => {
              // Diferenciar si es public o privat
              if (filtre.isPublic === "1") {
                filtresPublics.push({
                  value: filtre.id,
                  label: filtre.nom,
                  filtre: filtre.filtre,
                  isPublic: filtre.isPublic,
                });
              } else {
                filtresPrivats.push({
                  value: filtre.id,
                  label: filtre.nom,
                  filtre: filtre.filtre,
                  isPublic: filtre.isPublic,
                });
              }
            });
            // Guardar values per el select de filtres diferenciant privats i publics
            setFiltres([
              { label: "Filtres privats", options: filtresPrivats },
              { label: "Filtres públics", options: filtresPublics },
            ]);
          }
        })
      )
      .catch((errors) => {
        // TOAST ERROR
        addToast("S'ha produït un error", {
          appearance: "error",
        });
      });

    // Carreguem el filtre de l'usuari
    if (JSON.parse(localStorage.getItem("userLogged")).filtre) {
      setFilter(
        JSON.parse(JSON.parse(localStorage.getItem("userLogged")).filtre)
      );
    }

    // eslint-disable-next-line
  }, []);

  // MODAL SAVE FILTERS
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [filterName, setFilterName] = useState(false);

  // Filtre dates init
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);

  // FUNCTION SAVE FILTERS
  const handleSaveFilter = () => {
    // Insertem la nova resposta a la base de dades (amb les dades del formulari al state)
    axios
      .post(
        process.env.REACT_APP_API_URL + "api/filtre/",
        { nom: filterName, filtre: JSON.stringify(filter) },
        {
          auth: {
            username: process.env.REACT_APP_API_USER,
            password: process.env.REACT_APP_API_PWD,
          },
          headers: {
            token: JSON.parse(localStorage.getItem("userLogged")).token,
            idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
          },
        }
      )
      .then((res) => {
        setFiltres([
          ...filtres,
          {
            value: `${res.data}`,
            label: filterName,
            filtre: filter,
          },
        ]);

        handleClose();
        // TOAST OK
        addToast("Filtres guardats correctament", {
          appearance: "success",
          autoDismiss: true,
        });
        window.location.reload(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // FUNCTION DELETE FILTER

  const handleDeleteFilter = () => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}api/filtre/${
          JSON.parse(localStorage.getItem("userLogged")).idFiltre
        }/`,
        {
          auth: {
            username: process.env.REACT_APP_API_USER,
            password: process.env.REACT_APP_API_PWD,
          },
          headers: {
            token: JSON.parse(localStorage.getItem("userLogged")).token,
            idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
          },
        }
      )
      .then((res) => {
        handleCloseDelete();
        addToast("Filtre eliminat correctament", {
          appearance: "success",
          autoDismiss: true,
        });
        // CLEAR LOCALSTORAGE WITH NO FILTER
        const userLogged = JSON.parse(localStorage.getItem("userLogged"));

        userLogged.idFiltre = 1;
        userLogged.filtre =
          '{"idEstat":null,"idAssignat":[{"value":"null","label":"Sense assignar"}]}';
        userLogged.nomFiltre = "Sense assignar";
        // Guardem els nous filtres seleccionats al state
        localStorage.setItem("userLogged", JSON.stringify(userLogged));

        window.location.reload(false);
      });
  };

  // console.log(filtres);
  const handleFilterSelected = (filter) => {
    // SETEJAR FILTRE
    setFilter(filter.filtre);

    // GUARDAR FILTRE SELECCIONAT COM A PREDEFINIT DEL USUARI a la base de dades
    axios
      .put(
        `${process.env.REACT_APP_API_URL}api/tecnic/filtre/${
          JSON.parse(localStorage.getItem("userLogged")).id
        }/`,
        { filtre: filter.value },
        {
          auth: {
            username: process.env.REACT_APP_API_USER,
            password: process.env.REACT_APP_API_PWD,
          },
          headers: {
            token: JSON.parse(localStorage.getItem("userLogged")).token,
            idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
          },
        }
      )
      .then((res) => {
        // UPDATE LOCALSTORAGE WITH NEW FILTERS
        const userLogged = JSON.parse(localStorage.getItem("userLogged"));

        userLogged.idFiltre = filter.value;
        userLogged.filtre = JSON.stringify(filter.filtre);

        userLogged.nomFiltre = filter.label;
        userLogged.isPublicFiltre = filter.isPublic;
        // Guardem els nous filtres seleccionats al state
        localStorage.setItem("userLogged", JSON.stringify(userLogged));
        loadTickets({ reloadCurrentPage: true });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Aplicar el filtre sempre que es modifiqui el filtre state
  /*  useEffect(() => {
 
    if (filter) {
      loadTickets({ reloadCurrentPage: true }); //True = Reload currentPage
    }
   
  }, [filter]); */

  return (
    <Container className='mt-3'>
      <div className="form-group lg:hidden absolute top-1 right-0 w-full ml-12 z-40 d-inline-block">
        <InputGroup>
          <FormControl
            className="rounded-sm lg:hidden  h-14 bg-transparent mx-2 border-gray-300 border text-sm shadow-md pl-12"
            id="inlineFormInputGroupUsername2"
            placeholder="Buscar ticket"
            onChange={(e) => {
              e.target.value !== "" && setSearchTerm(e.target.value);
              /* e.target.value !== ""
                    ? setSearchTerm(e.target.value)
                    : loadTickets({ reloadCurrentPage: true }); */
            }}
          />
        </InputGroup>
        <button  className='absolute lg:hidden top-1 right-12  p-3' onClick={() => setMostrarFiltres(!mostrarFiltres)}>

        <FiFilter size={20}    />
        </button>
        
      </div>
      <Row className="hidden lg:block">
        <div  className=" flex ">
          {/* FILTER SELECT FILTERS */}
          <div className="form-group flex ">
            <Select
              styles={{
                // Fixes the overlapping problem of the component
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
              className="select w-96 "
              options={filtres}
              placeholder="Seleccionar filtre"
              defaultValue={
                JSON.parse(localStorage.getItem("userLogged")).idFiltre
                  ? {
                      label: JSON.parse(localStorage.getItem("userLogged"))
                        .nomFiltre,
                      value: JSON.parse(localStorage.getItem("userLogged"))
                        .idFiltre,
                      filtre: JSON.parse(localStorage.getItem("userLogged"))
                        .filtre,
                      isPublic: JSON.parse(localStorage.getItem("userLogged"))
                        .isPublicFiltre,
                    }
                  : ""
              }
              onChange={(filtre) => {
                
                handleFilterSelected(filtre);
              }}
            />
             <button
              className={` hover:bg-gray-200 rounded-lg ml-2 p-2`}
              onClick={handleShow}
            > <AiOutlineSave size={25}/> </button>
            {JSON.parse(localStorage.getItem("userLogged")).isPublicFiltre ===
            "0" ? (
              <button
                className={` hover:bg-gray-200 rounded-lg p-2 mr-2`}
                onClick={handleShowDelete}
              ><AiOutlineDelete size={25}/></button>
            ) : (
              ""
            )}
          {/* <Button
                className={` hover:bg-white mb-1 hover:text-blue-900 transition duration-150  w-40 bg-blue-900 border-t border-b border-l border-r border-blue-900 text-white `}
                onClick={() => setFilter({idEstat: 'null', idAssignat: [{value: 'null', label: 'Sense assignar'}]})}
             
              >Veure no assignats</Button> */}
          
          </div>
          {/* MODAL - FILTER SELECT FILTERS */}
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Guardar filtres</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Nom dels filtres</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Introduir nom dels filtres"
                  onChange={(filtre) => {
                    setFilterName(filtre.target.value);
                  }}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="grey" onClick={handleClose}>
                Tancar
              </Button>
              <Button variant="primary" onClick={handleSaveFilter}>
                Guardar filtres
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={showDelete} onHide={handleCloseDelete}>
            <Modal.Header closeButton>
              <Modal.Title>Eliminar filtre</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>
                  Estàs segur d'eliminar el filtre seleccionat?
                </Form.Label>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="grey" onClick={handleCloseDelete}>
                Tancar
              </Button>
              <Button variant="secondary" onClick={handleDeleteFilter}>
                Eliminar filtre
              </Button>
            </Modal.Footer>
          </Modal>
           {/* SEARCH BAR */}
           <div className="form-group d-inline-block ml-12 w-full">
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text >
                  <i className={`fa fa-search`} style={{ width: "25px" }}></i>
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                id="inlineFormInputGroupUsername2"
                placeholder="Buscar ticket"
                className='rounded-sm'
                onChange={(e) => {
                  e.target.value !== "" && setSearchTerm(e.target.value);
                  /* e.target.value !== ""
                    ? setSearchTerm(e.target.value)
                    : loadTickets({ reloadCurrentPage: true }); */
                }}
              />
            </InputGroup>
          </div>
          <div
            className="form-group d-inline-block  "
            style={{ width: "3%", marginLeft: "2%" }}
          >
            <i
              className={` fa fa-filter `}
              style={{ fontSize: "25px", cursor: "pointer", marginTop: "5px" }}
              onClick={() => setMostrarFiltres(!mostrarFiltres)}
            ></i>
          </div>
        </div>
      </Row>
      <div className={`${mostrarFiltres ? "d-block" : "d-none"} xs:mx-1 mt-4` }>
        <Row>
        <div className={`form-group  xs:flex hidden`}>
            <Select
              styles={{
                // Fixes the overlapping problem of the component
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
              className="select w-72 ml-2 "
              options={filtres}
              placeholder="Seleccionar filtre"
              defaultValue={
                JSON.parse(localStorage.getItem("userLogged")).idFiltre
                  ? {
                      label: JSON.parse(localStorage.getItem("userLogged"))
                        .nomFiltre,
                      value: JSON.parse(localStorage.getItem("userLogged"))
                        .idFiltre,
                      filtre: JSON.parse(localStorage.getItem("userLogged"))
                        .filtre,
                      isPublic: JSON.parse(localStorage.getItem("userLogged"))
                        .isPublicFiltre,
                    }
                  : ""
              }
              onChange={(filtre) => {
                
                handleFilterSelected(filtre);
              }}
            />
             <button
              className={` hover:bg-gray-200 rounded-lg ml-2 p-2`}
              onClick={handleShow}
            > <AiOutlineSave size={25}/> </button>
            {JSON.parse(localStorage.getItem("userLogged")).isPublicFiltre ===
            "0" ? (
              <button
                className={` hover:bg-gray-200 rounded-lg p-2 mr-2`}
                onClick={handleShowDelete}
              ><AiOutlineDelete size={25}/></button>
            ) : (
              ""
            )}
          {/* <Button
                className={` hover:bg-white mb-1 hover:text-blue-900 transition duration-150  w-40 bg-blue-900 border-t border-b border-l border-r border-blue-900 text-white `}
                onClick={() => setFilter({idEstat: 'null', idAssignat: [{value: 'null', label: 'Sense assignar'}]})}
             
              >Veure no assignats</Button> */}
          
          </div>
          <Col sm={4} className='pl-0'>
            <Form.Group controlId="exampleForm.ControlSelect1">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">
                    Client
                  </InputGroup.Text>
                </InputGroup.Prepend>

                {empreses ? (
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    className="select"
                    options={empreses}
                    value={filter ? filter.idClient : ""}
                    placeholder="Seleccionar client"
                    isMulti
                    onChange={(idClient) => {
                      setFilter({
                        ...filter,
                        idClient,
                      });
                    }}
                  />
                ) : (
                  ""
                )}
              </InputGroup>
            </Form.Group>
          </Col>
          <Col sm={4}  className='pl-0'>
            <Form.Group controlId="exampleForm.ControlSelect1">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">
                    Informador
                  </InputGroup.Text>
                </InputGroup.Prepend>
                {informadors ? (
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    className="select"
                    options={informadors}
                    isMulti
                    value={filter ? filter.idInformador : ""}
                    placeholder="Seleccionar informadors"
                    onChange={(idInformador) => {
                      setFilter({
                        ...filter,
                        idInformador,
                      });
                    }}
                  />
                ) : (
                  ""
                )}
              </InputGroup>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="exampleForm.ControlSelect1">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">
                    Prioritat
                  </InputGroup.Text>
                </InputGroup.Prepend>
                {prioritats ? (
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    className="select"
                    options={prioritats}
                    isMulti
                    value={filter ? filter.idPrioritat : ""}
                    placeholder="Seleccionar prioritats"
                    onChange={(idPrioritat) => {
                      setFilter({
                        ...filter,
                        idPrioritat,
                      });
                    }}
                  />
                ) : (
                  ""
                )}
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={4}  className='pl-0'>
            <Form.Group controlId="exampleForm.ControlSelect1">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">
                    Servei
                  </InputGroup.Text>
                </InputGroup.Prepend>
                {serveis ? (
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    className="select"
                    options={serveis}
                    isMulti
                    value={filter ? filter.idServei : ""}
                    placeholder="Seleccionar serveis"
                    onChange={(idServei) => {
                      setFilter({
                        ...filter,
                        idServei,
                      });
                    }}
                  />
                ) : (
                  ""
                )}
              </InputGroup>
            </Form.Group>
          </Col>
          <Col sm={4} className='pl-0'>
            <Form.Group controlId="exampleForm.ControlSelect1">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">
                    Sub servei
                  </InputGroup.Text>
                </InputGroup.Prepend>
                {subServeis ? (
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    className="select"
                    options={subServeis}
                    isMulti
                    value={filter ? filter.idSubServei : ""}
                    placeholder="Seleccionar sub servei"
                    onChange={(idSubServei) => {
                      setFilter({
                        ...filter,
                        idSubServei,
                      });
                    }}
                  />
                ) : (
                  ""
                )}
              </InputGroup>
            </Form.Group>
          </Col>
          <Col sm={4} className='pl-0' >
            <Form.Group controlId="exampleForm.ControlSelect1">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">Grup</InputGroup.Text>
                </InputGroup.Prepend>
                {grups ? (
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    className="select"
                    options={grups}
                    isMulti
                    value={filter ? filter.idGrup : ""}
                    placeholder="Seleccionar grups"
                    onChange={(idGrup) => {
                      setFilter({
                        ...filter,
                        idGrup,
                      });
                    }}
                  />
                ) : (
                  ""
                )}
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={4} className='pl-0'> 
            <Form.Group controlId="exampleForm.ControlSelect1">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">
                    Assignat
                  </InputGroup.Text>
                </InputGroup.Prepend>
                {tecnics ? (
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    className="select"
                    options={tecnics}
                    isMulti
                    value={filter ? filter.idAssignat : ""}
                    placeholder="Seleccionar tècnics"
                    onChange={(idAssignat) => {
                      console.log(idAssignat)
                      setFilter({
                        ...filter,
                        idAssignat,
                      });
                    }}
                  />
                ) : (
                  ""
                )}
              </InputGroup>
            </Form.Group>
          </Col>
          <Col sm={4}className='pl-0' >
            <Form.Group controlId="exampleForm.ControlSelect1">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">
                    Involucrats
                  </InputGroup.Text>
                </InputGroup.Prepend>
                {involucrats ? (
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    className="select"
                    options={involucrats}
                    isMulti
                    value={filter ? filter.involucrats : ""}
                    placeholder="Seleccionar involucrats"
                    onChange={(involucrats) => {
                      setFilter({
                        ...filter,
                        involucrats,
                      });
                    }}
                  />
                ) : (
                  ""
                )}
              </InputGroup>
            </Form.Group>
          </Col>

          <Col sm={4} className='pl-0' >
            <Form.Group controlId="exampleForm.ControlSelect1">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">
                    Responsable
                  </InputGroup.Text>
                </InputGroup.Prepend>
                {tecnics ? (
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    className="select"
                    options={tecnics}
                    value={filter ? filter.responsable : ""}
                    isMulti
                    placeholder="Seleccionar responsable"
                    onChange={(responsable) => {
                      setFilter({
                        ...filter,
                        responsable,
                      });
                    }}
                  />
                ) : (
                  ""
                )}
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={4} className='pl-0' >
            <Form.Group controlId="exampleForm.ControlSelect1">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">
                    Estat
                  </InputGroup.Text>
                </InputGroup.Prepend>
                {estats ? (
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    className="select"
                    options={estats}
                    isMulti
                    value={filter ? filter.idEstat : ""}
                    placeholder="Seleccionar estats"
                    onChange={(idEstat) => {
                      setFilter({
                        ...filter,
                        idEstat,
                      });
                    }}
                  />
                ) : (
                  ""
                )}
              </InputGroup>
            </Form.Group>
          </Col>

          <Col sm={4} className='pl-0'> 
            <Form.Group controlId="exampleForm.ControlSelect1">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">
                    Caducitat
                  </InputGroup.Text>
                </InputGroup.Prepend>

                <Form.Control
                  as="select"
                  onChange={(caducitat) =>
                    setFilter({
                      ...filter,
                      caducitat: caducitat.target.value,
                    })
                  }
                >
                  <option value="0">No caducat</option>
                  <option value="1">1 dia</option>
                  <option value="3">3 dies</option>
                  <option value="5">5 dies</option>
                  <option value="15">15 dies</option>
                  <option value="30">30 dies</option>
                  <option value="60">60 dies</option>
                  <option value="90">+90 dies</option>
                </Form.Control>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col sm={4} className='pl-0'> 
            <Form.Group controlId="exampleForm.ControlSelect1">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">
                    Data ticket
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <DatePicker
                  selected={startDate}
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  onChange={(startDateFilter) => {
                    setStartDate(startDateFilter);
                    setFilter({
                      ...filter,
                      data: [
                        startDateFilter
                          ? moment(startDateFilter).format("X")
                          : null,
                        endDate ? moment(endDate).format("X") : null,
                      ],
                    });
                  }}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                />
                <DatePicker
                  selected={endDate}
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  onChange={(endDateFilter) => {
                    setEndDate(endDateFilter);
                    setFilter({
                      ...filter,
                      data: [
                        startDate ? moment(startDate).format("X") : null,
                        endDateFilter
                          ? moment(endDateFilter).format("X")
                          : null,
                      ],
                    });
                  }}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                />
              </InputGroup>
            </Form.Group>
          </Col>
          {/*
          <Col sm={4}>className='pl-0' 
            <Form.Group controlId="exampleForm.ControlSelect1">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">
                    Sense resposta
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Group controlId="formBasicCheckbox" className="ml-2">
                  <Form.Check
                    type="checkbox"
                    onChange={(resposta) =>
                      setFilter({
                        ...filter,
                        resposta: resposta.target.checked,
                      })
                    }
                  />
                </Form.Group>
              </InputGroup>
            </Form.Group>
                  </Col> */}
        </Row>
      </div>
    </Container>
  );
};

export default FiltersBar;

import React, { useEffect, useState } from "react";
import Title from "../../components/shared/Title/Title";
import { Row } from "react-bootstrap";
import LogItem from "../../components/Tickets/TicketLog/LogItem/LogItem";

import axios from "axios";

const TicketLog = ({ id }) => {
  const [logs, setLogs] = useState();
  useEffect(() => {
    // Obté el ticket X
    axios
      .get(`${process.env.REACT_APP_API_URL}api/logs/tiquets/${id}/`, {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      })
      .then((res) => {
        // console.log(res);
      
        const logs = res.data;

        // Guardem les dades del log al state
        setLogs(logs);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  return (
    <>
      {logs ? (
        <>
          <Title
            title="Tickets"
            title2={`#${id}`}
            title3="Logs"
            titleLink="/tickets"
            title2Link={`/ticket/${id}`}
          />
          <Row>
            {logs.map((log,i) => {
              return (
                <LogItem
                key={i}  
                
                  nomOperador={log.nomOperador}
                  fotoOperador={log.fotoOperador}
                  missatge={log.missatge}
                  data={log.data}
                  missatgesTractats={log.missatgesTractats}
                />
              );
            })}
          </Row>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default TicketLog;

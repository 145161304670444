import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";

import axios from "axios";
import ListItemZammad from "./ListItem/ListItemZammad";

export default function MaterialListZammad({
  material,
  materialEntregat,
  setMaterialEntregat,
  albara,
}) {
  const [genericList, setGenericList] = useState();
  const [imputatList, setImputatList] = useState();
  const [entregatList, setEntregatList] = useState();
  
  useEffect(() => {
    // Obté els materials
    let peticioApi = "";

    if (material === "generic") {
      // Peticio api material generic
      peticioApi = `${process.env.REACT_APP_API_URL}api/hardwares/generics/`;
    } else if (material === "imputat") {
      // Peticio api material imputat del client del albarà
      peticioApi = `${process.env.REACT_APP_API_URL}api/hardwares/pendents/${albara}/`;
    }
    if (material !== "entregat") {
      axios
        .get(peticioApi, {
          auth: {
            username: process.env.REACT_APP_API_USER,
            password: process.env.REACT_APP_API_PWD,
          },
          headers: {
            token: JSON.parse(localStorage.getItem("userLogged")).token,
            idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
          },
        })
        .then((res) => {
          const hardwares = res.data;
          if (material === "generic") {
            // Renderitzem el hardware generic
            renderGeneric(hardwares);
          } else if (material === "imputat") {
            // Renderitzem el hardware imputat
            renderImputat(hardwares);
          }
        });
    } else {
      renderEntregat();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    renderEntregat();
    // eslint-disable-next-line
  }, [materialEntregat]);

  // Funció que recorre els materials IMPUTATS A AQUELL CLIENT i els crea les files
  const renderImputat = (hardwares) => {
    // console.log(hardwares);
    const imputatList = [];
    if (hardwares) {
      hardwares.forEach((hardware) => {
        imputatList.push(
          <ListItemZammad
            key={hardware.id}
            hardware={hardware}
            hardwares={hardwares}
            material={material}
            handleEntregarMaterial={handleEntregarMaterial}
            renderImputat={renderImputat}
          />
        );
      });
      setImputatList(imputatList);
    } else {
      setImputatList(
        <tr>
          <td colSpan="8">No s'ha trobat cap material imputat</td>
        </tr>
      );
    }
  };

  // Funció que recorre els materials GENERICS i els crea les files
  const renderGeneric = (hardwares) => {
    // console.log(hardwares);
    const genericList = [];
    if (hardwares) {
      hardwares.forEach((hardware) => {
        genericList.push(
          <ListItemZammad
            key={hardware.id}
            hardware={hardware}
            hardwares={hardwares}
            material={material}
            handleEntregarMaterial={handleEntregarMaterial}
            renderGeneric={renderGeneric}
          />
        );
      });
      setGenericList(genericList);
    } else {
      setGenericList(
        <tr>
          <td colSpan="8">No s'ha trobat cap material genèric</td>
        </tr>
      );
    }
  };

  // Funció que renderitza el material ENTREGAT (guardat al state)
  const renderEntregat = () => {
    const entregatList = [];
    if (materialEntregat.length > 0) {
      materialEntregat.forEach((entregat) => {
        entregatList.push(
          <ListItemZammad
            key={material.id}
            hardware={entregat}
            material={material}
            handleQuantitatChange={handleQuantitatChange}
          />
        );
        setEntregatList(entregatList);
      });
    } else {
      setEntregatList(
        <tr>
          <td colSpan="8">No s'ha trobat cap material entregat</td>
        </tr>
      );
    }
  };

  // Funció del esdeveniment de handleClick de entrega o prestec
  const handleEntregarMaterial = (producte, prestec, generic) => {
    console.log(prestec);
    setMaterialEntregat((materialEntregat) => [
      ...materialEntregat,
      {
        id: `${producte.id}`,
        producte: producte.producte,
        qty: producte.qty,
        prestec,
        generic,
      },
    ]);
  };

  // Al canviar quantitat dels inputs de MATERIAL ENTREGAT
  const handleQuantitatChange = (e, hardware) => {
    const quantitat = e.target.value;

    // Obntenim la posicio de larray dins de el state
    const index = materialEntregat.findIndex((mat) => mat.id === hardware.id);

    // Assignem la quantitat a Material Entregat
    materialEntregat[index].qty = quantitat;

    // Guardem canvis al state
    setMaterialEntregat(materialEntregat);
  };

  return (
    <>
      <Table striped responsive="sm">
        <thead>
          <tr>
            <th>Producte</th>
            {material === "entregat" || material === "imputat" ? (
              <th>Quantitat</th>
            ) : (
              ""
            )}
            {material !== "entregat" ? <th>Entrega</th> : ""}
            <th>Préstec</th>
          </tr>
        </thead>
        <tbody>
          {material === "generic" ? (
            genericList
          ) : material === "imputat" ? (
            imputatList
          ) : material === "entregat" ? (
            entregatList
          ) : (
            <tr>
              <td>Carregant material...</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};

import PropTypes from "prop-types";

import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
// import { Link } from 'wouter'

const Stats = ({ data, title }) => {

  return (
      <div className='bg-bootstrap  border-b border-white rounded py-4 '>
      <p className='text-center font-bold text-white text-xl' >{title}</p>
    <div className='flex mt-4 '>
      {Object.entries(data).map(([key,value], i) => (
        <div
          key={i}
          className="col-span-1 text-white my-auto flex 2xl:w-56 sm:w-56 lg:w-40  mx-auto relative font-ms-semi rounded-lg   h-36  "
        >
          {/* <img
        src={index === 0 ? gas : index === 1 ? washing : car}
        className="w-12 h-12 absolute top-6 lg:left-4 sm:left-0 left-12 pl-3  pt-3  "
        alt="icon"
      /> */}
          <div className="flex flex-col justify-center   items-center h-auto w-full">
            <h3 className=" capitalize sm:text-sm text-base md:text-base  ">
              {key}
            </h3>
            
              <p className="2xl:text-6xl text-4xl font-ms-bold  ">
             {value}
              </p>

              {/* <span className="text-green-400 text-lg font-ms-bold flex items-center">
                {" "}
                <AiOutlineArrowUp />
                joan
              </span> */}
         
            {/* <Link href={link}>
          <a className="bg-gray-100 rounded-b-lg  py-2 mt-auto lg:text-left lg:pl-7 text-center  w-full ">
            Ver más
          </a>
        </Link> */}
          </div>
        </div>
      ))}
    </div>
    </div>
  );
};

export default Stats;

Stats.propTypes = {
  title: PropTypes.string.isRequired,
  number: PropTypes.number,
  change: PropTypes.number,
  link: PropTypes.string,
  index: PropTypes.number,
};

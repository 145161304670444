/* eslint-disable */
import React from "react";
import styles from "../Sidebar.module.css";

import BlockTitle from "./BlockTitle/BlockTitle";
import BlockItem from "./BlockItem/BlockItem";

const Block = ({ title, items }) => {
  let itemsList = [];
  const renderItems = () => {
    items.forEach(function (item) {
      itemsList.push(<BlockItem item={item} key={item.id} />);
    });
    return itemsList;
  };

  return (
    <>
      {/*<BlockTitle title={title} className={styles.sidebarTitle} />*/}
      <ul style={{ marginTop: "5px" }}>{renderItems()}</ul>
    </>
  );
};

export default Block;

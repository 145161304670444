import React, { useState, useEffect } from "react";
import Title from "../../components/shared/Title/Title";
import TicketForm from "../../forms/TicketForm";

import { useToasts } from "react-toast-notifications";

import { useHistory } from "react-router-dom";
import axios from "axios";

const DuplicateTicket = ({ id }) => {
  const { addToast } = useToasts();
  const history = useHistory();

  const [ticket, setTicket] = useState();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}api/tiquet/${id}/`, {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      })
      .then((res) => {
        const ticketInfo = res.data;

        // TODO: Eliminar camps del ticket a modificar (TEMPORAL, FER AL BACKEND)
        delete ticketInfo.id;

        ticketInfo.idEstat = "1";
        setTicket(ticketInfo);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line
  }, []);

  // Al fer clic al botó de Cancelar
  const handleCancel = () => {
    history.push("/tickets");
  };

  // Al fer submit del formulari
  const handleSubmit = () => {
    // Insertem el nou ticket a la base de dades (amb les dades del formulari)
    axios
      .post(
        process.env.REACT_APP_API_URL + "api/tiquet/",
        { ticket },
        {
          auth: {
            username: process.env.REACT_APP_API_USER,
            password: process.env.REACT_APP_API_PWD,
          },
          headers: {
            token: JSON.parse(localStorage.getItem("userLogged")).token,
            idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
          },
        }
      )
      .then((res) => {
        // Redireccionem a la pàgina del ticket creat
        history.push(`/ticket/${res.data}/`);
        // TOAST OK
        addToast("Tiquet duplicat correctament", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((error) => {
        console.log(error);
        // TOAST ERROR
        addToast("S'ha produït un error", {
          appearance: "error",
        });
      });
  };
  return (
    <>
      <Title title="Tickets" title2="Duplicar" titleLink="/tickets" />
      {ticket ? (
        <TicketForm
          ticket={ticket}
          setTicket={setTicket}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default DuplicateTicket;

import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import ListItem from "./ListItem/ListItem";
import Table from "react-bootstrap/Table";
import FiltersBar from "./FiltersBar/FiltersBar";
import Pagination from "../../shared/Pagination/Pagination";
import {IoPencilOutline} from 'react-icons/io5'
import { useHistory,Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import axios from "axios";

export default function TicketList() {
  const { addToast } = useToasts();
  const history = useHistory();

  /* MODAL per a eliminar filtres */
  const [showEliminar, setShowEliminar] = useState(false);
  const handleCloseEliminar = () => setShowEliminar(false);
  const handleShowEliminar = () => setShowEliminar(true);

  /* MODAL per a combinar tiquets */
  const [showCombinar, setShowCombinar] = useState(false);
  const handleCloseCombinar = () => {
    setShowCombinar(false);
    setCombinarPrincipal(null);
  };
  const handleShowCombinar = () => {
    setShowCombinar(true);
    setCombinarPrincipal(null);
  };

  const [combinarPrincipal, setCombinarPrincipal] = useState();

  const [tickets, setTickets] = useState();
  const [itemList, setItemList] = useState();
  const [ticketsSeleccionats, setTicketsSeleccionats] = useState([]);
  const [ticketsEliminar, setTicketsEliminar] = useState([]);

  // Pagination
  const [totalTickets, setTotalTickets] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  // Filters
  const [filter, setFilter] = useState();

  // Estat amb dades desplegables
  const [empreses, setEmpreses] = useState();
  // eslint-disable-next-line
  const [serveis, setServeis] = useState();
  // eslint-disable-next-line
  const [estats, setEstats] = useState();
  const [tecnics, setTecnics] = useState();

  const [searchTerm, setSearchTerm] = useState();

  //* Scroll listener
  let listener = null
  const [scrollState, setScrollState] = useState("top")

  useEffect(() => {
    listener = document.addEventListener("scroll", e => {
      const scrolled = document.scrollingElement.scrollTop
      if (scrolled >= 60) {
        if (scrollState !== "amir") {
          setScrollState("amir")
        }
      } else {
        if (scrollState !== "top") {
          setScrollState("top")
        }
      }
    })
    return () => {
      document.removeEventListener("scroll", listener)
    }
  }, [scrollState])

  // Al carregar la screen
  useEffect(() => {
    // Carreguem les dades dels desplegables
    // PETICIÓ API EMPRESES
    const apiEmpreses = axios.get(
      process.env.REACT_APP_API_URL + "api/empreses/",
      {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      }
    );

    // PETICIÓ API SERVEIS
    const apiServeis = axios.get(
      process.env.REACT_APP_API_URL + "api/serveis/",
      {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      }
    );

    // ESTATS
    const apiEstats = axios.get(process.env.REACT_APP_API_URL + "api/estats/", {
      auth: {
        username: process.env.REACT_APP_API_USER,
        password: process.env.REACT_APP_API_PWD,
      },
      headers: {
        token: JSON.parse(localStorage.getItem("userLogged")).token,
        idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
      },
    });

    // PETICIÓ API TECNICS
    const apiTecnics = axios.get(
      process.env.REACT_APP_API_URL + "api/tecnics/",
      {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      }
    );
    axios
      .all([apiEmpreses, apiServeis, apiEstats, apiTecnics])
      .then(
        axios.spread((...responses) => {
          const responseEmpreses = responses[0].data;
          const responseServeis = responses[1].data;
          const responseEstats = responses[2].data;
          const responseTecnics = responses[3].data;

          /* EMPRESES SELECT OPTIONS */
          const empresesList = [];
          responseEmpreses.forEach((empresa) => {
            empresesList.push({
              value: empresa.id,
              label: empresa.nom,
            });
          });
          setEmpreses(empresesList);

          /* SERVEIS SELECT OPTIONS */
          // console.log(responseServeis);
          const serveisList = [];

          responseServeis.forEach((servei) => {
            serveisList.push({
              value: servei.id,
              label: servei.nom,
              subServeis: servei.subserveis,
              color: servei.color,
            });
          });
          setServeis(serveisList);

          /* ESTATS SELECT OPTIONS */
          const estatsList = [];
          responseEstats.forEach((estat) => {
            estatsList.push({
              value: estat.id,
              label: estat.nom,
            });
          });
          setEstats(estatsList);

          /* TECNICS SELECT OPTIONS */
          const tecnicsList = [{ value: null, label: "Sense assignar" }];
          responseTecnics.forEach((tecnic) => {
            tecnicsList.push({
              value: tecnic.id,
              label: tecnic.nom,
              foto: tecnic.foto,
            });
          });
          setTecnics(tecnicsList);
        })
      )
      .catch((errors) => {
        // TOAST ERROR
        addToast("S'ha produït un error", {
          appearance: "error",
        });
      });

    // eslint-disable-next-line
  }, []);


  // Si canvia la pàgina seleccionant el paginador, carregar tiquets d'aquella pàgina i amb aquells filtres
  useEffect(() => {

    if (filter && currentPage && estats) {
      loadTickets({ reloadCurrentPage: false, reload: true });
    }
    // eslint-disable-next-line
  }, [filter, currentPage, estats]);

  // Si canvia la pàgina seleccionant el paginador, carregar tiquets d'aquella pàgina i amb aquells filtres
  useEffect(() => {
    if (tickets) {
      renderList(tickets);
    }
    // eslint-disable-next-line
  }, [tickets]);

  // FUNCTION SEARCH[filter]
  useEffect(() => {
    if (searchTerm) {
      const delayDebounceFn = setTimeout(() => {
        const params = {
          cerca: encodeURI(searchTerm),
        };

        axios
          .get(
            `${process.env.REACT_APP_API_URL}api/tiquets/cerca/${currentPage}/`,
            {
              params,
              auth: {
                username: process.env.REACT_APP_API_USER,
                password: process.env.REACT_APP_API_PWD,
              },
              headers: {
                token: JSON.parse(localStorage.getItem("userLogged")).token,
                idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
              },
            }
          )
          .then((res) => {
            // console.log(res);
            renderList(res.data.tiquets);
            setTotalTickets(res.data.total);
            window.scrollTo(0, 0); // Anar al principi de la pàgina
          });
      }, 300);

      return () => clearTimeout(delayDebounceFn);
    }
    // eslint-disable-next-line
  }, [searchTerm, currentPage]);

  // Carregar tiquets tenint en compte els filtres seleccionats i rebent com a paràmetre opcional si reiniciar el paginador
  const loadTickets = ({ reloadCurrentPage, scroll = false }) => {

    const filterCurrent = filter

    const params = {
      filtres: JSON.stringify(filterCurrent),
    };

    axios
      .get(`${process.env.REACT_APP_API_URL}api/tiquets/${currentPage}/`, {
        params,
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      })
      .then((res) => {
        setTickets(res.data.tiquets);
        renderList(res.data.tiquets);
        setTotalTickets(res.data.total);
        if (scroll) window.scrollTo(0, 0);


        if (reloadCurrentPage) {
          setCurrentPage(1);
        }
      });
  };

  // Canvi de pàgina Pagination handle
  const handlePagination = (e) => {
    const selectedPage = e.selected + 1;
    setCurrentPage(selectedPage);
  };

  // Funcio que carrega les accions al seleccionar els checkbox de la llista de tickets
  const handleCheckbox = (e, id, assumpte) => {
    e.stopPropagation();
    if (e.target.checked === true) {
      setTicketsSeleccionats((ticketsSeleccionats) => [
        ...ticketsSeleccionats,
        [id, assumpte],
      ]);
      setTicketsEliminar((ticketsEliminar) => [...ticketsEliminar, id]);
    } else {
      setTicketsSeleccionats((ticketsSeleccionats) =>
        ticketsSeleccionats.filter((e) => e[0] !== id)
      );
      setTicketsEliminar((ticketsEliminar) =>
        ticketsEliminar.filter((e) => e !== id)
      );
    }
  };

  // Funció que recorre els tickets i els crea les files (genera la taula)
  const renderList = (ticketsList) => {
    const itemList = [];
    if (ticketsList) {
      console.log(ticketsList.map(ticket => ticket.clientDataResposta))
      ticketsList.forEach((ticket) => {
        itemList.push(
          <ListItem
            handleCheckbox={handleCheckbox}
            key={ticket.id}
            ticket={ticket}
            tickets={tickets}
            loadTickets={loadTickets}
            setTickets={setTickets}
            empreses={empreses}
            tecnics={tecnics}
            estats={estats}
            serveis={serveis}
          />
        );
      });
      setItemList(itemList.sort(({clientDataResposta:a}, {clientDataResposta:b}) => b-a))
    } else {
      setItemList(
        <tr>
          <td colSpan="8">No s'ha trobat cap ticket</td>
        </tr>
      );
    }
  };

  // Funció per eliminar els registres seleccionats per eliminar amb el select
  const handleEliminar = () => {
    axios
      .delete(process.env.REACT_APP_API_URL + "api/tiquets/", {
        data: { ticketsSeleccionats: ticketsEliminar },
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      })
      .then((res) => {
        handleCloseEliminar();
        addToast("Ticket/s eliminado/s correctamente", {
          appearance: "success",
          autoDismiss: true,
        });
        window.location.reload(false);
        setTicketsEliminar([]);
      });
  };

  // Funció per a duplicar els tiquets seleccionats amb el select
  const handleDuplicar = () => {
    history.push(`/tickets/duplicar-ticket/${ticketsSeleccionats}`);
  };

  // Funció per a combinar els tiquets seleccionats amb el select i seleccionar el principal en el popup
  const handleCombinar = () => {
    // Ticket principal: Convertim a Int per enviar a API
    const ticketPrincipal = parseInt(combinarPrincipal);
    // Inicialitzem array ticketsACombinar
    const ticketsCombinar = [];

    // Recorrem tots els tickets seleccionats sense el principal i ho guardem a l'array ticketsACombinar
    ticketsSeleccionats.forEach((ticketSeleccionat) => {
      if (ticketSeleccionat[0] !== combinarPrincipal) {
        ticketsCombinar.push(parseInt(ticketSeleccionat[0]));
      }
    });

    // Fem petició a API de combinar tiquets
    axios
      .post(
        process.env.REACT_APP_API_URL + "api/tiquets/combinar/",
        { tickets: { ticketPrincipal, ticketsCombinar } },
        {
          auth: {
            username: process.env.REACT_APP_API_USER,
            password: process.env.REACT_APP_API_PWD,
          },
          headers: {
            token: JSON.parse(localStorage.getItem("userLogged")).token,
            idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
          },
        }
      )
      .then((res) => {
        // Tanquem popup combinar
        handleCloseCombinar();
        setCombinarPrincipal(null);

        // Redirigim al ticket principal
        history.push(`/ticket/${combinarPrincipal}/`);

        // TOAST RESPOSTA OK
        addToast("Tickets combinats correctament", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((error) => {
        // TOAST ERROR
        addToast("S'ha produït un error", {
          appearance: "error",
        });
        console.log(error);
      });

  };

  return (
    <>
    <Link to='/nou-ticket'>
    <button className={`lg:hidden fixed shadow-lg flex rounded-pill h-8  items-center justify-between px-3 bg-red-500 text-white ${scrollState === 'top' ? 'w-28' : 'w-13'}   z-10 top-3/4 right-16 `}>
    <IoPencilOutline size={20}/>
    <span className={` ${scrollState === 'top' ? 'block' : 'hidden'} text-sm font-semibold mt-1`}>Redactar</span>
    </button>
    </Link>
      <FiltersBar
        loadTickets={loadTickets}
        setSearchTerm={setSearchTerm}
        setFilter={setFilter}
        filter={filter}
        empreses={empreses}
        estats={estats}
        serveis={serveis}
        tecnics={tecnics}
        involucrats={tecnics}
      />
      <div>
        {ticketsSeleccionats.length === 1 ? (
          <Button
            style={{ marginRight: 10, marginBottom: 10 }}
            onClick={handleDuplicar}
          >
            Duplicar
          </Button>
        ) : (
          ""
        )}
        {ticketsSeleccionats.length > 1 ? (
          <Button
            style={{ marginRight: 10, marginBottom: 10 }}
            onClick={handleShowCombinar}
          >
            Combinar
          </Button>
        ) : (
          ""
        )}
        {JSON.parse(localStorage.getItem("userLogged")).nivell === "1" &&
          ticketsSeleccionats.length > 0 ? (
          <Button
            variant="secondary"
            style={{ marginRight: 10, marginBottom: 10 }}
            onClick={handleShowEliminar}
          >
            Eliminar
          </Button>
        ) : (
          ""
        )}
      </div>

      <Table striped responsive="sm">
        <thead>
          <tr className='hidden lg:table-row font-rob-light '>
            <th style={{ width: "1%" }}></th>
            <th className='' style={{ width: "20%" }}>Client</th>
            <th className='hidden  lg:table-cell' style={{ width: "40%" }}>Ticket</th>
            <th className='hidden  lg:table-cell' style={{ width: "15%" }}>Servei / Subservei</th>
            <th className='hidden  lg:table-cell' style={{ width: "5%" }}>Prioritat</th>
            <th className='' style={{ width: "13%" }}>Assignat</th>
            <th className='hidden  lg:table-cell' style={{ width: "6%" }}>Estat</th>
          </tr>
        </thead>
        <tbody>
          {itemList ? (
            totalTickets > 0 ? (
              itemList
            ) : (
              <tr>
                <td colSpan="8">
                  No s'ha trobat cap ticket amb els filtres seleccionats.
                </td>
              </tr>
            )
          ) : (
            <tr>
              <td colSpan="8">Carregant tickets...</td>
            </tr>
          )}
        </tbody>
      </Table>
      {totalTickets ? (
        <div style={{ textAlign: "right" }}>
          <small style={{ color: "grey" }}>
            Total tickets filtrats: {totalTickets}
          </small>
        </div>
      ) : (
        ""
      )}
      {/* PAGINATION */}
      {totalTickets > 25 ? (
        <Pagination
          handlePagination={handlePagination}
          totalRows={totalTickets}
        />
      ) : (
        ""
      )}

      <Modal show={showEliminar} onHide={handleCloseEliminar}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar tiquet</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          <p>Estàs segur d'eliminar definitivament aquest tiquet/tiquets?</p>
          <Button variant="secondary" onClick={handleEliminar}>
            Si, eliminar definitivament
          </Button>
          &nbsp;
          <Button variant="primary" onClick={handleCloseEliminar}>
            No, cancelar
          </Button>
        </Modal.Body>
      </Modal>

      <Modal show={showCombinar} onHide={handleCloseCombinar}>
        <Modal.Header closeButton>
          <Modal.Title>Combinar tiquets</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          <p>Escull el tiquet principal per combinar</p>
          <Table striped hover responsive="sm">
            <thead>
              <tr>
                <th></th>
                <th>ID</th>
                <th>Ticket</th>
              </tr>
            </thead>
            <tbody>
              {ticketsSeleccionats.map((ticketSeleccionat) => {
                return (
                  <tr key={ticketSeleccionat[0]}>
                    <td>
                      <input
                        onClick={() =>
                          setCombinarPrincipal(ticketSeleccionat[0])
                        }
                        type="radio"
                        checked={
                          combinarPrincipal === ticketSeleccionat[0]
                        }
                      />
                    </td>
                    <td>#{ticketSeleccionat[0]}</td>
                    <td style={{ textAlign: "left" }}>
                      {ticketSeleccionat[1]}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {combinarPrincipal && (
            <Button variant="primary" onClick={handleCombinar}>
              Combinar tiquets
            </Button>
          )}
          &nbsp;
          <Button variant="light" onClick={handleCloseCombinar}>
            Cancelar
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

import React, { useState } from "react";

import CreatableSelect from "react-select/creatable";

const CreatableInput = ({ onChange, defaultValue }) => {
  const components = {
    DropdownIndicator: null,
  };

  const createOption = (label) => ({
    label,
    value: label,
  });

  /* if (defaultValue) {
    defaultOptions = createOption(defaultValue);
  } */
  // console.log(defaultValue);

  const [value, setValue] = useState(defaultValue || null);
  const [inputValue, setInputValue] = useState();

  const handleChange = (value, actionMeta) => {
    setValue(value);
    onChange(value);
  };
  const handleInputChange = (inputValue) => {
    setInputValue(inputValue);
  };
  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        // Si value no es null
        if (value) {
          setValue((value) => [...value, createOption(inputValue)]);
        } else {
          setValue([createOption(inputValue)]);
        }
        setInputValue("");

        // Si value no es null
        if (value) {
          // Enviem al onchange del camp les dades
          onChange([...value, createOption(inputValue)]);
        } else {
          // Enviem al onchange del camp les dades
          onChange([createOption(inputValue)]);
        }
        break;
      default:
        break;
    }
  };

  return (
    <CreatableSelect
      components={components}
      inputValue={inputValue}
      isClearable={false}
      isMulti
      menuIsOpen={false}
      onChange={handleChange}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      placeholder=""
      value={value}
      size="sm"
      styles={{
        // Fixes the overlapping problem of the component
        menu: (provided) => ({
          ...provided,
          zIndex: 9999,
        }),
      }}
      className="select"
      style={{ width: "100%" }}
    />
  );
};

export default CreatableInput;

import React from "react";
import { Form } from "react-bootstrap";
// import { useHistory } from "react-router-dom";

export default ({
  hardware,
  hardwares,
  material,
  renderGeneric,
  renderImputat,
  handleEntregarMaterial,
  handleQuantitatChange,
}) => {
  // let history = useHistory();
  // console.log(hardware);
  return (
    <tr key={hardware.id}>
      <td>{hardware.producte}</td>

      {material !== "entregat" ? (
        <>
          {material === "imputat" && <td>{hardware.qty}</td>}
          <td className="">
            <i
              title="AFEGIR"
              className={`fa fa-check cursor-pointer hover:text-green-400`}
              style={{ fontSize: "20px", color: "green" }}
              onClick={() => {
                // Treiem de la llista anterior el item seleccionat
                const newHardwares = hardwares.filter((hard) => {
                  return hard.id !== hardware.id;
                });
                if (material === "generic") {
                  renderGeneric(newHardwares);
                } else if (material === "imputat") {
                  renderImputat(newHardwares);
                }

                handleEntregarMaterial(
                  hardware,
                  "0",
                  material === "generic" ? 1 : 0
                );
              }}
            ></i>
          </td>

          <td>
            <i
              title="AFEGIR"
              className={`fa fa-arrow-left cursor-pointer hover:text-blue-400`}
              style={{ fontSize: "20px", color: "#34588d" }}
              onClick={() => {
                // Treiem de la llista anterior el item seleccionat
                const newHardwares = hardwares.filter((hard) => {
                  return hard.id !== hardware.id;
                });
                if (material === "generic") {
                  renderGeneric(newHardwares);
                } else if (material === "imputat") {
                  renderImputat(newHardwares);
                }
                handleEntregarMaterial(
                  hardware,
                  "1",
                  material === "generic" ? 1 : 0
                );
              }}
            ></i>
          </td>
        </>
      ) : (
        <>
          {/* INPUT QUANTITAT DEL MATERIAL ENTREGAT */}
          <td>
            <Form.Group
              controlId="loginForm.usuari"
              style={{ width: "4rem", margin: "0" }}
            >
              {hardware.generic === 1 ? (
                <Form.Control
                  type="number"
                  maxLength="4"
                  defaultValue="1"
                  onChange={(e) => handleQuantitatChange(e, hardware)}
                />
              ) : (
                hardware.qty
              )}
            </Form.Group>
          </td>
          <td>{hardware.prestec === "n" ? "No" : "Si"}</td>
        </>
      )}
    </tr>
  );
};

import React from "react";
import "../../styles/style.css";
import "../../../node_modules/font-awesome/css/font-awesome.min.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";

// Toasts
import { ToastProvider } from "react-toast-notifications";

// Import routing
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

// Layout components
import Header from "../layout/Header/Header";
import Sidebar from "../layout/Sidebar/Sidebar";

// Routing screens
// Tickets
import Tickets from "../../screens/Tickets/Tickets";
import TicketsRecurrents from "../../screens/Tickets/TicketsRecurrents";
import NewTicket from "../../screens/Tickets/NewTicket";
import DuplicateTicket from "../../screens/Tickets/DuplicateTicket";
import NewTicketRecurrent from "../../screens/Tickets/NewTicketRecurrent";
import EditTicketRecurrent from "../../screens/Tickets/EditTicketRecurrent";
import TicketDetails from "../../screens/Tickets/TicketDetails";
import TicketLog from "../../screens/Tickets/TicketLog";
import TicketRecurrentLog from "../../screens/Tickets/TicketRecurrentLog";
import Filters from "../../screens/Tickets/Filters";
// Albarans
import NewAlbaraZammad from "../../screens/Albarans/NewAlbaraZammad";
import NewAlbara from "../../screens/Albarans/NewAlbara";
import ImputarMaterial from "../../screens/Albarans/ImputarMaterial";
// Email
import NewEmail from "../../screens/Tickets/NewEmail";

// Dashboard
import Dashboard from "../../screens/Dashboard/Dashboard";

import Login from "../../screens/Login/Login";
import Logout from "../../components/shared/Logout/Logout";
import ImputarMaterialZammad from "../../screens/Albarans/ImputarMaterialZammad";
import LoginZammad from "../../screens/Login/LoginZammad";

function App() {
  const currentUrl = window.location.href;

  return (
    <ToastProvider placement={"top-center"}>
      <Router>
        <div className="App">
          {localStorage.getItem("userLogged") === null ? (
            !currentUrl.includes('zammad') ? <Login /> : <LoginZammad currentUrl={currentUrl} />
          ) : (
            <>
              <Header />
              {!currentUrl.includes('zammad') && <Sidebar />}
              <main className={`${!currentUrl.includes('zammad') ? 'pt-16 xs:pl-0 lg:pl-16 xs:pr-0 xs:pt-0 xs:-mt-4' : 'md:pt-16 px-8 xs:px-0 xs:pt-0'}`}>
                <Switch>
                  <Route exact path="/">
                    <Redirect to="/tickets" />
                  </Route>
                  <Route path="/login" exact component={Login} />
                  <Route path="/logout" exact component={Logout} />

                  <Route
                    path="/tickets-recurrents"
                    component={TicketsRecurrents}
                  />

                  <Route
                    path="/ticket-recurrent/:id/log"
                    component={(props) => (
                      <TicketRecurrentLog id={props.match.params.id} />
                    )}
                  />

                  <Route
                    path="/nou-ticket-recurrent"
                    component={NewTicketRecurrent}
                  />
                  <Route
                    path="/editar-ticket-recurrent/:id"
                    component={(props) => (
                      <EditTicketRecurrent id={props.match.params.id} />
                    )}
                  />
                  <Route path="/nou-ticket" component={NewTicket} />
                  <Route
                    path="/tickets/duplicar-ticket/:id"
                    component={(props) => (
                      <DuplicateTicket id={props.match.params.id} />
                    )}
                  />
                  <Route path="/tickets" component={Tickets} />
                  <Route
                    path="/ticket/:id/log"
                    component={(props) => (
                      <TicketLog id={props.match.params.id} />
                    )}
                  />

                  <Route
                    path="/ticket/:ticket_id/nou-albara-zammad/:user_id/:organization_id"
                    component={(props) => (
                      <NewAlbaraZammad params={props.match.params} />
                    )}
                  />
                  <Route
                    path="/ticket/:idTicket/albara-zammad/:idAlbara/imputar-material"
                    component={(props) => (
                      <ImputarMaterialZammad
                        ticket={props.match.params.idTicket}
                        albara={props.match.params.idAlbara}
                      />
                    )}
                  />

                  <Route
                    path="/ticket/:id/nou-albara"
                    component={(props) => (
                      <NewAlbara ticket={props.match.params.id} />
                    )}
                  />
                  <Route
                    path="/ticket/:idTicket/albara/:idAlbara/imputar-material"
                    component={(props) => (
                      <ImputarMaterial
                        ticket={props.match.params.idTicket}
                        albara={props.match.params.idAlbara}
                      />
                    )}
                  />
                  <Route
                    path="/ticket/:id"
                    component={(props) => (
                      <TicketDetails id={props.match.params.id} />
                    )}
                  />
                  <Route path="/filtres" component={Filters} />
                  <Route path="/nou-albara" component={NewAlbara} />
                  <Route path="/nou-email" component={NewEmail} />
                  <Route path="/dashboard" component={Dashboard} />

                </Switch>
              </main>
            </>
          )}
        </div>
      </Router>
    </ToastProvider>
  );
}

export default App;

import React from "react";

export default ({ className }) => {
  return (
    <div className='flex items-center  '>
    <h2 className={` hidden lg:block text-lg lg:text-2xl ${className} `}>
      {JSON.parse(localStorage.getItem("userLogged")).nom}
     
    </h2>
    <img  className='w-10 h-10 object-contain xs:mr-4 lg:hidden z-30 rounded-full' src={`${process.env.REACT_APP_API_URL}/sat/ct/imatges/imatges-tecnics/${JSON.parse(localStorage.getItem("userLogged")).foto}`} alt="perfil" /> 
    </div>
  );
};

import React, { useEffect, useState } from "react";
import Title from "../../components/shared/Title/Title";
import { Row, Col } from "react-bootstrap";
import ToTop from "../../assets/images/totop.png";

import { useToasts } from "react-toast-notifications";

import TicketContent from "../../components/Tickets/TicketContent/TicketContent";
import TicketAnswers from "../../components/Tickets/TicketContent/TicketAnswers/TicketAnswers";
import TicketSidebar from "../../components/Tickets/TicketContent/TicketSidebar/TicketSidebar";

import { useHistory } from "react-router-dom";
import axios from "axios";

const TicketDetails = ({ id }) => {
    const { addToast } = useToasts();
    const history = useHistory();

    const [ticket, setTicket] = useState(0);
    const [empreses, setEmpreses] = useState();
    const [showEdit, setShowEdit] = useState(false);
    const [editSidebar, setEditSidebar] = useState(false);

    useEffect(() => {
        // PETICIÓ API EMPRESES
        getCompanies()
        // Obté el ticket X
        getTicketData(id)
    }, [id]);

    const getCompanies = () => {
        axios
            .get(process.env.REACT_APP_API_URL + "api/empreses/", {
                auth: {
                    username: process.env.REACT_APP_API_USER,
                    password: process.env.REACT_APP_API_PWD,
                },
                headers: {
                    token: JSON.parse(localStorage.getItem("userLogged")).token,
                    idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
                },
            })
            .then((res) => {
                /* EMPRESES SELECT OPTIONS */
                const empresesList = [];
                res.data.forEach((empresa) => {
                    empresesList.push({
                        value: empresa.id,
                        label: empresa.nom,
                    });
                });
                setEmpreses(empresesList);
            });
    }

    const getTicketData = (idTicket) => {
        axios
            .get(`${process.env.REACT_APP_API_URL}api/tiquet/${idTicket}/`, {
                auth: {
                    username: process.env.REACT_APP_API_USER,
                    password: process.env.REACT_APP_API_PWD,
                },
                headers: {
                    token: JSON.parse(localStorage.getItem("userLogged")).token,
                    idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
                },
            })
            .then((res) => {
                const ticket = res.data;
                // Guardem les dades del ticket al state

                setTicket(ticket);
            });
    }
    
    // Al fer submit del formulari
    const handleSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }
        // Actualitzem el nou ticket a la base de dades (amb les dades del formulari)
        axios
            .put(
                `${process.env.REACT_APP_API_URL}api/tiquet/${ticket.id}/`,
                { ticket },
                {
                    auth: {
                        
                        username: process.env.REACT_APP_API_USER,
                        password: process.env.REACT_APP_API_PWD,
                    },
                    headers: {
                        token: JSON.parse(localStorage.getItem("userLogged")).token,
                        idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
                    },
                }
            )
            .then((res) => {
                // Recarreguem la pàgina amb el nou client
                setEditSidebar(false);

                // TOAST OK
                addToast("Tiquet modificat correctament", {
                    appearance: "success",
                    autoDismiss: true,
                });
            })
            .catch((error) => {
                console.log(error);
                // TOAST ERROR
                addToast("S'ha produït un error", {
                    appearance: "error",
                });
            });
    };

    const handleEdit = () => {
        setShowEdit(!showEdit);
    };

    const handleLog = () => {
        history.push(`/ticket/${ticket.id}/log/`);
    };

    return (
        <>
            {ticket ? (
                <div className='mx-2 xs:mt-6'>
                    <Title
                        title="Tickets"
                        title2={`#${ticket.id} - ${ticket.assumpte}`}
                        titleLink="/tickets"
                        button="Veure Log"
                        buttonAction={handleLog}
                        button2="Editar ticket"
                        buttonAction2={handleEdit}
                    />
                    <Row >
                        <Col className='mb-4 ' sm={9}>
                            <TicketContent
                                ticket={ticket}
                                setTicket={setTicket}
                                handleSubmit={handleSubmit}
                                handleEdit={handleEdit}
                                showEdit={showEdit}
                                empreses={empreses}
                            />
                            <TicketAnswers ticket={ticket} setTicket={setTicket} />
                        </Col>
                        <Col className='xs:ml-3' sm={3} style={{ paddingLeft: 0 }}>
                            <TicketSidebar
                                ticket={ticket}
                                setTicket={setTicket}
                                handleSubmit={handleSubmit}
                                editSidebar={editSidebar}
                                setEditSidebar={setEditSidebar}
                            />
                        </Col>
                    </Row>

                    <img
                        className="totop xs:hidden"
                        src={ToTop}
                        alt="totop"
                        onClick={() =>
                            window.scroll({ top: 0, left: 0, behavior: "smooth" })
                        }
                    />
                </div>
            ) : (
                ""
            )}
        </>
    );
};

export default TicketDetails;

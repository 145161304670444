import React, { useState, useEffect } from "react";
import Title from "../../components/shared/Title/Title";
import AlbaraForm from "../../forms/AlbaraForm";
import AlbaraSuggestionsNotes from "../../components/Albarans/AlbaraSuggestionsNotes/AlbaraSuggestionsNotes";

import { useToasts } from "react-toast-notifications";

import { useHistory } from "react-router-dom";
import axios from "axios";

const NewAlbara = ({ ticket }) => {
  const { addToast } = useToasts();
  const history = useHistory();

  const minutsFacturatsDefault = 0; // 60 minuts per defecte
  // Estat amb dades per defecte
  const [ticketNotes, setTicketNotes] = useState()
  const [albara, setAlbara] = useState({
    empresa: "",
    operador: JSON.parse(localStorage.getItem("userLogged")).id,
    minuts_facturats: minutsFacturatsDefault,
    incidencia: "",
    tipus_manteniment: "1",
    idServei: "",
    idTicket: ticket,
    idSubServei: "",
    minutsDedicats: "",
    horesDedicades: "",
  });
  // console.log(albara);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}api/tiquet/notes/${ticket}/`, {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      })
      .then((res) => { setTicketNotes(res.data) })
      .catch((error) => console.error(error))

  }, [ticket])
  useEffect(() => {
    if (albara) {
      if(albara.incidencia == null) albara.incidencia = ""
    }
  }, [albara])
  // Al carregar la screen
  useEffect(() => {
    // Si existeix un ticket
    if (ticket > 0) {
      // Carreguem les dades dels desplegables
      // PETICIÓ API EMPRESES
      axios
        .get(`${process.env.REACT_APP_API_URL}api/tiquet/${ticket}/`, {
          auth: {
            username: process.env.REACT_APP_API_USER,
            password: process.env.REACT_APP_API_PWD,
          },
          headers: {
            token: JSON.parse(localStorage.getItem("userLogged")).token,
            idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
          },
        })
        .then((res) => {
          const ticketInfo = res.data;

          // Guardem les dades del ticket al state
          setAlbara({
            ...albara,
            empresa: ticketInfo.idClient,
            incidencia: ticketInfo?.textAlbara,
            minuts_facturats: ticketInfo.minuts
              ? ticketInfo.minuts
              : minutsFacturatsDefault,
            idServei: ticketInfo.idServei,
            idSubServei: ticketInfo.idSubServei,
            minutsDedicats: ticketInfo.minutsDedicats,
            horesDedicades: ticketInfo.horesDedicades,
          });
        });
    }
    // eslint-disable-next-line
  }, [ticket]);

  // Al fer clic al botó de Cancelar
  const handleCancel = (e, ticket) => {
    history.push(`/ticket/${ticket}`);
  };

  // Al fer submit del formulari
  const handleSubmit = (e) => {
    document.getElementById("btnCrearAlbara").disabled = true
    e.preventDefault();
    // No es pot generar albara si el servei / subservei / empresa no esta indicada
    if (albara.idServei === "0" || albara.idSubServei === "0" || albara.empresa === "0" ||
      albara.idServei === "" || albara.idSubServei === "" || albara.empresa === "" ||albara.minuts_facturats === 0) {
      document.getElementById("btnCrearAlbara").disabled = false
      return addToast("S'ha produït un error amb el servei", { appearance: "error", });
    }

    // Insertem el nou albara a la base de dades (amb les dades del formulari)
    axios
      .post(
        process.env.REACT_APP_API_URL + "api/albara/",
        { albara },
        {
          auth: {
            username: process.env.REACT_APP_API_USER,
            password: process.env.REACT_APP_API_PWD,
          },
          headers: {
            token: JSON.parse(localStorage.getItem("userLogged")).token,
            idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
          },
        }
      )
      .then((res) => {
        // Redireccionem a la pàgina de imputar material
        history.push(`/ticket/${ticket}/albara/${res.data}/imputar-material/`);

      })
      .catch((error) => {
        console.log(error);
        // TOAST ERROR
        addToast("S'ha produït un error", {
          appearance: "error",
        });
        document.getElementById("btnCrearAlbara").disabled = false

      });
  };
  return (
    <>
      {ticket > 0 ? (
        <Title
          title="Tiquets"
          title2={`#${ticket}`}
          title3="Crear albarà"
          titleLink="/tickets"
          title2Link={`/ticket/${ticket}`}
        />
      ) : (
        ""
      )}
      <AlbaraForm
        albara={albara}
        setAlbara={setAlbara}
        handleCancel={(e) => handleCancel(e, ticket)}
        handleSubmit={handleSubmit}
      />
      {ticketNotes && (
        <AlbaraSuggestionsNotes notes={ticketNotes} setAlbara={setAlbara} albara={albara} />
      )}
    </>
  );
};

export default NewAlbara;



import React, {  useState } from "react";
import { Container, } from "react-bootstrap";

const AlbaraNote = ({ note }) => {

    const [currentNote,] = useState(note)
    console.log(currentNote)
    return <Container style={{
        backgroundColor: "rgb(254, 255, 236)",
        padding: "15px",
        marginTop: "15px",
        cursor: "pointer"
    }} className="border  ">
        
        <Container className="   mt-2 px-3 py-2 " dangerouslySetInnerHTML={{ __html: currentNote.text }}>

        </Container>
        <small className="d-flex" style={{justifyContent:"flex-end"}}>
            Hores invertides:
            <strong>
               &nbsp;{Math.round(currentNote.minuts/60 * 100) / 100}
            </strong>
        </small>


    </Container>
}
export default AlbaraNote;
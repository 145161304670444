import React, { useState, useEffect } from "react";
import Title from "../../components/shared/Title/Title";
import TicketRecurrentForm from "../../forms/TicketRecurrentForm";

import { useToasts } from "react-toast-notifications";

import { useHistory } from "react-router-dom";
import axios from "axios";

const EditTicketRecurrent = ({ id }) => {
  const { addToast } = useToasts();
  const history = useHistory();

  // Estat amb dades per defecte
  const [ticketRecurrent, setTicketRecurrent] = useState();

  useEffect(() => {
    // OBTENIR TICKET RECURRENT
    axios
      .get(`${process.env.REACT_APP_API_URL}api/tiquet/recurrent/${id}/`, {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      })
      .then((res) => {
        setTicketRecurrent(res.data);
      });
  }, [id]);

  // Al fer clic al botó de Cancelar
  const handleCancel = () => {
    history.push("/tickets-recurrents");
  };

  // Al fer submit del formulari
  const handleSubmit = () => {
    // Actualitzem el nou ticket a la base de dades (amb les dades del formulari)
    // console.log(ticketRecurrent);
    document.getElementById("btnCreateRecurrentTicket").disabled = true
    axios
      .put(
        `
        ${process.env.REACT_APP_API_URL}api/tiquet/recurrent/${ticketRecurrent.id}/`,
        { ticketRecurrent },
        {
          auth: {
            username: process.env.REACT_APP_API_USER,
            password: process.env.REACT_APP_API_PWD,
          },
          headers: {
            token: JSON.parse(localStorage.getItem("userLogged")).token,
            idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
          },
        }
      )
      .then((res) => {
        // console.log(res);
        // Redireccionem a la pàgina del ticket creat
        history.push(`/tickets-recurrents/`);
        // TOAST OK
        addToast("Tiquet recurrent modificat correctament", {
          appearance: "success",
          autoDismiss: true,
        });
        // console.log(ticketRecurrent);
      })
      .catch((error) => {
        document.getElementById("btnCreateRecurrentTicket").disabled = false
        console.log(error);
        // TOAST ERROR
        addToast("S'ha produït un error", {
          appearance: "error",
        });
      });
  };
  return (
    <>
      <Title
        title="Tickets"
        titleLink="/tickets"
        title2="Tickets recurrents"
        title2Link="/tickets-recurrents"
        title3={`Editar`}
      ></Title>
      {ticketRecurrent ? (
        <TicketRecurrentForm
          action="edit"
          ticketRecurrent={ticketRecurrent}
          setTicketRecurrent={setTicketRecurrent}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default EditTicketRecurrent;

import React from "react";
import styles from "./Sidebar.module.css";

import Block from "./Block/Block";

const Sidebar = () => {
  const block1 = [
    {
      id: 1,
      title: "Resum",
      icon: "dashboard",
      url: "//www.gestinet.info/sat/ct/index.php",
    },
    {
      id: 2,
      title: "Balanç",
      icon: "line-chart",
      url: "//www.gestinet.info/sat/ct/index.php?doc=b",
    },
    {
      id: 3,
      title: "Avisos",
      icon: "warning",
      url: "//www.gestinet.info/sat/ct/index.php?doc=au",
    },
    {
      id: 4,
      title: "Tickets",
      icon: "ticket",
      url: "/tickets",
    },
  ];
  const block2 = [
    {
      id: 5,
      title: "Nou albarà",
      icon: "plus-square-o",
      url: "https://www.gestinet.info/sat/ct/index.php?doc=n",
    },
    {
      id: 6,
      title: "Històric",
      icon: "history",
      url: "https://www.gestinet.info/sat/ct/index.php?doc=b",
    },
    {
      id: 7,
      title: "Anul·lats",
      icon: "times",
      url: "https://www.gestinet.info/sat/ct/index.php?doc=an",
    },
  ];
  const block3 = [
    {
      id: 8,
      title: "Empreses",
      icon: "building",
      url: "//www.gestinet.info/sat/ct/index.php?doc=m",
    },
    {
      id: 9,
      title: "Persones",
      icon: "user",
      url: "//www.gestinet.info/sat/ct/index.php?doc=r",
    },
    {
      id: 10,
      title: "Backup Remot",
      icon: "server",
      url: "//www.gestinet.info/sat/ct/index.php?doc=backups",
    },
    {
      id: 11,
      title: "LOPD",
      icon: "book",
      url: "//www.gestinet.info/sat/ct/index.php?doc=lopd",
    },
    {
      id: 12,
      title: "Mat. no entregat",
      icon: "wrench",
      url: "//www.gestinet.info/sat/ct/index.php?doc=mi",
    },
  ];
  const block4 = [
    {
      id: 13,
      title: "Operadors",
      icon: "user-md",
      url: "//www.gestinet.info/sat/ct/index.php?doc=d",
    },
    {
      id: 14,
      title: "Vacances",
      icon: "ship",
      url: "//www.gestinet.info/sat/ct/index.php?doc=vac",
    },
    {
      id: 15,
      title: "Guàrdies",
      icon: "list-alt",
      url: "//www.gestinet.info/sat/ct/index.php?doc=guar",
    },
    {
      id: 16,
      title: "Documents",
      icon: "file-text",
      url: "//www.gestinet.info/sat/ct/index.php?doc=docs",
    },
    {
      id: 17,
      title: "Tancar sessió",
      icon: "sign-out",
      url: "//www.gestinet.info/sat/tancar.php",
    },
  ];
  return (
    <nav className={`${styles.sidebar} hidden lg:block `}>
      <Block title="General" items={block1} key={1} />
      <Block title="Assistències" items={block2} key={2} />
      <Block title="Empreses" items={block3} key={3} />
      <Block title="Administració" items={block4} key={4} />
    </nav>
  );
};

export default Sidebar;

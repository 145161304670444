import React, { useEffect, useState } from "react";
import axios from "axios";
import { Form, Button, Container, InputGroup, Row, Col,} from "react-bootstrap";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";
// import CreatableSelect from "react-select/creatable";

import { useToasts } from "react-toast-notifications";
import CreatableInput from "../components/shared/CreatableInput/CreatableInput";

const AnswerForm = ({
    resposta,
    setResposta,
    handleCancel,
    handleSubmit,
    adjunts,
    ticket,
    setAdjunts,
}) => {
    // console.log(ticket);
    const TICKET_ACTIONS = {
        REPLY: 1,
        RESEND: 2,
        NOTE: 3
    }
    const { addToast } = useToasts();
    const [tecnics, setTecnics] = useState();
    useEffect(() => {
        // TECNICS
        axios
            .get(process.env.REACT_APP_API_URL + "api/tecnics/", {
                auth: {
                    username: process.env.REACT_APP_API_USER,
                    password: process.env.REACT_APP_API_PWD,
                },
                headers: {
                    token: JSON.parse(localStorage.getItem("userLogged")).token,
                    idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
                },
            })
            .then((res) => {
                const itemList = [];
                res.data.forEach((tecnic) => {
                    itemList.push({
                        value: tecnic.id,
                        label: tecnic.nom,
                        email: tecnic.email,
                    });
                });

                setTecnics(itemList);
            });
    }, []);

    // VALIDAR ADJUNTS
    const validarAdjunts = (e) => {
        // Validem que sigui menor a 30MB (limit de tamany arxiu)
        const adjunts = e.target.files;
        const limit = 31457280; // 30MB

        // Recorrem tots els adjunts i si trobem un que supera el límit, mostrem error i fem return
        for (let i = 0; i < adjunts.length; i++) {
            if (adjunts[i].size > limit) {
                addToast(
                    `Error al adjuntar fitxers. El límit és ${limit / 1024 / 1024}MB`,
                    {
                        appearance: "error",
                    }
                );
                e.target.value = null;
                return;
            }
        }

        setAdjunts(adjunts);
    };

    const getActionHeader = (actionType) => {
        if (actionType === TICKET_ACTIONS.NOTE) return renderNoteHeader()
        if (actionType === TICKET_ACTIONS.RESEND) return renderReesendHeader()
        if (actionType === TICKET_ACTIONS.REPLY) return renderReplyHeader()
    }

    const renderNoteHeader = () => {
        return <Row>
            <Col sm={8}>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text id="inputGroupPrepend">
                                Notificar a:
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        {tecnics ? (
                            <Select
                                styles={{
                                    // Fixes the overlapping problem of the component
                                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                                }}
                                className="select"
                                /* value={tecnics.filter(
                                  (tecnic) => tecnic.value === ticketRecurrent.idClient
                                )} */
                                isMulti
                                onChange={(e) => {
                                    // console.log(e);
                                    setResposta({
                                        ...resposta,
                                        correuReceptor: e,
                                    });
                                }}
                                options={tecnics}
                                placeholder="Seleccionar tècnic"
                            />
                        ) : (
                            ""
                        )}
                    </InputGroup>
                </Form.Group>
            </Col>
            <Col sm={4}>
                <Form.Group controlId="exampleForm.ControlSelect1">
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text id="inputGroupPrepend">
                                Tipo
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            as="select"
                            onChange={(e) => {
                                /* setTicket({ ...ticket, idClient: e.target.value });
                                handleClient(e.target.value); */
                            }}
                        >
                            <option value="privada">Privada</option>
                            <option value="publica">Pública</option>
                        </Form.Control>
                    </InputGroup>
                </Form.Group>
            </Col>
        </Row>
    }

    const renderReesendHeader = () => {
        return <>
            <Form.Group controlId="exampleForm.ControlTextarea1">
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend">
                            Destinatari
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                        type="text"
                        value={resposta.correuReceptor}
                        autoFocus
                        onChange={(e) =>
                            setResposta({ ...resposta, correuReceptor: e.target.value })
                        }
                    />
                </InputGroup>
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlTextarea1">
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend">CC</InputGroup.Text>
                    </InputGroup.Prepend>

                    <CreatableInput
                        defaultValue={resposta.cc}
                        onChange={(res) => {
                            setResposta({
                                ...resposta,
                                cc: res,
                            });
                        }}
                    />
                </InputGroup>
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlTextarea1">
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend">BCC</InputGroup.Text>
                    </InputGroup.Prepend>
                    <CreatableInput
                        defaultValue={resposta.bcc}
                        onChange={(res) => {
                            setResposta({
                                ...resposta,
                                bcc: res,
                            });
                        }}
                    />
                </InputGroup>
            </Form.Group>
        </>
    }

    const renderReplyHeader = () => {
        return <>
            {/* RESPOSTA A CLIENT */}
            <Form.Group controlId="exampleForm.ControlTextarea1">
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend">
                            Destinatari
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control name="email" type="text" value={ticket.email} disabled />
                </InputGroup>
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlTextarea1">
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend">CC</InputGroup.Text>
                    </InputGroup.Prepend>

                    <CreatableInput
                        name="cc"
                        defaultValue={resposta.cc}
                        onChange={(res) => {
                            setResposta({
                                ...resposta,
                                cc: res,
                            });
                        }}
                    />
                </InputGroup>
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlTextarea1">
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend">BCC</InputGroup.Text>
                    </InputGroup.Prepend>
                    <CreatableInput
                        name="bcc"
                        defaultValue={resposta.bcc}
                        onChange={(res) => {
                            setResposta({
                                ...resposta,
                                bcc: res,
                            });
                        }}
                    />
                </InputGroup>
            </Form.Group>
        </>
    }


    return (

        <Container
            style={{
                backgroundColor: "#f8f9fa",
                padding: 12,
                marginTop: 10,
                border: "1px solid #ced9e8",
            }}
        >
            <Form>

                {getActionHeader(resposta.tipus)}


                <Form.Group controlId="exampleForm.ControlTextarea1">
                    <CKEditor
                        name="text"
                        editor={ClassicEditor}
                        data=""
                        onInit={(editor) => {
                            if (resposta.tipus !== TICKET_ACTIONS.RESEND) {
                                editor.editing.view.focus();
                            }

                            window.scrollTo(0, document.body.scrollHeight);
                        }}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setResposta({ ...resposta, text: data });
                        }}
                        config={{
                            removePlugins: ["Heading"],
                            ckfinder: {
                                uploadUrl: `${process.env.REACT_APP_API_URL}ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images&responseType=json`,
                            },
                        }}
                    />
                </Form.Group>
                <Row>
                    <Col sm={6}>
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroupPrepend">
                                        Temps invertit
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    name="horesDedicades"
                                    type="number"
                                    placeholder="Hores"
                                    style={{ marginRight: 10 }}
                                    max={99}
                                    value={resposta.horesDedicades ? resposta.horesDedicades : ""}
                                    onChange={(e) => {
                                        setResposta({
                                            ...resposta,
                                            horesDedicades: e.target.value,
                                        });
                                    }}
                                />
                                <Form.Control
                                    name="minutsDedicats"
                                    type="number"
                                    placeholder="Minuts"
                                    max={59}
                                    value={resposta.minutsDedicats ? resposta.minutsDedicats : ""}
                                    onChange={(e) => {
                                        setResposta({
                                            ...resposta,
                                            minutsDedicats: e.target.value,
                                        });
                                    }}
                                />
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col sm={5}>
                        <Button variant="primary" type="submit" onClick={handleSubmit} id="btnRespondreTicketAClient">
                            {resposta.tipus === TICKET_ACTIONS.REPLY && "Respondre ticket  a client"}
                            {resposta.tipus === TICKET_ACTIONS.RESEND && "Reenviar email"}
                            {resposta.tipus === TICKET_ACTIONS.NOTE && "Afegir nota"}
                        </Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button variant="light" type="button" onClick={handleCancel}>
                            Cancelar
                        </Button>
                    </Col>
                    <Col sm={7}>
                        <Form.Group>
                            <Form.Control
                                name="adjunts[]"
                                type="file"
                                multiple
                                onChange={(e) => validarAdjunts(e)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
        </Container>

    );
};

export default AnswerForm;

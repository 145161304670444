import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Button, Container, Row, Col, InputGroup } from "react-bootstrap";
import Select from "react-select";

import { useToasts } from "react-toast-notifications";

const AlbaraForm = ({ albara, setAlbara, handleCancel, handleSubmit }) => {
  const { addToast } = useToasts();

  // Estat amb dades desplegables
  const [empreses, setEmpreses] = useState();
  const [serveis, setServeis] = useState();
  const [subServeis, setSubServeis] = useState();
  // eslint-disable-next-line
  const [tecnics, setTecnics] = useState();
  // console.log(albara);
  // Al carregar la screen
  useEffect(() => {
    // Carreguem les dades dels desplegables
    // PETICIÓ API EMPRESES
    const apiEmpreses = axios.get(
      process.env.REACT_APP_API_URL + "api/empreses/",
      {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      }
    );

    // PETICIÓ API SERVEIS
    const apiServeis = axios.get(
      process.env.REACT_APP_API_URL + "api/serveis/",
      {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      }
    );

    // PETICIO API SUBSERVEIS
    const apiSubServeis = axios.get(
      `${process.env.REACT_APP_API_URL}api/serveis/subserveis/`,
      {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      }
    );

    // PETICIÓ API TECNICS
    const apiTecnics = axios.get(
      process.env.REACT_APP_API_URL + "api/tecnics/",
      {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      }
    );

    axios
      .all([apiEmpreses, apiServeis, apiSubServeis, apiTecnics])
      .then(
        axios.spread((...responses) => {
          const responseEmpreses = responses[0].data;
          const responseServeis = responses[1].data;
          const responseSubServeis = responses[2].data;
          const responseTecnics = responses[3].data;

          /* EMPRESES SELECT OPTIONS */
          const empresesList = [];
          responseEmpreses.forEach((empresa) => {
            empresesList.push({
              value: empresa.id,
              label: empresa.nom,
            });
          });
          setEmpreses(empresesList);

          /* SERVEIS SELECT OPTIONS */
          const serveisList = [];
          responseServeis.forEach((servei) => {
            serveisList.push({
              value: servei.id,
              label: servei.nom,
            });
          });
          setServeis(serveisList);

          /* SUBSERVEIS SELECT OPTIONS */
          const subServeisList = [];
          responseSubServeis.forEach((subServei) => {
            subServeisList.push({
              value: subServei.id,
              label: subServei.nom,
            });
          });
          setSubServeis(subServeisList);

          /* TECNICS SELECT OPTIONS */
          const tecnicsList = [
            {
              value: "null",
              label: "Sense assignar",
            },
          ];
          responseTecnics.forEach((tecnic) => {
            tecnicsList.push({
              value: tecnic.id,
              label: tecnic.nom,
            });
          });
          setTecnics(tecnicsList);
        })
      )
      .catch((errors) => {
        // TOAST ERROR
        addToast("S'ha produït un error", {
          appearance: "error",
        });
      });
    // eslint-disable-next-line
  }, []);

  // HANDLE SELECCIONAR EMPRESA
  const handleClient = (client) => {
    setAlbara({
      ...albara,
      empresa: client.value,
    });
  };

  // HANDLE SELECCIONAR SERVEI
  const handleServei = (servei) => {
    getDadesServei(servei.value);

    setAlbara({
      ...albara,
      idServei: servei.value,
    });
  };
  // HANDLE SELECCIONAR SUBSERVEI
  const handleSubServei = (subServei) => {
    setAlbara({
      ...albara,
      idSubServei: subServei.value,
    });
  };

  const getDadesServei = (idServei) => {
    // Guardem les dades de serveis/subserveis dins del estat
    axios
      .get(`${process.env.REACT_APP_API_URL}api/servei/${idServei}/`, {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      })
      .then((res) => {
        // Carreguem la llista de serveis per a buscar els subserveis
        // Guardem els subserveis del servei seleccionat
        const itemList = [];
        res.data.subserveis.forEach((subServei) => {
          itemList.push({
            value: subServei.id,
            label: subServei.nom,
          });
        });
        setSubServeis(itemList);
      });
  };

  // CALCUL DELS OPTIONS DEL SELECT DE MINUTS
  const minutsList = () => {
    // Numero de options que hi hauran al select
    const numMinuts = 49;
    const minutsArray = [];
    for (let i = 0; i < numMinuts; i++) {
      // Calculem els minuts
      const minuts = 30 * i;
      // Calculem les hores
      const hores = minuts / 60;

      // Afegim el option dins de l'array
      minutsArray.push(
        <option value={minuts} key={minuts}>
          {minuts} min ({hores} h)
        </option>
      );
    }
    return minutsArray;
  };
  // console.log(albara);
  return (
    <Form>
      <Container>
        <Row>
          <Col sm={8}>
            <Form.Group controlId="exampleForm.ControlSelect1">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">
                    Client
                  </InputGroup.Text>
                </InputGroup.Prepend>
                {empreses ? (
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    isDisabled={true}
                    className="select"
                    value={empreses.filter(
                      (empresa) => empresa.value === albara.empresa
                    )}
                    onChange={handleClient}
                    options={empreses}
                    placeholder="Seleccionar client"
                  />
                ) : (
                  ""
                )}
              </InputGroup>
            </Form.Group>

            <Form.Group controlId="exampleForm.ControlTextarea1">
              <InputGroup.Prepend>
                <InputGroup.Text id="inputGroupPrepend">
                  Text albarà
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                as="textarea"
                rows="10"
                defaultValue={albara.incidencia}
                onChange={(e) => {
                  setAlbara({
                    ...albara,
                    incidencia: e.target.value,
                  });
                }}
              />
            </Form.Group>

            <div
              style={{
                textAlign: "center",
                marginTop: "2rem",
              }}
            >
              <Button id="btnCrearAlbara" variant="primary" type="submit" onClick={handleSubmit}>
                Crear albarà
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button variant="light" type="button" onClick={handleCancel}>
                Cancelar
              </Button>
            </div>
          </Col>
          <Col sm={4}>
            <Form.Group controlId="exampleForm.ControlSelect1">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">
                    Servei
                  </InputGroup.Text>
                </InputGroup.Prepend>
                {serveis ? (
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    className="select"
                    value={serveis.filter(
                      (servei) => servei.value === albara.idServei
                    )}
                    onChange={handleServei}
                    options={serveis}
                    placeholder="Seleccionar servei"
                  />
                ) : (
                  ""
                )}
              </InputGroup>
            </Form.Group>

            {/* ********** SUBSERVEI ********** */}
            {subServeis && albara.idServei ? (
              <Form.Group controlId="exampleForm.ControlSelect1">
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text id="inputGroupPrepend">
                      Sub Servei
                    </InputGroup.Text>
                  </InputGroup.Prepend>

                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    className="select"
                    value={subServeis.filter(
                      (subServei) => subServei.value === albara.idSubServei
                    )}
                    onChange={handleSubServei}
                    options={subServeis}
                    placeholder="Seleccionar sub servei"
                  />
                </InputGroup>
              </Form.Group>
            ) : (
              ""
            )}

            <hr />

            {/* ********** MINUTS A FACTURAR ********** */}
            {albara.horesDedicades || albara.minutsDedicats ? (
              <Row>
                <Col
                  sm={12}
                  style={{
                    textAlign: "center",
                    color: "grey",
                    marginBottom: 10,
                  }}
                >
                  <small>
                    Temps invertit:{" "}
                    <strong>
                      {albara.horesDedicades
                        ? albara.horesDedicades === 1
                          ? albara.horesDedicades + " hora"
                          : albara.horesDedicades + " hores"
                        : ""}{" "}
                      {albara.minutsDedicats
                        ? albara.minutsDedicats + " minuts"
                        : ""}
                    </strong>
                  </small>
                </Col>
              </Row>
            ) : (
              ""
            )}
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">
                    Minuts a facturar
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  as="select"
                  value={albara.minuts_facturats}
                  onChange={(e) =>
                    setAlbara({
                      ...albara,
                      minuts_facturats: e.target.value,
                    })
                  }
                >
                  {minutsList()}
                </Form.Control>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

export default AlbaraForm;

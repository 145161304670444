import {Fragment, useState} from "react";
import {Link} from 'react-router-dom'
import styles from "./Header.module.css";
import { Dialog,   Transition } from '@headlessui/react'
import {  XIcon } from '@heroicons/react/outline'
import {AiOutlineMenu} from 'react-icons/ai'
import logo from "../../../assets/images/logo-gestinet.png";

// Components
import Logo from "./Logo/Logo";
import UserTitle from "./UserTitle/UserTitle";
import Actions from "./Actions/Actions";
const navigation = {
  pages: [
    { name: 'Crear tiquet', href: '/nou-ticket' },
    { name: 'Crear email', href: '/nou-email' },
    { name: 'Gestionar tickets recurrents', href: '/tickets-recurrents' },
    { name: 'Tancar sessió', href: '/logout' }
    
  ],
}

const Header = () => {
  const currentUrl = window.location.href;
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  return (
    <header className={`${styles.header} h-16 xs:flex static lg:fixed xs:justify-end shadow-md`}>
      <button className={`absolute ${mobileMenuOpen && 'hidden'} top-6 left-5 z-50  lg:hidden`} onClick={()=> setMobileMenuOpen(true)}><AiOutlineMenu size={20} /></button>
      <Logo className={styles.logo} />
      {!currentUrl.includes('zammad') && <UserTitle className={styles.userTitle} />}
      {!currentUrl.includes('zammad') && <Actions className={styles.actions} />}

      {/* Mobile menu */}
      <Transition.Root show={mobileMenuOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-40 flex lg:hidden" onClose={setMobileMenuOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex flex-col w-full max-w-xs pb-12 overflow-y-auto bg-white shadow-xl">
            
              <div className="flex justify-between px-4 py-1">
              <img src={logo} alt="logo" className="-ml-1 w-36" />
                <button
                  type="button"
                  className="inline-flex items-center justify-center p-2 -m-2 text-gray-400 rounded-md"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XIcon className="w-6 h-6" aria-hidden="true" />
                </button>
              
              </div>

              {/* Links */}

              <div className="px-4 py-6 space-y-6 border-t border-gray-200">
                {navigation.pages.map((page) => (
                  <div key={page.name}  onClick={() => setMobileMenuOpen(false)} className="flow-root">
                    <Link to={page.href}>
                    <span  className="block p-2 -m-2 font-medium text-gray-900">
                      {page.name}
                    </span>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
    </header>
  );
};

export default Header;

import React, { useState, useEffect } from "react";
import Title from "../../components/shared/Title/Title";
import AlbaraZammadForm from "../../forms/AlbaraZammadForm";
import AlbaraSuggestionsNotes from "../../components/Albarans/AlbaraSuggestionsNotes/AlbaraSuggestionsNotes";
import logoZammad from "../../assets/images/logo-zammad.png";

import { useToasts } from "react-toast-notifications";
import { CgSpinner } from "react-icons/cg";
import { AiFillStar } from "react-icons/ai";

import { useHistory } from "react-router-dom";
import axios from "axios";
import AlbaraZammadTimeArticles from "../../components/Albarans/AlbaraZammad/AlbaraZammadTimeArticles";
import AlbaraZammadInternalArticles from "../../components/Albarans/AlbaraZammad/AlbaraZammadInternalArticles";

import logoAlbarans from "../../assets/images/logo-albarans.png";
import { BiLink } from "react-icons/bi";
import { FiLoader } from "react-icons/fi";
import { GiStopSign } from "react-icons/gi";

const NewAlbaraZammad = ({ params }) => {
	const [getTicketId, setTicketId] = useState(
		params.ticket_id && params.ticket_id !== "" && params.ticket_id !== "-"
			? params.ticket_id
			: null
	);

	const { addToast } = useToasts();
	const history = useHistory();

	const [albara, setAlbara] = useState({
		empresa: null,
		operador: JSON.parse(localStorage.getItem("userLogged")).id,
		minuts_facturats: 0,
		incidencia: "",
		tipus_manteniment: "1",
		idServei: null,
		idTicket:
			params.ticket_id && params.ticket_id !== "" && params.ticket_id !== "-"
				? params.ticket_id
				: null,
		idTecnic: null,
		idSubServei: null,
		minutsDedicats: "",
		horesDedicades: "",
	});

	const [getCurrentMinutes, setCurrentMinutes] = useState(0);

	const [getZammadTicketInfo, setZammadTicketInfo] = useState("");
	const [getZammadUserId, setZammadUserId] = useState(0);
	const [getZammadUserName, setZammadUserName] = useState("");
	const [getZammadOrganizationId, setZammadOrganizationId] = useState(null);
	const [getZammadTicketTimeUnit, setZammadTicketTimeUnit] = useState(0);
	const [getZammadUserTimeUnit, setZammadUserTimeUnit] = useState(0);
	const [getZammadInternalArticles, setZammadInternalArticles] = useState(null);
	const [getZammadTicketTimeArticles, setZammadTicketTimeArticles] =
		useState(null);
	const [getEmpreses, setEmpreses] = useState(null);
	const [getServeis, setServeis] = useState(null);
	const [getSubServeis, setSubServeis] = useState(null);
	const [getTecnics, setTecnics] = useState(null);
	const [getMinutes, setMinutes] = useState(null);
	const [getErrorSeconds, setErrorSeconds] = useState(10);

	useEffect(() => {
		async function fetchData() {
			let isUnauthorizedError = false;

			const apiUrlUser = `https://zammad.gestinet.com/api/v1/users/${params.user_id}`;
			const apiUrlOrg = `https://zammad.gestinet.com/api/v1/organizations/${params.organization_id}`;
			const apiUrlTicket = `https://zammad.gestinet.com/api/v1/tickets/${params.ticket_id}`;
			const apiUrlHistory = `https://zammad.gestinet.com/api/v1/ticket_history/${params.ticket_id}`;

			const basicAuth = Buffer.from(
				`${process.env.REACT_APP_ZAMMAD_API_USER}:${process.env.REACT_APP_ZAMMAD_API_PWD}`
			).toString("base64");
			const headers = {
				Authorization: `Token token=${process.env.REACT_APP_ZAMMAD_API_TOKEN}`,
				Authorization: `Basic ${basicAuth}`,
				'Accept': '*/*',
				"Content-Type": 'text/plain',
			};

			// GET: User
			try {
				const resUser = await axios.get(apiUrlUser, { headers });

				const id_user_gestinet = resUser?.data?.id_user_gestinet;
				const user_firstname = resUser?.data?.firstname;
				const user_lastname = resUser?.data?.lastname;
				const user_fullname = user_firstname + " " + user_lastname;
				setZammadUserId(id_user_gestinet);
				setZammadUserName(user_fullname);
				setAlbara((prevState) => ({
					...prevState,
					idTecnic: id_user_gestinet ? id_user_gestinet : 0,
				}));
			} catch (error) {
				console.error("⚠ Error API Call User: ", error);

				setAlbara((prevState) => ({
					...prevState,
					idTecnic: 0,
				}));
			}

			// GET: Organization
			try {
				const resOrg = await axios.get(apiUrlOrg, { headers });

				const id_client_gestinet = resOrg?.data?.id_client_gestinet;
				setZammadOrganizationId(id_client_gestinet);
				setAlbara((prevState) => ({
					...prevState,
					empresa: id_client_gestinet ? id_client_gestinet : 0,
					// empresa: "720",
				}));
			} catch (error) {
				console.error("⚠ Error API Call Organization: ", error);
				setZammadOrganizationId(null);
				setAlbara((prevState) => ({
					...prevState,
					empresa: 0,
				}));
			}

			// GET: Ticket
			try {
				const resTicket = await axios.get(apiUrlTicket, { headers });

				const idServei = resTicket?.data?.id_service_gestinet;
				const idSubServei = resTicket?.data?.id_sub_service_gestinet;
				setAlbara((prevState) => ({
					...prevState,
					idServei: idServei ? idServei : 0,
					idSubServei: idSubServei ? idSubServei : 0,
				}));

				// const time_unit = resTicket?.data?.time_unit;
				// const wholeHours = Math.floor(time_unit ? Number(time_unit) : 0);
				// const remainingMinutes = Math.round((time_unit - wholeHours) * 60);
				// const final_time = {
				// 	time: time_unit,
				// 	hours: wholeHours,
				// 	minutes: remainingMinutes,
				// };

				const time_unit = resTicket?.data?.time_unit;
				const totalMinutes = time_unit ? Number(time_unit) : 0;
				const wholeHours = Math.floor(totalMinutes / 60);
				const remainingMinutes = totalMinutes % 60;
				const final_time = {
					time: time_unit,
					hours: wholeHours,
					minutes: remainingMinutes,
				};

				setZammadTicketTimeUnit(final_time);

				setZammadTicketInfo(resTicket);
			} catch (error) {
				console.error("⚠ Error API Call Ticket: ", error);

				setTicketId(null);
				getErrorTimeOut();

				setAlbara((prevState) => ({
					...prevState,
					idServei: 0,
					idSubServei: 0,
				}));
			}

			// GET: Ticket History
			try {
				const resHistory = await axios.get(apiUrlHistory, { headers });

				// Per calcular les hores dedicades de l'usuari facturador actual:
				const history_time_articles_user = resHistory?.data?.history?.filter(
					(article) =>
						article?.hasOwnProperty("attribute") &&
						article?.attribute === "time_unit" &&
						Number(article?.created_by_id) === Number(params?.user_id)
				);

				const sumUser = history_time_articles_user?.reduce(
					(accumulator, currentItem) => {
						const value_from =
							currentItem.value_from && currentItem.value_from !== ""
								? Number(currentItem.value_from)
								: 0;
						const value_to = Number(currentItem.value_to);
						const time_value = value_to - value_from;

						// console.log("🚀 ~ file: NewAlbara.js:83 ~ sum ~ value_from:", value_from)
						// console.log("🚀 ~ file: NewAlbara.js:84 ~ sum ~ value_to:", value_to)
						// console.log("🚀 ~ file: NewAlbara.js:85 ~ sum ~ time_value:", time_value)
						// console.log("🚀 ~ file: NewAlbara.js:90 ~ sum ~ accumulator:", accumulator)

						return accumulator + time_value;
					},
					0
				);

				// const wholeHoursUser = Math.floor(sumUser);
				// const remainingMinutesUser = Math.round(
				// 	(sumUser - wholeHoursUser) * 60
				// );
				// const final_time_user = {
				// 	time: sumUser,
				// 	hours: wholeHoursUser,
				// 	minutes: remainingMinutesUser,
				// };

				// const wholeHoursUser = Math.floor(sumUser);
				// const remainingMinutesUser = Math.round((sumUser - wholeHoursUser) * 60);
				// const final_time_user = {
				// 	time: sumUser,
				// 	hours: wholeHoursUser,
				// 	minutes: remainingMinutesUser,
				// };
				
				const time_unit = Math.floor(sumUser);
				const totalMinutes = time_unit ? Number(time_unit) : 0;
				const wholeHours = Math.floor(totalMinutes / 60);
				const remainingMinutes = totalMinutes % 60;
				const final_time_user = {
					time: time_unit,
					hours: wholeHours,
					minutes: remainingMinutes,
				};

				// setZammadUserTimeUnit(sumUser ? final_time_user : 0);
				setZammadUserTimeUnit(final_time_user);

				// Per mostrar un llistat de notes amb les hores dedicades:
				const history_time_articles = resHistory?.data?.history?.filter(
					(article) =>
						article.hasOwnProperty("attribute") &&
						article.attribute === "time_unit"
				);
				history_time_articles && history_time_articles.length > 0
					? fillTimeArticles(history_time_articles)
					: setZammadTicketTimeArticles([]);

				// Per mostrar un llistat de notes internes:
				const assets_ticket_articles = Array.from(
					Object.values(resHistory?.data?.assets?.TicketArticle)
				);
				const internal_articles = assets_ticket_articles?.filter(
					(item) => item.internal === true
				);
				setZammadInternalArticles(assets_ticket_articles);
			} catch (error) {
				console.error("⚠ Error API Call Ticket History: ", error);

				setZammadInternalArticles([]);
				setZammadTicketTimeArticles([]);
			}

			// ###############################################################

			// GET: Empreses
			try {
				const apiCallGet = await axios.get(
					process.env.REACT_APP_API_URL + "api/empreses/",
					{
						auth: {
							username: process.env.REACT_APP_API_USER,
							password: process.env.REACT_APP_API_PWD,
						},
						headers: {
							token: JSON.parse(localStorage.getItem("userLogged")).token,
							idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
							// token: process.env.REACT_APP_GESTINET_API_TOKEN,
							// idOperador: process.env.REACT_APP_GESTINET_API_USER_ID,
						},
					}
				);

				setEmpreses(apiCallGet?.data);
			} catch (error) {
				console.error("⚠ Error API Call Ticket History: ", error);

				if(error.response.status === 401) {
					console.log("Entré!");
					isUnauthorizedError = true;
				}

				setZammadInternalArticles([]);
				setZammadTicketTimeArticles([]);
			}

			// GET: Serveis
			try {
				const apiCallGet = await axios.get(
					process.env.REACT_APP_API_URL + "api/serveis/",
					{
						auth: {
							username: process.env.REACT_APP_API_USER,
							password: process.env.REACT_APP_API_PWD,
						},
						headers: {
							token: JSON.parse(localStorage.getItem("userLogged")).token,
							idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
							// token: process.env.REACT_APP_GESTINET_API_TOKEN,
							// idOperador: process.env.REACT_APP_GESTINET_API_USER_ID,
						},
					}
				);

				setServeis(apiCallGet?.data);
			} catch (error) {
				console.error("⚠ Error API Call Ticket History: ", error);

				if(error.response.status === 401) {
					console.log("Entré!");
					isUnauthorizedError = true;
				}

				setZammadInternalArticles([]);
				setZammadTicketTimeArticles([]);
			}

			// GET: Sub Serveis
			try {
				const apiCallGet = await axios.get(
					`${process.env.REACT_APP_API_URL}api/serveis/subserveis/`,
					{
						auth: {
							username: process.env.REACT_APP_API_USER,
							password: process.env.REACT_APP_API_PWD,
						},
						headers: {
							token: JSON.parse(localStorage.getItem("userLogged")).token,
							idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
							// token: process.env.REACT_APP_GESTINET_API_TOKEN,
							// idOperador: process.env.REACT_APP_GESTINET_API_USER_ID,
						},
					}
				);

				setSubServeis(apiCallGet?.data);
			} catch (error) {
				console.error("⚠ Error API Call Ticket History: ", error);

				if(error.response.status === 401) {
					console.log("Entré!");
					isUnauthorizedError = true;
				}

				setZammadInternalArticles([]);
				setZammadTicketTimeArticles([]);
			}

			// GET: Tècnics
			try {
				const apiCallGet = await axios.get(
					process.env.REACT_APP_API_URL + "api/tecnics/",
					{
						auth: {
							username: process.env.REACT_APP_API_USER,
							password: process.env.REACT_APP_API_PWD,
						},
						headers: {
							token: JSON.parse(localStorage.getItem("userLogged")).token,
							idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
							// token: process.env.REACT_APP_GESTINET_API_TOKEN,
							// idOperador: process.env.REACT_APP_GESTINET_API_USER_ID,
						},
					}
				);

				setTecnics(apiCallGet?.data);
			} catch (error) {
				console.error("⚠ Error API Call Ticket History: ", error);
				console.error("⚠ Error API Call Ticket History response status: ", typeof error.response.status);

				if(error.response.status === 401) {
					console.log("Entré!");
					isUnauthorizedError = true;
				}

				setZammadInternalArticles([]);
				setZammadTicketTimeArticles([]);
			}

			const numMinuts = 49;
			const minutsArray = [];
			for (let i = 0; i < numMinuts; i++) {
				// Calculem els minuts
				const minuts = 30 * i;
				// Calculem les hores
				const hores = minuts / 60;

				minutsArray.push({
					value: minuts,
					label: `${minuts} min (${hores} h)`,
				});
			}
			setMinutes(minutsArray);
			// console.log("🚀 ~ file: NewAlbaraZammad.js:378 ~ fetchData ~ minutsArray:", minutsArray)

			if(isUnauthorizedError) {
				window.location.reload();
				localStorage.removeItem('userLogged');
			}
		}

		!getTicketId ? getErrorTimeOut() : fetchData();
	}, []);

	const getErrorTimeOut = () => {
		const errorTimeOut = setInterval(() => {
			setErrorSeconds((prevState) => {
				if (prevState > 1) {
					return prevState - 1;
				} else {
					clearInterval(errorTimeOut);
					window.location.href = `https://zammad.gestinet.com/#dashboard`;
					return 0;
				}
			});
		}, 1000);
	};

	const fillTimeArticles = async (filteredNotes) => {
		const apiUrlUsers = `https://zammad.gestinet.com/api/v1/users/search?sort_by=created_at&query=*&role_ids%5B%5D=1&role_ids%5B%5D=2&role_ids%5B%5D=4&role_ids%5B%5D=5&role_ids%5B%5D=6&full=true`;

		const basicAuth = Buffer.from(
			`${process.env.REACT_APP_ZAMMAD_API_USER}:${process.env.REACT_APP_ZAMMAD_API_PWD}`
		).toString("base64");
		const headers = {
			Authorization: `Token token=${process.env.REACT_APP_ZAMMAD_API_TOKEN}`,
			Authorization: `Basic ${basicAuth}`,
		};

		axios
			.get(apiUrlUsers, { headers })
			.then((response) => {
				// console.log("All Users Object: ", response?.data?.assets?.User);

				const users = response?.data?.assets?.User;
				const arrayOfUsers = Object.keys(users).map((key) => ({
					...users[key],
				}));

				const filteredUserNames = filteredNotes.map((item) => ({
					...item,
					created_by_user: arrayOfUsers?.find(
						(user) => Number(user.id) === Number(item.created_by_id)
					),
				}));
				setZammadTicketTimeArticles(filteredUserNames);
			})
			.catch((error) => console.error("Users API Call: ", error));
	};

	// CRUD: POST
	const handleSubmit = (e) => {
		document.getElementById("btnCrearAlbara").disabled = true;
		e.preventDefault();
		// No es pot generar albara si el servei / subservei / empresa no esta indicada
		if (
			albara.idServei === "0" ||
			albara.idSubServei === "0" ||
			albara.empresa === "0" ||
			albara.idServei === "" ||
			albara.idSubServei === "" ||
			albara.empresa === "" ||
			albara.minuts_facturats === 0
		) {
			document.getElementById("btnCrearAlbara").disabled = false;
			return addToast("S'ha produït un error amb el servei", {
				appearance: "error",
			});
		}

		const basicAuth = Buffer.from(
			`${process.env.REACT_APP_ZAMMAD_API_USER}:${process.env.REACT_APP_ZAMMAD_API_PWD}`
		).toString("base64");
		const headers = {
			Authorization: `Token token=${process.env.REACT_APP_ZAMMAD_API_TOKEN}`,
			Authorization: `Basic ${basicAuth}`,
		};

		const zammadTicket = getZammadTicketInfo;
		zammadTicket.id_service_gestinet = albara.idServei;
		zammadTicket.id_sub_service_gestinet = albara.idSubServei;

		// Insertem el nou albara a la base de dades (amb les dades del formulari)
		axios
			.post(
				process.env.REACT_APP_API_URL + "api/albara/zammad/create/albara",
				{ albara },
				{
					auth: {
						username: process.env.REACT_APP_API_USER,
						password: process.env.REACT_APP_API_PWD,
					},
					headers: {
						token: JSON.parse(localStorage.getItem("userLogged")).token,
						idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
					},
				}
			)
			.then((res1) => {
				// Redireccionem a la pàgina de imputar material
				// history.push(`/ticket/${getTicketId}/albara/${res.data}/imputar-material/`);

				axios
					.put(
						`https://zammad.gestinet.com/api/v1/tickets/${params.ticket_id}`,
						{
							id_service_gestinet: `${albara.idServei}`,
							id_sub_service_gestinet: `${albara.idSubServei}`,
							state_id: "4",
							article: {
								subject: "Albarán",
								body: `${getZammadUserName} ha facturado el tiquet en el albarán '${res1?.data?.numAlbara}': https://www.gestinet.info/sat/ct/index.php?doc=n&id=${res1?.data?.idAlbara}.`,
								type: "note",
								internal: true,
							},
						},
						{ headers }
					)
					.then((res2) => {
						// Redireccionem a la pàgina de imputar material
						// history.push(`/ticket/${getTicketId}/albara/${res.data}/imputar-material/`);
						// window.location.href = `https://zammad.gestinet.com/#ticket/zoom/${params.ticket_id}`
						history.push(
							`/ticket/${getTicketId}/albara-zammad/${res1?.data?.numAlbara}/imputar-material/`
						);
					})
					.catch((error) => {
						console.log(error);
						// TOAST ERROR
						addToast("S'ha produït un error", {
							appearance: "error",
						});
						document.getElementById("btnCrearAlbara").disabled = false;
					});
			})
			.catch((error) => {
				console.log(error);
				// TOAST ERROR
				addToast("S'ha produït un error", {
					appearance: "error",
				});
				document.getElementById("btnCrearAlbara").disabled = false;
			});
	};

	return getTicketId ? (
		albara.idTicket !== null &&
		albara.empresa !== null &&
		albara.idTecnic !== null &&
		albara.idServei !== null &&
		albara.idSubServei !== null &&
		getZammadInternalArticles !== null &&
		getZammadTicketTimeArticles !== null &&
		getEmpreses !== null &&
		getServeis !== null &&
		getSubServeis !== null &&
		getTecnics !== null &&
		getMinutes !== null ? (
			<>
				{getTicketId > 0 && (
					<div
						style={{
							borderBottom: "1px solid #dee2e6",
							marginTop: "1.4rem",
							marginBottom: "1rem",
							overflow: "hidden",
							width: "100%",
						}}
					>
						<h1
							style={{
								color: "#34588d",
								fontSize: "1.75rem",
								float: "left",
								fontWeight: "600",
								marginBottom: "15px",
							}}
						>
							<a
								href={`https://zammad.gestinet.com/#ticket/view/my_assigned`}
								target="_self"
								className="hover:underline"
							>
								Tiquet Zammad
							</a>
							{" » "}
							<a
								href={`https://zammad.gestinet.com/#ticket/zoom/${getTicketId}`}
								target="_self"
								className="hover:underline"
							>
								#{getTicketId}
							</a>
							{" » Crear albarà"}
						</h1>
					</div>
				)}

				<AlbaraZammadForm
					albara={albara}
					setAlbara={setAlbara}
					handleSubmit={handleSubmit}
					zammadTicketId={getTicketId}
					zammadUserId={getZammadUserId}
					zammadUserName={getZammadUserName}
					zammadOrganizationid={getZammadOrganizationId}
					zammadTicketTimeUnit={getZammadTicketTimeUnit}
					zammadUserTimeUnit={getZammadUserTimeUnit}
					gestinetEmpreses={getEmpreses}
					gestinetServeis={getServeis}
					gestinetSubServeis={getSubServeis}
					gestinetTecnics={getTecnics}
					gestinetMinutes={getMinutes}
					getCurrentMinutes={getCurrentMinutes}
					setCurrentMinutes={setCurrentMinutes}
				/>

				<hr className="mb-0 mt-14" />

				{/* NOTES + TEMPS */}
				{/* <div className="w-full"> */}
					{/* NOTES INTERNES */}
					{/* <div className="flex flex-col justify-center w-full pt-4 pl-3 pr-4">
						<div className="flex flex-row items-center justify-around w-8/12 m-auto select-none">
							<div className="relative flex items-center justify-center w-3/12 mb-2 text-xl font-bold border-2 rounded text-amber-900 h-14 border-amber-900 bg-amber-50 w-12/12">
								<span>Notes</span>
							</div>

							<div className="relative flex items-center justify-center w-3/12 mb-2 text-xl font-bold text-teal-900 border-2 rounded h-14 border-cyan-900 bg-cyan-50 w-12/12">
								<span>Temps</span>
							</div>
						</div>

						<div className="flex flex-row items-center justify-around w-8/12 m-auto select-none">
							<div className="relative flex items-center justify-center w-3/12 mb-2 text-xl font-bold border-2 rounded text-amber-900 h-14 border-amber-900 bg-amber-50 w-12/12">
								<span>Notes</span>
							</div>

							<div className="relative flex items-center justify-center w-3/12 mb-2 text-xl font-bold text-teal-900 border-2 rounded h-14 border-cyan-900 bg-cyan-50 w-12/12">
								<span>Temps</span>
							</div>
						</div>
						<AlbaraZammadInternalArticles
							articles={getZammadInternalArticles}
							setAlbara={setAlbara}
							albara={albara}
							logoZammad={logoZammad}
						/>
					</div> */}

					{/* HORES */}
					{/* <div className="w-6/12 pt-4 pl-4 pr-3">
						<div className="w-6/12 m-auto select-none">
							<div className="relative flex items-center justify-center mb-2 text-xl font-bold text-teal-900 border-2 rounded h-14 border-cyan-900 bg-cyan-50 w-12/12">
								<span>Temps</span>
							</div>
						</div>

						<AlbaraZammadTimeArticles
							articles={getZammadTicketTimeArticles}
							setAlbara={setAlbara}
							albara={albara}
							logoZammad={logoZammad}
							getZammadUserName={getZammadUserName}
							setCurrentMinutes={setCurrentMinutes}
						/>
					</div> */}
				{/* </div> */}

				{/* NOTES INTERNES + HORES */}
				<div className="flex flex-row divide-x divide-gray-300">
					{/* NOTES INTERNES */}
					<div className="w-6/12 pt-4 pl-3 pr-4">
						<div className="w-6/12 m-auto select-none">
							<div className="relative flex items-center justify-center mb-2 text-xl font-bold border-2 rounded text-amber-900 h-14 border-amber-900 bg-amber-50 w-12/12">
								<span>Notes</span>
							</div>
						</div>

						<AlbaraZammadInternalArticles
							articles={getZammadInternalArticles}
							setAlbara={setAlbara}
							albara={albara}
							logoZammad={logoZammad}
						/>
					</div>

					{/* HORES */}
					<div className="w-6/12 pt-4 pl-4 pr-3">
						<div className="w-6/12 m-auto select-none">
							<div className="relative flex items-center justify-center mb-2 text-xl font-bold text-teal-900 border-2 rounded h-14 border-cyan-900 bg-cyan-50 w-12/12">
								<span>Temps</span>
							</div>
						</div>

						<AlbaraZammadTimeArticles
							articles={getZammadTicketTimeArticles}
							setAlbara={setAlbara}
							albara={albara}
							logoZammad={logoZammad}
							getZammadUserName={getZammadUserName}
							getCurrentMinutes={getCurrentMinutes}
							setCurrentMinutes={setCurrentMinutes}
							gestinetMinutes={getMinutes}
						/>
					</div>
				</div>
			</>
		) : (
			<div className="fixed top-0 left-0 flex flex-col items-center justify-center w-full h-full p-0 m-0 space-y-16">
				<span
					className="flex flex-row items-center justify-center"
					style={{ color: "#34588d" }}
				>
					<span className="rounded-full w-28 h-28 bg-cyan-50 ring-2 ring-cyan-100">
						<img
							src={logoAlbarans}
							alt="logo"
							className="w-28"
							loading="lazy"
						/>
					</span>
					<BiLink size="52" className="mx-4" />
					<span className="rounded-full w-28 h-28 bg-amber-50 ring-2 ring-amber-100">
						<img src={logoZammad} alt="logo" className="w-28" loading="lazy" />
					</span>
				</span>
				<span
					className="flex items-center justify-center animate-bounce"
					style={{ color: "#34588d" }}
				>
					<FiLoader size="80" className="animate-spin text-cyan-900" />
				</span>
			</div>
		)
	) : (
		<div className="fixed top-0 left-0 flex flex-col items-center justify-center w-full h-full p-0 m-0 space-y-16">
			<span
				className="flex flex-row items-center justify-center"
				style={{ color: "#34588d" }}
			>
				<span className="rounded-full w-28 h-28 bg-cyan-50 ring-2 ring-cyan-100">
					<img src={logoAlbarans} alt="logo" className="w-28" loading="lazy" />
				</span>
				<BiLink size="52" className="mx-4" />
				<span className="rounded-full w-28 h-28 bg-amber-50 ring-2 ring-amber-100">
					<img src={logoZammad} alt="logo" className="w-28" loading="lazy" />
				</span>
			</span>
			<span className="flex items-center justify-center h-20 text-lg font-bold text-red-900 animate-pulse font-ms">
				Aquest ticket no existeix, redirigint a Zammad en {getErrorSeconds} s...
			</span>
		</div>
	);
};

export default NewAlbaraZammad;

import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Form, Button, Container, Row, Col, InputGroup } from "react-bootstrap";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import Select from "react-select";

import { useToasts } from "react-toast-notifications";

import "react-datepicker/dist/react-datepicker.css";

import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";


import ca from "date-fns/locale/ca";
registerLocale("ca", ca);
setDefaultLocale("ca");

const TicketRecurrentForm = ({
  action,
  ticketRecurrent,
  setTicketRecurrent,
  handleCancel,
  handleSubmit,
}) => {
  const { addToast } = useToasts();

  // STATES
  const [empreses, setEmpreses] = useState();
  const [serveis, setServeis] = useState();
  const [subServeis, setSubServeis] = useState();
  const [tecnics, setTecnics] = useState();
  const [grups, setGrups] = useState();

  // Dades seleccionades amb dependencies
  const [empresaSeleccionada, setEmpresaSeleccionada] = useState();

  // CONSTANTS
  const frequencies = [
    {
      value: "anual",
      label: "Anual",
    },
    {
      value: "mensual",
      label: "Mensual",
    },
    {
      value: "setmanal",
      label: "Setmanal",
    },
    {
      value: "diaria",
      label: "Diària",
    },
  ];
  // console.log(ticketRecurrent);

  useEffect(() => {
    // SI ES MODIFICAR TICKET RECURRENT, CARREGUEM TOTS ELS HANDLES
    if (action === "edit" && ticketRecurrent) {
      handleClient({
        value: ticketRecurrent.idClient,
        label: ticketRecurrent.client,
      });

      handleServei({
        value: ticketRecurrent.idServei,
        label: ticketRecurrent.servei,
      });
    }
    // PETICIÓ API EMPRESES
    const apiEmpreses = axios.get(
      process.env.REACT_APP_API_URL + "api/empreses/",
      {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      }
    );

    // PETICIÓ API SERVEIS
    const apiServeis = axios.get(
      process.env.REACT_APP_API_URL + "api/serveis/",
      {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      }
    );

    // PETICIÓ API TECNICS
    const apiTecnics = axios.get(
      process.env.REACT_APP_API_URL + "api/tecnics/",
      {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      }
    );

    // PETICIÓ API GRUPS
    const apiGrups = axios.get(process.env.REACT_APP_API_URL + "api/grups/", {
      auth: {
        username: process.env.REACT_APP_API_USER,
        password: process.env.REACT_APP_API_PWD,
      },
      headers: {
        token: JSON.parse(localStorage.getItem("userLogged")).token,
        idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
      },
    });

    axios
      .all([apiEmpreses, apiServeis, apiTecnics, apiGrups])
      .then(
        axios.spread((...responses) => {
          const responseEmpreses = responses[0].data;
          const responseServeis = responses[1].data;
          const responseTecnics = responses[2].data;
          const responseGrups = responses[3].data;

          /* EMPRESES SELECT OPTIONS */
          const empresesList = [];
          responseEmpreses.forEach((empresa) => {
            empresesList.push({
              value: empresa.id,
              label: empresa.nom,
            });
          });
          setEmpreses(empresesList);

          /* SERVEIS SELECT OPTIONS */
          const serveisList = [];
          responseServeis.forEach((servei) => {
            serveisList.push({
              value: servei.id,
              label: servei.nom,
            });
          });
          setServeis(serveisList);

          /* TECNICS SELECT OPTIONS */
          const tecnicsList = [
            {
              value: "null",
              label: "Sense assignar",
            },
          ];
          responseTecnics.forEach((tecnic) => {
            tecnicsList.push({
              value: tecnic.id,
              label: tecnic.nom,
            });
          });
          setTecnics(tecnicsList);

          /* GRUPS SELECT OPTIONS */
          const grupsList = [];
          responseGrups.forEach((grup) => {
            grupsList.push({
              value: grup.id,
              label: grup.nom,
            });
          });
          setGrups(grupsList);
        })
      )
      .catch((errors) => {
        // TOAST ERROR
        addToast("S'ha produït un error", {
          appearance: "error",
        });
      });
    // eslint-disable-next-line
  }, []);

  // HANDLE SELECCIONAR CLIENT
  const handleClient = (client) => {
    // Carreguem les dades de l'empresa client seleccionada
    getDadesEmpresa(client.value);

    setTicketRecurrent({
      ...ticketRecurrent,
      idClient: client.value,
    });
  };

  // HANDLE SELECCIONAR SERVEI
  const handleServei = (servei) => {
    getDadesServei(servei.value);

    setTicketRecurrent({
      ...ticketRecurrent,
      idServei: servei.value,
    });
  };

  // HANDLE SELECCIONAR SUBSERVEI
  const handleSubServei = (subServei) => {
    setTicketRecurrent({
      ...ticketRecurrent,
      idSubServei: subServei.value,
    });
  };

  // HANDLE SELECCIONAR FREQUENCIA (Guardem quin interval s'ha seleccionat, anual, mensual, setmanal, diari...)
  const handleFrequencia = (frequencia) => {
    setTicketRecurrent({
      ...ticketRecurrent,
      intervalFrequencia: frequencia.value,
      frequencia: [],
    });
  };

  const handleSetmanal = (checkbox) => {
    const id = checkbox.target.id;
    const index = ticketRecurrent.frequencia.indexOf(id);

    // Si s'activa el checkbox
    if (checkbox.target.checked === true) {
      if (index <= -1) {
        setTicketRecurrent({
          ...ticketRecurrent,
          frequencia: ticketRecurrent.frequencia.concat(id),
        });
      }
    } else {
      // Si es desmarca el checkbox
      if (index > -1) {
        setTicketRecurrent({
          ...ticketRecurrent,
          frequencia: ticketRecurrent.frequencia.filter((e) => e !== id),
        });
      }
    }
  };
  // HANDLE SELECCIONAR ASSIGNAT
  const handleAssignat = (assignat) => {
    setTicketRecurrent({
      ...ticketRecurrent,
      idAssignat: assignat.value,
    });
  };
  // HANDLE SELECCIONAR GRUP
  const handleGrup = (grup) => {
    setTicketRecurrent({
      ...ticketRecurrent,
      idGrup: grup.value,
    });
  };

  const getDadesEmpresa = (idClient) => {
    // PETICIÓ API EMPRESES
    axios
      .get(`${process.env.REACT_APP_API_URL}api/empresa/${idClient}/`, {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      })
      .then((res) => {
        // Guardar dades de l'Empresa Seleccionada (Responsable, Contacte, Telefon...)
        setEmpresaSeleccionada(res.data);
      });
  };

  const getDadesServei = (idServei) => {
    // Guardem les dades de serveis/subserveis dins del estat
    axios
      .get(`${process.env.REACT_APP_API_URL}api/servei/${idServei}/`, {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      })
      .then((res) => {
        // Carreguem la llista de serveis per a buscar els subserveis
        // Guardem els subserveis del servei seleccionat
        const itemList = [];
        res.data.subserveis.forEach((subServei) => {
          itemList.push({
            value: subServei.id,
            label: subServei.nom,
          });
        });
        setSubServeis(itemList);
      });
  };
  // CALCUL DELS OPTIONS DEL SELECT DE MINUTS
  const minutsList = () => {
    // Numero de options que hi hauran al select
    const numMinuts = 49;
    const minutsArray = [];
    for (let i = 0; i < numMinuts; i++) {
      // Calculem els minuts
      const minuts = 30 * i;
      // Calculem les hores
      const hores = minuts / 60;

      // Afegim el option dins de l'array
      minutsArray.push(
        <option value={minuts} key={minuts}>
          {minuts} min ({hores} h)
        </option>
      );
    }
    return minutsArray;
  };

  // VALIDAR CAMPS
  const validarCamps = () => {
    let campsValidats = true;
    // eslint-disable-next-line
    Object.keys(ticketRecurrent).map((key) => {
      // EXCLUIM ELS CAMPS DADES, MINUTS I TEXTALBARA JA QUE NO SON OBLIGATORIS
      if (
        key !== "dades" &&
        key !== "minuts" &&
        key !== "textAlbara" &&
        key !== "url_atera" &&
        key !== "url_jedi"
      ) {
        if (ticketRecurrent[key] === "" || ticketRecurrent[key] === 0) {
          console.log(key);
          campsValidats = false;
        }
      }
    });

    if (campsValidats) {
      handleSubmit();
    } else {
      console.log("ERROR");
      addToast(
        "Error al creat tiquet recurrent. Revisa que tots els camps estiguin plens.",
        {
          appearance: "error",
        }
      );
    }
  };

  const date = new Date();
  return (
    <Form>
      <Container>
        <Row>
          <Col sm={8}>
            {/* ********** CLIENT ********** */}
            <Form.Group controlId="exampleForm.ControlSelect1">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">
                    Client
                  </InputGroup.Text>
                </InputGroup.Prepend>
                {empreses ? (
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({ ...provided, zIndex: 9999 }),
                    }}
                    className="select"
                    value={empreses.filter(
                      (empresa) => empresa.value === ticketRecurrent.idClient
                    )}
                    onChange={handleClient}
                    options={empreses}
                    placeholder="Seleccionar client"
                  />
                ) : (
                  ""
                )}
              </InputGroup>
            </Form.Group>

            {/* ********** ASSUMPTE ********** */}
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">
                    Assumpte
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  value={ticketRecurrent.assumpte}
                  maxLength="100"
                  onChange={(e) =>
                    setTicketRecurrent({
                      ...ticketRecurrent,
                      assumpte: e.target.value,
                    })
                  }
                />
              </InputGroup>
            </Form.Group>

            {/* ********** DADES ********** */}
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <InputGroup.Prepend>
                <InputGroup.Text id="inputGroupPrepend">Dades</InputGroup.Text>
              </InputGroup.Prepend>
              <CKEditor
                editor={ClassicEditor}
                data={ticketRecurrent.dades ? ticketRecurrent.dades : ""}
                onInit={(editor) => {
                  editor.editing.view.focus();
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setTicketRecurrent({ ...ticketRecurrent, dades: data });
                }}
                config={{
                  removePlugins: ["Heading"],
                  ckfinder: {
                    uploadUrl: `${process.env.REACT_APP_API_URL}ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images&responseType=json`,
                  },
                }}
              />
            </Form.Group>

            {/* ********** MINUTS A FACTURAR ********** */}
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">
                    Minuts a facturar
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  as="select"
                  value={ticketRecurrent.minuts}
                  onChange={(e) =>
                    setTicketRecurrent({
                      ...ticketRecurrent,
                      minuts: e.target.value,
                    })
                  }
                >
                  {minutsList()}
                </Form.Control>
              </InputGroup>
            </Form.Group>

            {/* ********** TEXT ALBARÀ ********** */}
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <InputGroup.Prepend>
                <InputGroup.Text id="inputGroupPrepend">
                  Text albarà
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                as="textarea"
                rows="5"
                value={
                  ticketRecurrent.textAlbara ? ticketRecurrent.textAlbara : ""
                }
                onChange={(e) =>
                  setTicketRecurrent({
                    ...ticketRecurrent,
                    textAlbara: e.target.value,
                  })
                }
              />
            </Form.Group>

            {/* ********** BUTONS CREAR/MODIFICAR i CANCELAR ********** */}
            <div style={{ textAlign: "center", marginTop: "2rem" }}>
              <Button
                id="btnCreateRecurrentTicket"
                variant="primary"
                type="button"
                onClick={() => validarCamps()}
                className='text-white transition duration-300 hover:bg-white hover:text-blue-900 border-blue-900'
              >
                {action && action === "edit"
                  ? "Modificar ticket recurrent"
                  : "Crear ticket recurrent"}
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button variant="light" type="button" onClick={handleCancel}>
                Cancelar
              </Button>
            </div>
          </Col>
          <Col sm={4}>
            {/* ********** SERVEI ********** */}
            <Form.Group controlId="exampleForm.ControlSelect1">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">
                    Servei
                  </InputGroup.Text>
                </InputGroup.Prepend>
                {serveis ? (
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({ ...provided, zIndex: 9999 }),
                    }}
                    className="select"
                    value={serveis.filter(
                      (servei) => servei.value === ticketRecurrent.idServei
                    )}
                    onChange={handleServei}
                    options={serveis}
                    placeholder="Seleccionar servei"
                  />
                ) : (
                  ""
                )}
              </InputGroup>
            </Form.Group>

            {/* ********** SUBSERVEI ********** */}
            {subServeis ? (
              <Form.Group controlId="exampleForm.ControlSelect1">
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text id="inputGroupPrepend">
                      Sub Servei
                    </InputGroup.Text>
                  </InputGroup.Prepend>

                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({ ...provided, zIndex: 9999 }),
                    }}
                    className="select"
                    value={subServeis.filter(
                      (subServei) =>
                        subServei.value === ticketRecurrent.idSubServei
                    )}
                    onChange={handleSubServei}
                    options={subServeis}
                    placeholder="Seleccionar sub servei"
                  />
                </InputGroup>
              </Form.Group>
            ) : (
              ""
            )}

            {/* ********** RESPONSABLE ********** */}
            {empresaSeleccionada ? (
              <Form.Group controlId="exampleForm.ControlSelect1">
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text id="inputGroupPrepend">
                      Responsable
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control as="select" disabled>
                    <option>
                      {empresaSeleccionada.responsable.id === "0" ||
                        empresaSeleccionada.responsable.id === null
                        ? "Sense responsable"
                        : empresaSeleccionada.responsable.nom}
                    </option>
                  </Form.Control>
                </InputGroup>
              </Form.Group>
            ) : (
              ""
            )}

            {/* ********** GRUP ASSIGNAT ********** */}
            {empresaSeleccionada && grups ? (
              <Form.Group controlId="exampleForm.ControlSelect1">
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text id="inputGroupPrepend">
                      Grup
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({ ...provided, zIndex: 9999 }),
                    }}
                    className="select"
                    value={grups.filter(
                      (grup) => grup.value === ticketRecurrent.idGrup
                    )}
                    onChange={handleGrup}
                    options={grups}
                    placeholder="Seleccionar grup"
                  />
                </InputGroup>
              </Form.Group>
            ) : (
              ""
            )}

            {/* ********** ASSIGNAT ********** */}
            {empresaSeleccionada && tecnics ? (
              <Form.Group controlId="exampleForm.ControlSelect1">
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text id="inputGroupPrepend">
                      Assignat
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({ ...provided, zIndex: 9999 }),
                    }}
                    className="select"
                    value={tecnics.filter(
                      (tecnic) => tecnic.value === ticketRecurrent.idAssignat
                    )}
                    onChange={handleAssignat}
                    options={tecnics}
                    placeholder="Seleccionar tècnic"
                  />
                </InputGroup>
              </Form.Group>
            ) : (
              ""
            )}

            <hr />
            {/* ********** FREQUÈNCIA ********** */}
            <Form.Group controlId="exampleForm.ControlSelect1">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">
                    Freqüència
                  </InputGroup.Text>
                </InputGroup.Prepend>

                <Select
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  className="select"
                  value={frequencies.filter(
                    (frequencia) =>
                      frequencia.value === ticketRecurrent.intervalFrequencia
                  )}
                  onChange={handleFrequencia}
                  options={frequencies}
                  placeholder="Seleccionar frequencia"
                />
              </InputGroup>
            </Form.Group>

            {/* ********** CALENDARI FREQUÈNCIA ANUAL ********** */}
            {ticketRecurrent.intervalFrequencia === "anual" ? (
              <Form.Group controlId="exampleForm.ControlSelect1">
                <InputGroup>
                  <DatePicker
                    selected={new Date(ticketRecurrent.frequenciaSelected)}
                    dateFormat="dd/MM/yyyy"
                    inline
                    onChange={(date) => {
                      const frequenciaAnual = moment(date).format("DD-MM");

                      setTicketRecurrent({
                        ...ticketRecurrent,
                        frequencia: frequenciaAnual,
                        frequenciaSelected: date,
                      });
                    }}
                  />
                </InputGroup>
              </Form.Group>
            ) : (
              ""
            )}

            {/* ********** CALENDARI FREQUÈNCIA MENSUAL ********** */}
            {ticketRecurrent.intervalFrequencia === "mensual" ? (
              <Form.Group controlId="exampleForm.ControlSelect1">
                <InputGroup>
                  <DatePicker
                    selected={new Date(ticketRecurrent.frequenciaSelected)}
                    dateFormat="dd/MM/yyyy"
                    inline
                    onChange={(date) => {
                      const frequenciaMensual = moment(date).format("DD");

                      setTicketRecurrent({
                        ...ticketRecurrent,
                        frequencia: frequenciaMensual,
                        frequenciaSelected: date,
                      });
                    }}
                    minDate={new Date(date.getFullYear(), date.getMonth(), 1)}
                    maxDate={
                      new Date(date.getFullYear(), date.getMonth() + 1, 0)
                    }
                  />
                </InputGroup>
              </Form.Group>
            ) : (
              ""
            )}

            {/* ********** SELECTOR FREQUÈNCIA SETMANAL ********** */}
            {ticketRecurrent.intervalFrequencia === "setmanal" ? (
              <Form.Group
                controlId="exampleForm.ControlCheckbox"
                className="frequencia-setmanal"
              >
                <div style={{ display: "inline-block" }}>
                  <input
                    id="1"
                    className="checkbox-custom dilluns"
                    name="checkbox-dilluns"
                    checked={
                      ticketRecurrent.frequencia.indexOf("1") !== -1
                        ? "checked"
                        : ""
                    }
                    type="checkbox"
                    onChange={handleSetmanal}
                  />
                  <label htmlFor="1" className="checkbox-custom-label"></label>
                </div>
                <div style={{ display: "inline-block" }}>
                  <input
                    id="2"
                    className="checkbox-custom dimarts"
                    name="checkbox-dimarts"
                    checked={
                      ticketRecurrent.frequencia.indexOf("2") !== -1
                        ? "checked"
                        : ""
                    }
                    type="checkbox"
                    onChange={handleSetmanal}
                  />
                  <label htmlFor="2" className="checkbox-custom-label"></label>
                </div>
                <div style={{ display: "inline-block" }}>
                  <input
                    id="3"
                    className="checkbox-custom dimecres"
                    name="checkbox-dimecres"
                    checked={
                      ticketRecurrent.frequencia.indexOf("3") !== -1
                        ? "checked"
                        : ""
                    }
                    type="checkbox"
                    onChange={handleSetmanal}
                  />
                  <label htmlFor="3" className="checkbox-custom-label"></label>
                </div>
                <div style={{ display: "inline-block" }}>
                  <input
                    id="4"
                    className="checkbox-custom dijous"
                    name="checkbox-dijous"
                    checked={
                      ticketRecurrent.frequencia.indexOf("4") !== -1
                        ? "checked"
                        : ""
                    }
                    type="checkbox"
                    onChange={handleSetmanal}
                  />
                  <label htmlFor="4" className="checkbox-custom-label"></label>
                </div>
                <div style={{ display: "inline-block" }}>
                  <input
                    id="5"
                    className="checkbox-custom divendres"
                    name="checkbox-divendres"
                    checked={
                      ticketRecurrent.frequencia.indexOf("5") !== -1
                        ? "checked"
                        : ""
                    }
                    type="checkbox"
                    onChange={handleSetmanal}
                  />
                  <label htmlFor="5" className="checkbox-custom-label"></label>
                </div>
                <div style={{ display: "inline-block" }}>
                  <input
                    id="6"
                    className="checkbox-custom dissabte"
                    name="checkbox-dissabte"
                    checked={
                      ticketRecurrent.frequencia.indexOf("6") !== -1
                        ? "checked"
                        : ""
                    }
                    type="checkbox"
                    onChange={handleSetmanal}
                  />
                  <label htmlFor="6" className="checkbox-custom-label"></label>
                </div>
                <div style={{ display: "inline-block" }}>
                  <input
                    id="7"
                    className="checkbox-custom diumenge"
                    name="checkbox-diumenge"
                    checked={
                      ticketRecurrent.frequencia.indexOf("7") !== -1
                        ? "checked"
                        : ""
                    }
                    type="checkbox"
                    onChange={handleSetmanal}
                  />
                  <label htmlFor="7" className="checkbox-custom-label"></label>
                </div>
              </Form.Group>
            ) : (
              ""
            )}

            {/* ********** SELECTOR FREQUÈNCIA DIARIA ********** */}
            {ticketRecurrent.intervalFrequencia === "diaria" ? (
              <Form.Group controlId="exampleForm.ControlSelect1">
                Cada{" "}
                <Form.Control
                  type="text"
                  style={{ width: "60px", display: "inline-block" }}
                  maxLength="3"
                  value={ticketRecurrent.frequencia}
                  onChange={(e) =>
                    setTicketRecurrent({
                      ...ticketRecurrent,
                      frequencia: e.target.value,
                    })
                  }
                />{" "}
                dies
              </Form.Group>
            ) : (
              ""
            )}
            <hr />

            {/* ********** DATA INICI ********** */}
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">
                    Data inici
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <DatePicker
                  onChange={(date) => {
                    const dataInici = moment(date).format("X");

                    setTicketRecurrent({
                      ...ticketRecurrent,
                      dataInici: dataInici,
                    });
                  }}
                  dateFormat="dd/MM/yyyy"
                  placeholderText=""
                  className="form-control"
                  selected={new Date(ticketRecurrent.dataInici * 1000)}
                />
              </InputGroup>
            </Form.Group>

            {/* ********** CADUCITAT ********** */}
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">
                    Caducitat
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <DatePicker
                  onChange={(date) => {
                    const dataCaducitat = moment(date).format("X");

                    setTicketRecurrent({
                      ...ticketRecurrent,
                      dataCaducitat: dataCaducitat,
                    });
                  }}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Opcional"
                  minDate={new Date()}
                  className="form-control"
                  selected={
                    ticketRecurrent.dataCaducitat
                      ? new Date(ticketRecurrent.dataCaducitat * 1000)
                      : ""
                  }
                />
              </InputGroup>
            </Form.Group>

            {/* ********** ESTAT ********** */}
            <Form.Group controlId="exampleForm.ControlSelect1">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">
                    Estat
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  as="select"
                  onChange={(e) =>
                    setTicketRecurrent({
                      ...ticketRecurrent,
                      estat: e.target.value,
                    })
                  }
                  value={ticketRecurrent.estat}
                >
                  <option value="obert">Obert</option>
                  <option value="congelat">Congelat</option>
                  <option value="no_responsable">No responsable</option>
                  <option value="tancat">Tancat</option>
                </Form.Control>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

export default TicketRecurrentForm;

import React from "react";
import styles from "./Title.module.css";
import Button from "../Button/Button";
import { SplitButton, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

export default ({
  title,
  titleLink,
  title2,
  title2Link,
  title3,
  button,
  buttonAction,
  button2,
  buttonAction2,
  splitButton,
  splitButtonAction,
}) => {
  return (
    <div className={` hidden lg:block ${styles.pageHeader}`}>
      <h1 className={styles.title}>
        {/* TITUL 1 (AMB LINK) */}
        {title && titleLink ? (
          <Link to={titleLink}>{title}</Link>
        ) : title || (
          ""
        )}
        {/* TITUL 2 (AMB LINK O SENSE) */}
        {title2 ? " » " : ""}
        {title2 && title2Link ? (
          <Link to={title2Link}>{title2}</Link>
        ) : title2 || (
          ""
        )}
        {/* TITUL 3 (SENSE LINK) */}
        {title3 ? ` » ${title3}` : ""}
      </h1>
      <div>
        {splitButton ? (
          <div className={styles.titleButton}>
            <SplitButton
              key={button}
              id={`dropdown-split-variants-primary`}
              variant="primary"
              title={button}
              onClick={buttonAction}
              style={{ marginLeft: "1rem" }}
            >
              <Dropdown.Item eventKey="1" onClick={splitButtonAction}>
                Enviar email
              </Dropdown.Item>
            </SplitButton>
          </div>
        ) : button ? (
          <div className={styles.titleButton} style={{ marginLeft: "1rem" }}>
            <Button type="primary" buttonAction={buttonAction}>
              {button}
            </Button>
          </div>
        ) : (
          ""
        )}

        {button2 ? (
          <div className={styles.titleButton}>
            <Button type="secondary" buttonAction={buttonAction2}>
              {button2}
            </Button>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

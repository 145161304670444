const ClientsStats = ({data}) => {
    
    return (
        <div className=' rounded px-12 border-l border-white w-full  py-4 text-white '>
            <div className='flex justify-between'>

            <span className='font-bold text-xl'>Nº tickets</span>
            <span className='text-sm'>(últims 30 dies)</span>
            </div>
            {data.map(({nom, tiquets, idClient}) => (
                <div className='flex border-b border-blue-800  justify-between my-1 items-center ' key={idClient}>
                    <span className='2xl:text-xl'>{nom}</span>
                    <span className='font-bold ml-2  text-2xl text-yellow-300'>{tiquets}</span>

                </div>
            ))}
        </div>
    )
}

export default ClientsStats

import React, { useState } from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";

import { useToasts } from "react-toast-notifications";

import AnswerForm from "../../../../../forms/AnswerForm";
import axios from "axios";

const Actions = ({ ticket, setTicket }) => {
  const { addToast } = useToasts();

  const [isResposta, setIsResposta] = useState(0);
  const [resposta, setResposta] = useState({
    idTiquet: ticket.id,
    isClient: "0",
    idEmissor: JSON.parse(localStorage.getItem("userLogged")).id,
    correuReceptor: "", // ticket.ccInvolucrats,
    cc: ticket.cc,
    bcc: ticket.bcc,
    minutsDedicats: "",
    horesDedicades: "",
    text: "",
    tipus: "",
  });
  // console.log(resposta);
  const [adjunts, setAdjunts] = useState([]);

  const handleClick = (tipusResposta) => {
    // Guardem el tipus de resposta depenent del botó que s'ha clicat
    setResposta({ ...resposta, tipus: tipusResposta, correuReceptor: "" });

    // Canviem el booleà per mostrar el textarea amb l'editor de resposta
    setIsResposta(1);
  };

  // Penjar adjunts
  const uploadAdjunts = (idResposta) => {
    if (adjunts.length === 0) return
    const formData = new FormData();

    for (let i = 0; i <= adjunts.length; i++) {
      if (adjunts[i]) {
        formData.append("adjunts[]", adjunts[i]);
      }
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/resposta/${idResposta}/adjunts/`,
        formData,
        {
          auth: {
            username: process.env.REACT_APP_API_USER,
            password: process.env.REACT_APP_API_PWD,
          },
          headers: {
            token: JSON.parse(localStorage.getItem("userLogged")).token,
            idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        // Anem fins al final de la pàgina
        // console.log(res);
        window.scrollTo(0, document.body.scrollHeight);
        /*  window.location.reload(); */

      })
      .catch((error) => {
        console.log(error);
      });

    setResposta({
      ...resposta,
      adjunts: formData,
    });
  };

  const handleSubmit = (e) => {
    try {
      document.getElementById("btnRespondreTicketAClient").disabled = true
    } catch (error) {

    }
    e.preventDefault();
    if (resposta.horesDedicades === "" && resposta.minutsDedicats === "") {
      handleShow();
    } else {
      saveResposta();
    }
  };

  const saveResposta = () => {
    try {
      document.getElementById("btnCrearNota").disabled = true

    } catch (error) {

    }
    // Tanquem modal (si es que està obert)
    handleClose();
    try {
      document.getElementById("btnRespondreTicketAClient").disabled = true

    } catch (error) {

    }
    // Insertem la nova resposta a la base de dades (amb les dades del formulari al state)
    const formData = new FormData();

    formData.append("idTiquet", resposta.idTiquet)
    formData.append("isClient", resposta.isClient)
    formData.append("idEmissor", resposta.idEmissor)
    formData.append("correuReceptor", JSON.stringify(resposta.correuReceptor))
    formData.append("cc", JSON.stringify(resposta.cc))
    formData.append("bcc", JSON.stringify(resposta.bcc))
    formData.append("minutsDedicats", resposta.minutsDedicats)
    formData.append("horesDedicades", resposta.horesDedicades)
    formData.append("text", resposta.text)
    formData.append("tipus", resposta.tipus)
    for (let i = 0; i <= adjunts.length; i++) {
      if (adjunts[i]) {
        formData.append("adjunts[]", adjunts[i]);
      }
    }


    axios
      .post(
        process.env.REACT_APP_API_URL + "api/resposta/", formData,
        {
          auth: {
            username: process.env.REACT_APP_API_USER,
            password: process.env.REACT_APP_API_PWD,
          },
          headers: {
            token: JSON.parse(localStorage.getItem("userLogged")).token,
            idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
            "content-type": "multipart/form-data",

          },
        }
      )
      .then((res) => {
        const respostaCreada = res.data;
        // console.log(respostaCreada);

        // Afegim la nova resposta a les respostes actuals del estat del tiquet
        const novaResposta = { ...ticket };
        novaResposta.respostes.push(respostaCreada);
        // Guardem estat resposta
        setTicket(novaResposta);

        // Tanquem resposta
        setIsResposta(!isResposta);

        // TOAST RESPOSTA OK
        addToast("Resposta creada correctament", {
          appearance: "success",
          autoDismiss: true,
        });

        // Resetejem les hores i minuts
        setResposta({
          ...resposta,
          horesDedicades: "",
          minutsDedicats: "",
        });

        // Si existeixen arxius adjunts, penjar arxius
        if (adjunts) {
          uploadAdjunts(res.data.id);
        } else {
          // Anem fins al final de la pàgina
          window.scrollTo(0, document.body.scrollHeight);
        }
      })
      .catch((error) => {
        // TOAST ERROR
        try {
          document.getElementById("btnRespondreTicketAClient").disabled = false
          document.getElementById("btnCrearNota").disabled = false
        } catch (error) {

        }


        addToast("S'ha produït un error", {
          appearance: "error",
        });
        console.log(error);
      });
  };

  const handleCancel = () => {
    setIsResposta(!isResposta);
  };

  /* MODAL */
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    document.getElementById("btnRespondreTicketAClient").disabled = false
  }
  const handleShow = () => setShow(true);

  return (
    <>
      {/* MODAL CREAR NOTA SENSE TEMPS INVERTIT */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Afegir nota sense temps invertit</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          <p>Vols crear una nota sense temps invertit?</p>
          {/* <Button variant="secondary" onClick={handleGenerarAlbara}> */}
          <Button variant="secondary" onClick={saveResposta} id="btnCrearNota">
            Si, crear nota
          </Button>
          &nbsp;
          {/* <Button variant="primary" onClick={handleTancarTiquet}> */}
          <Button variant="primary" onClick={handleClose}>
            No, cancelar
          </Button>
        </Modal.Body>
      </Modal>

      <Container
        style={{
          backgroundColor: "#f8f9fa",
          padding: 12,
          marginTop: 10,
          border: "1px solid #ced9e8",
        }}
      >
        <Row style={{ color: "#34588d" }}>
          <Col
            sm={4}
            style={{ cursor: "pointer" }}
            onClick={() => handleClick(3) /* Nota */}
          >
            <i className={"fa fa-pencil-square-o"}></i>&nbsp; Afegir nota
          </Col>


          <Col
            sm={4}
            style={{ cursor: "pointer" }}
            onClick={() => handleClick(2) /* Reenviament */}
          >
            <i className={"fa fa-envelope-o"}></i>&nbsp; Reenviar email
          </Col>
          <Col
            sm={4}
            style={{ cursor: "pointer" }}
            onClick={() => handleClick(1) /* Resposta */}
          >
            <i className={"fa fa-reply"}></i>&nbsp; Respondre ticket a client
          </Col>
        </Row>
      </Container>
      {isResposta ? (
        <>
          <AnswerForm
            resposta={resposta}
            setResposta={setResposta}
            handleCancel={handleCancel}
            handleSubmit={handleSubmit}
            adjunts={adjunts}
            ticket={ticket}
            setAdjunts={setAdjunts}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default Actions;

import React from "react";
import { Form, Button, Container, Row, Col, InputGroup } from "react-bootstrap";
import { BiLink } from "react-icons/bi";
import logoGestinet from "../assets/images/logo-gestinet.png";
import logoZammad from "../assets/images/logo-zammad-big.png";

const LoginFormZammad = ({ login, setLogin, handleSubmit }) => {
	return (
		<Form className="login bg-gradient-to-r from-cyan-900 to-amber-900">
			<Container className="border-2 border-black">
				<div
					className="flex flex-row items-center justify-center pt-2 pb-5"
					style={{ color: "#34588d" }}
				>
					<img src={logoGestinet} alt="Gestinet" className="w-64" />

					<BiLink size="44" className="mx-4" />

					<img src={logoZammad} alt="Zammad" className="w-64" />
				</div>

				<Row>
					<Col sm={12}>
						<Form.Group controlId="loginForm.usuari">
							<InputGroup>
								<InputGroup.Prepend>
									<InputGroup.Text id="grupUsuari">Usuari</InputGroup.Text>
								</InputGroup.Prepend>
								<Form.Control
									type="text"
									value={login.usuari}
									onChange={(e) =>
										setLogin({ ...login, usuari: e.target.value })
									}
								/>
							</InputGroup>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<Form.Group controlId="loginForm.pwd">
							<InputGroup>
								<InputGroup.Prepend>
									<InputGroup.Text id="grupPwd">Contrasenya</InputGroup.Text>
								</InputGroup.Prepend>
								<Form.Control
									type="password"
									value={login.pwd}
									onChange={(e) => setLogin({ ...login, pwd: e.target.value })}
								/>
							</InputGroup>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<Button variant="primary" type="submit" onClick={handleSubmit}>
							Iniciar sessió
						</Button>
					</Col>
				</Row>
			</Container>
		</Form>
	);
};

export default LoginFormZammad;

import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import MaterialListZammad from "./MaterialList/MaterialListZammad";

const ImputarMaterialZammad = ({ ticket, albara }) => {
	const { addToast } = useToasts();
	const history = useHistory();

	const [materialEntregat, setMaterialEntregat] = useState([]);

	// CRUD: POST - Creem material inputat a la base de dades i el lliguem a l'albarà que acabem de crear:
	const handleSubmit = () => {
		document.getElementById("btnEndAndSendMail").disabled = true;

		axios
			.post(
				`${process.env.REACT_APP_API_URL}api/albara/imputar/${albara}/`,
				{
					materialEntregat,
				},
				{
					auth: {
						username: process.env.REACT_APP_API_USER,
						password: process.env.REACT_APP_API_PWD,
					},
					headers: {
						token: JSON.parse(localStorage.getItem("userLogged")).token,
						idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
						// token: process.env.REACT_APP_GESTINET_API_TOKEN,
						// idOperador: process.env.REACT_APP_GESTINET_API_USER_ID,
					},
				}
			)
			.then((res) => {
				addToast("Albarà creat!", {
					appearance: "success",
					autoDismiss: true,
				});
				window.location.href = `https://zammad.gestinet.com/#ticket/zoom/${ticket}`;
			})
			.catch((error) => {
				console.log(error);
				addToast("S'ha produït un error!", {
					appearance: "error",
					autoDismiss: true,
				});
				document.getElementById("btnEndAndSendMail").disabled = false;
			});
	};

	return (
		<>
			<div
				style={{
					borderBottom: "1px solid #dee2e6",
					marginTop: "1.4rem",
					marginBottom: "1rem",
					overflow: "hidden",
					width: "100%",
				}}
			>
				<h1
					style={{
						color: "#34588d",
						fontSize: "1.75rem",
						float: "left",
						fontWeight: "600",
						marginBottom: "15px",
					}}
				>
					<a
						href={`https://zammad.gestinet.com/#ticket/view/my_assigned`}
						target="_self"
						className="hover:underline"
					>
						Tiquets Zammad
					</a>
					{" » "}
					<a
						href={`https://zammad.gestinet.com/#ticket/zoom/${ticket}`}
						target="_self"
						className="hover:underline"
					>
						#{ticket}
					</a>
					{` » Albarà #${albara} » Imputar material`}
				</h1>
			</div>

			<h2>Material imputat no entregat</h2>
			<MaterialListZammad
				material="imputat"
				albara={albara}
				materialEntregat={materialEntregat}
				setMaterialEntregat={setMaterialEntregat}
			/>

			<h2>Material genèric</h2>
			<MaterialListZammad
				material="generic"
				materialEntregat={materialEntregat}
				setMaterialEntregat={setMaterialEntregat}
			/>

			<h2>Material entregat</h2>
			<MaterialListZammad
				material="entregat"
				materialEntregat={materialEntregat}
				setMaterialEntregat={setMaterialEntregat}
			/>

			<div style={{ textAlign: "center", marginTop: "30px" }}>
				<Button
					id="btnEndAndSendMail"
					variant="primary"
					type="submit"
					onClick={handleSubmit}
				>
					Finalitzar i enviar email al client
				</Button>
			</div>
		</>
	);
};

export default ImputarMaterialZammad;

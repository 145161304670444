import React from "react";
import styles from "../../Sidebar.module.css";
import { Link } from "react-router-dom";

export default ({ item }) => {
  return (
    <li className={styles.sidebarList}>
      {item.title === "Tickets" ? (
        <Link to={item.url} title={item.title}>
          <i className={`fa fa-${item.icon}`} style={{ width: "25px" }}></i>
          {/* item.title */}
        </Link>
      ) : (
        <a
          href={item.url}
          target="_BLANK"
          rel="noopener noreferrer"
          title={item.title}
        >
          <i className={`fa fa-${item.icon}`} style={{ width: "25px" }}></i>
          {/* item.title */}
        </a>
      )}
    </li>
  );
};

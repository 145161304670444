import React from "react";
import { useHistory } from "react-router-dom";
import Title from "../../components/shared/Title/Title";

import TicketRecurrentList from "../../components/Tickets/TicketRecurrentList/TicketRecurrentList";

const TicketsRecurrents = () => {
  const history = useHistory();

  const handleClickRecurrent = () => {
    history.push("/nou-ticket-recurrent");
  };

  return (
    <>
      <Title
        button="Crear ticket recurrent"
        buttonAction={handleClickRecurrent}
        title="Tickets"
        titleLink="/tickets"
        title2="Tickets recurrents"
      />
      <TicketRecurrentList />
    </>
  );
};

export default TicketsRecurrents;

import React from "react";
import Button from "react-bootstrap/Button";

export default ({ buttonAction, type, children }) => {
  return (
    <Button variant={type} onClick={buttonAction}>
      {children}
    </Button>
  );
};

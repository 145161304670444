import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import moment from "moment";
import { Row, Col ,
  Form,
  Button,
  InputGroup,
  Badge,
  Container,
  Modal,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import { useToasts } from "react-toast-notifications";


import Moment from "react-moment";
import Select from "react-select";

export default ({
  ticket,
  setTicket,
  handleSubmit,
  editSidebar,
  setEditSidebar,
}) => {
  const { addToast } = useToasts();

  const firstUpdate = useRef(true);
  const history = useHistory();

  const [serveis, setServeis] = useState();
  const [subServeis, setSubServeis] = useState();
  const [tecnics, setTecnics] = useState();
  const [involucrats, setInvolucrats] = useState();
  const [estats, setEstats] = useState();
  const [prioritats, setPrioritats] = useState();

  const [showEditDataLimit, setShowEditDataLimit] = useState(false);
  const [showEditDataPlanificacio, setShowEditDataPlanificacio] = useState(
    false
  );
  const [showEditPrioritat, setShowEditPrioritat] = useState(false);

  /* HANDLE EDIT DATA LIMIT I PRIORITAT AMB LLAPIS DE MODIFICAR */
  const handleEditDataLimit = () => {
    setShowEditDataLimit(!showEditDataLimit);
    if (showEditDataLimit) setEditSidebar(true);
  };
  const handleEditDataPlanificacio = () => {
    setShowEditDataPlanificacio(!showEditDataPlanificacio);
    if (showEditDataPlanificacio) setEditSidebar(true);
  };
  const handleEditPrioritat = () => {
    setShowEditPrioritat(!showEditPrioritat);
    if (showEditPrioritat) setEditSidebar(true);
  };

  useEffect(() => {
    // PETICIÓ API SERVEIS
    const apiServeis = axios.get(
      process.env.REACT_APP_API_URL + "api/serveis/",
      {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      }
    );

    // PETICIÓ API SUB SERVEIS
    const apiSubServeis = axios.get(
      `${process.env.REACT_APP_API_URL}api/servei/${ticket.idServei}/`,
      {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      }
    );

    // PETICIO API TECNICS
    const apiTecnics = axios.get(
      process.env.REACT_APP_API_URL + "api/tecnics/",
      {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      }
    );

    // ESTATS
    const apiEstats = axios.get(process.env.REACT_APP_API_URL + "api/estats/", {
      auth: {
        username: process.env.REACT_APP_API_USER,
        password: process.env.REACT_APP_API_PWD,
      },
      headers: {
        token: JSON.parse(localStorage.getItem("userLogged")).token,
        idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
      },
    });

    // PRIORITATS
    const apiPrioritats = axios.get(
      process.env.REACT_APP_API_URL + "api/prioritats/",
      {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      }
    );

    axios
      .all([apiServeis, apiSubServeis, apiTecnics, apiEstats, apiPrioritats])
      .then(
        axios.spread((...responses) => {
          // console.log(responses);
          const responseServeis = responses[0].data;
          const responseSubServeis = responses[1].data;
          const responseTecnics = responses[2].data;
          const responseEstats = responses[3].data;
          const responsePrioritats = responses[4].data;

          /* SERVEIS SELECT OPTIONS */
          const serveisList = [];
          responseServeis.forEach((servei) => {
            serveisList.push({
              value: servei.id,
              label: servei.nom,
            });
          });
          setServeis(serveisList);

          /* SUBSERVEIS SELECT OPTIONS */
          const subServeisList = [];
          responseSubServeis.subserveis.forEach((subServei) => {
            subServeisList.push({
              value: subServei.id,
              label: subServei.nom,
            });
          });
          setSubServeis(subServeisList);

          /* TECNICS + INVOLUCRATS SELECT OPTIONS */
          const tecnicsList = [];
          tecnicsList.push({
            value: null,
            label: "Sense assignar",
            foto: null,
          });
          responseTecnics.forEach((tecnic) => {
            tecnicsList.push({
              value: tecnic.id,
              label: tecnic.nom,
              foto: tecnic.foto,
            });
          });
          setTecnics(tecnicsList);

          const involucratsList = [];
          responseTecnics.forEach((involucrat) => {
            involucratsList.push({
              value: involucrat.id,
              label: involucrat.nom,
            });
          });
          setInvolucrats(involucratsList);

          /* ESTATS SELECT OPTIONS */
          const estatsList = [];
          responseEstats.forEach((estat) => {
            estatsList.push({
              value: estat.id,
              label: estat.nom,
            });
          });
          setEstats(estatsList);

          /* PRIORITATS SELECT OPTIONS */
          const prioritatsList = [];
          responsePrioritats.forEach((prioritat) => {
            prioritatsList.push({
              value: prioritat.id,
              label: prioritat.nom,
            });
          });
          setPrioritats(prioritatsList);
        })
      )
      .catch((errors) => {
        console.log(errors);
        // TOAST ERROR
        addToast("S'ha produït un error", {
          appearance: "error",
        });
      });
    // eslint-disable-next-line
  }, []);

  // Al modificar qualsevol propietat del ticket dins del sidebar, fer submit de les dades
  useEffect(() => {
    // Si es la primera vegada que entra, no fer res
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (editSidebar === true) {
      handleSubmit();
    }

    // eslint-disable-next-line
  }, [editSidebar]);

  // HANDLE SELECCIONAR SERVEI
  const handleServei = (servei) => {
    getDadesServei(servei.value);

    setTicket({
      ...ticket,
      idServei: servei.value,
    });

    setEditSidebar(true);
  };

  // HANDLE SELECCIONAR SUBSERVEI
  const handleSubServei = (subServei) => {
    setTicket({
      ...ticket,
      idSubServei: subServei.value,
    });
    setEditSidebar(true);
  };

  // HANDLE SELECCIONAR ASSIGNAT
  const handleAssignat = (assignat) => {
    // console.log(assignat);
    setTicket({
      ...ticket,
      idAssignat: assignat.value,
      fotoAssignat: assignat.foto,
    });
    setEditSidebar(true);
  };
  // HANDLE SELECCIONAR INVOLUCRATS
  const handleInvolucrats = (involucrats) => {
    // console.log(involucrats);
    setTicket({
      ...ticket,
      involucrats: involucrats,
    });
    // console.log(ticket);
    setEditSidebar(true);
  };
  // HANDLE SELECCIONAR ESTAT
  const handleEstat = (estat) => {
    setTicket({
      ...ticket,
      idEstat: estat.value,
    });
    setEditSidebar(true);
  };

  const getDadesServei = (idServei) => {
    // Guardem les dades de serveis/subserveis dins del estat
    axios
      .get(`${process.env.REACT_APP_API_URL}api/servei/${idServei}/`, {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      })
      .then((res) => {
        // Carreguem la llista de serveis per a buscar els subserveis
        // Guardem els subserveis del servei seleccionat
        const itemList = [];
        res.data.subserveis.forEach((subServei) => {
          itemList.push({
            value: subServei.id,
            label: subServei.nom,
          });
        });
        setSubServeis(itemList);
      });
  };

  const handleGenerarAlbara = () => {
    history.push(`/ticket/${ticket.id}/nou-albara`);
  };

  const handleTancarTiquet = () => {
    const ticketEstat = { idEstat: 4 };
    axios
      .put(
        `${process.env.REACT_APP_API_URL}api/tiquet/${ticket.id}/`,
        { ticket: ticketEstat },
        {
          auth: {
            username: process.env.REACT_APP_API_USER,
            password: process.env.REACT_APP_API_PWD,
          },
          headers: {
            token: JSON.parse(localStorage.getItem("userLogged")).token,
            idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
          },
        }
      )
      .then((res) => {
        // Redireccionem a la pàgina de tickets
        history.push(`/tickets/`);
        // TOAST OK
        addToast("Tiquet resolt correctament", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((error) => {
        console.log(error);
        // TOAST ERROR
        addToast("S'ha produït un error", {
          appearance: "error",
        });
      });
  };

  /* MODAL */
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // console.log(ticket);
  return (
    <>
      <Container
        style={{
          backgroundColor: "#f8f9fa",
          padding: 10,
          border: "1px solid #ced9e8",
          // position: "sticky",
          // top: "80px",
          fontSize: "0.9rem",
          // overflowY: "auto",
          // overflowX: "hidden",
        }}
      >
        {ticket.idEstat !== "4" && ticket.idClient && ticket.contracteVigent ? (
          <div align="center" style={{ marginBottom: 10 }}>
            <Button
              variant="secondary"
              type="button"
              size="sm"
              onClick={handleShow}
            >
              Resoldre i generar albarà
            </Button>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Resoldre tiquet</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ textAlign: "center" }}>
                <p>Vols generar albarà d'aquest tiquet?</p>
                <Button variant="secondary" onClick={handleGenerarAlbara}>
                  Si, generar albarà
                </Button>
                &nbsp;
                <Button variant="primary" onClick={handleTancarTiquet}>
                  No, només marcar com a Resolt
                </Button>
              </Modal.Body>
            </Modal>
          </div>
        ) : (
          ""
        )}
        {/* ticket.dataResposta ? (
          <p style={{ marginBottom: "0.75rem" }}>
            <strong>Primera resposta:</strong>{" "}
            <Badge variant="light">
              <Moment format="DD/MM/YYYY HH:mm" unix>
                {ticket.dataResposta}
              </Moment>
            </Badge>
          </p>
        ) : (
          ""
        ) */}
        {ticket.dataPlanificacio ? (
          <p style={{ marginBottom: "0.75rem" }}>
            <strong>Data planificació:</strong>{" "}
            {showEditDataPlanificacio ? (
              <div style={{ width: "55%", display: "inline-block" }}>
                <DatePicker
                  onChange={(date) => {
                    const dataPlanificacio = moment(date).format("X");

                    setTicket({
                      ...ticket,
                      dataPlanificacio: dataPlanificacio,
                    });

                    handleEditDataPlanificacio();
                  }}
                  dateFormat="dd/MM/yyyy"
                  minDate={new Date()}
                  className="form-control"
                  selected={
                    ticket.dataPlanificacio
                      ? new Date(ticket.dataPlanificacio * 1000)
                      : ""
                  }
                />
              </div>
            ) : (
              <>
                <Badge variant="primary">
                  <Moment format="DD/MM/YYYY" unix>
                    {ticket.dataPlanificacio}
                  </Moment>
                </Badge>
                &nbsp;&nbsp;
                <i
                  className="fa fa-pencil"
                  style={{ cursor: "pointer" }}
                  onClick={handleEditDataPlanificacio}
                ></i>
              </>
            )}
          </p>
        ) : (
          ""
        )}
        <p style={{ marginBottom: "0.75rem" }}>
          <strong>Data límit:</strong>{" "}
          {showEditDataLimit ? (
            <div style={{ width: "55%", display: "inline-block" }}>
              <DatePicker
                onChange={(date) => {
                  const dataCaducitat = moment(date).format("X");

                  setTicket({
                    ...ticket,
                    dataCaducitat: dataCaducitat,
                  });

                  handleEditDataLimit();
                }}
                dateFormat="dd/MM/yyyy"
                minDate={new Date()}
                className="form-control"
                selected={
                  ticket.dataCaducitat
                    ? new Date(ticket.dataCaducitat * 1000)
                    : ""
                }
              />
            </div>
          ) : (
            <>
              <Badge variant="primary">
                <Moment format="DD/MM/YYYY" unix>
                  {ticket.dataCaducitat}
                </Moment>
              </Badge>
              &nbsp;&nbsp;
              <i
                className="fa fa-pencil"
                style={{ cursor: "pointer" }}
                onClick={handleEditDataLimit}
              ></i>
            </>
          )}
        </p>
        <p style={{ marginBottom: "0.75rem" }}>
          <strong>Prioritat:</strong>{" "}
          {showEditPrioritat && prioritats ? (
            <div style={{ width: "65%", display: "inline-block" }}>
              <Select
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
                className="select"
                value={prioritats.filter(
                  (prioritat) => prioritat.value === ticket.idPrioritat
                )}
                onChange={(prioritat) => {
                  setTicket({
                    ...ticket,
                    idPrioritat: prioritat.value,
                  });

                  handleEditPrioritat();
                }}
                options={prioritats}
                placeholder="Seleccionar tècnic"
              />
            </div>
          ) : (
            <>
              <Badge
                variant={
                  ticket.idPrioritat === "1"
                    ? "danger"
                    : ticket.idPrioritat === "2"
                    ? "warning"
                    : ticket.idPrioritat === "3"
                    ? "success"
                    : ""
                }
              >
                {ticket.idPrioritat === "1"
                  ? "Alta"
                  : ticket.idPrioritat === "2"
                  ? "Mitja"
                  : ticket.idPrioritat === "3"
                  ? "Baixa"
                  : ""}
              </Badge>
              &nbsp;&nbsp;
              <i
                className="fa fa-pencil"
                style={{ cursor: "pointer" }}
                onClick={handleEditPrioritat}
              ></i>
            </>
          )}
        </p>
        <p style={{ marginBottom: "0.75rem" }}>
          <strong>Creador:</strong> {ticket.creador}
        </p>
        <strong>Assignat:</strong>
        <Form.Group
          controlId="exampleForm.ControlSelect1"
          style={{ marginBottom: "7px" }}
        >
          <InputGroup>
            {ticket.fotoAssignat ? (
              <img
                style={{
                  width: "25px",
                  border: "1px solid #ced9e8",
                  marginRight: "3px",
                  borderColor: "hsl(0,0%,80%)",
                }}
                alt={ticket.assignat}
                src={`${process.env.REACT_APP_API_URL}/sat/ct/imatges/imatges-tecnics/${ticket.fotoAssignat}`}
              />
            ) : (
              ""
            )}
            {tecnics ? (
              <Select
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                  control: (base) => ({
                    ...base,
                    minHeight: "32px",
                  }),
                  valueContainer: (value) => ({
                    ...value,
                    marginTop: "-3px",
                  }),
                  dropdownIndicator: (indicator) => ({
                    ...indicator,
                    padding: "5px",
                  }),
                  clearIndicator: (indicator) => ({
                    ...indicator,
                    padding: "5px",
                  }),
                }}
                className="select"
                value={tecnics.filter(
                  (tecnic) => tecnic.value === ticket.idAssignat
                )}
                onChange={handleAssignat}
                options={tecnics}
                placeholder="Seleccionar assignat"
              />
            ) : (
              ""
            )}
          </InputGroup>
        </Form.Group>
        <strong>Involucrats:</strong>
        <Form.Group
          controlId="exampleForm.ControlSelect1"
          style={{ marginBottom: "7px" }}
        >
          <InputGroup>
            {involucrats ? (
              <Select
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                  control: (base) => ({
                    ...base,
                    minHeight: "32px",
                  }),
                  valueContainer: (value) => ({
                    ...value,
                    marginTop: "-3px",
                  }),
                  dropdownIndicator: (indicator) => ({
                    ...indicator,
                    padding: "5px",
                  }),
                  clearIndicator: (indicator) => ({
                    ...indicator,
                    padding: "5px",
                  }),
                }}
                className="select"
                value={ticket ? ticket.involucrats : ""}
                onChange={handleInvolucrats}
                options={involucrats}
                isMulti
                placeholder="Seleccionar involucrats"
              />
            ) : (
              ""
            )}
          </InputGroup>
        </Form.Group>
        <strong>Servei:</strong>
        <Form.Group
          controlId="exampleForm.ControlSelect1"
          style={{ marginBottom: "7px" }}
        >
          <InputGroup>
            {serveis ? (
              <Select
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                  control: (base) => ({
                    ...base,
                    minHeight: "32px",
                  }),
                  valueContainer: (value) => ({
                    ...value,
                    marginTop: "-3px",
                  }),
                  dropdownIndicator: (indicator) => ({
                    ...indicator,
                    padding: "5px",
                  }),
                  clearIndicator: (indicator) => ({
                    ...indicator,
                    padding: "5px",
                  }),
                }}
                className="select"
                value={serveis.filter(
                  (servei) => servei.value === ticket.idServei
                )}
                onChange={handleServei}
                options={serveis}
                placeholder="Seleccionar servei"
              />
            ) : (
              ""
            )}
          </InputGroup>
        </Form.Group>
        <strong>Sub servei:</strong>
        <Form.Group
          controlId="exampleForm.ControlSelect1"
          style={{ marginBottom: "7px" }}
        >
          <InputGroup>
            {subServeis ? (
              <Select
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                  control: (base) => ({
                    ...base,
                    minHeight: "32px",
                  }),
                  valueContainer: (value) => ({
                    ...value,
                    marginTop: "-3px",
                  }),
                  dropdownIndicator: (indicator) => ({
                    ...indicator,
                    padding: "5px",
                  }),
                  clearIndicator: (indicator) => ({
                    ...indicator,
                    padding: "5px",
                  }),
                }}
                className="select"
                value={subServeis.filter(
                  (subServei) => subServei.value === ticket.idSubServei
                )}
                onChange={handleSubServei}
                options={subServeis}
                placeholder="Seleccionar subservei"
              />
            ) : (
              ""
            )}
          </InputGroup>
        </Form.Group>
        <strong>Estat:</strong>
        <Form.Group
          controlId="exampleForm.ControlSelect1"
          style={{ marginBottom: "7px" }}
        >
          <InputGroup>
            {estats ? (
              <Select
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,

                    overflow: "visible",
                  }),
                  control: (base) => ({
                    ...base,
                    minHeight: "32px",
                  }),
                  valueContainer: (value) => ({
                    ...value,
                    marginTop: "-3px",
                  }),
                  dropdownIndicator: (indicator) => ({
                    ...indicator,
                    padding: "5px",
                  }),
                  clearIndicator: (indicator) => ({
                    ...indicator,
                    padding: "5px",
                  }),
                }}
                className="select"
                value={estats.filter((estat) => estat.value === ticket.idEstat)}
                onChange={handleEstat}
                options={estats}
                placeholder="Seleccionar estat"
              />
            ) : (
              ""
            )}
          </InputGroup>
        </Form.Group>
        {ticket.horesDedicades || ticket.minutsDedicats ? (
          <Row>
            <Col
              sm={12}
              style={{ textAlign: "center", color: "grey", marginBottom: 10 }}
            >
              <small>
                Temps invertit:{" "}
                <strong>
                  {ticket.horesDedicades
                    ? ticket.horesDedicades === 1
                      ? ticket.horesDedicades + " hora"
                      : ticket.horesDedicades + " hores"
                    : ""}{" "}
                  {ticket.minutsDedicats
                    ? ticket.minutsDedicats + " minuts"
                    : ""}
                </strong>
              </small>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </Container>
    </>
  );
};

import React, { useEffect, useState } from "react";

import Answer from "./Answer/Answer";
import Actions from "./Actions/Actions";

import { Element } from "react-scroll";

const TicketAnswers = ({ ticket, setTicket }) => {
    // Estat per renderitzar les respostes
    const [respostes, setRespostes] = useState();
    const [ultimaResposta, setUltimaResposta] = useState();

    useEffect(() => {
        // Recorre totes les respostes
        const itemList = [];

        // Obtenim ultima resposta
        const lastKey = Object.keys(ticket.respostes).sort().reverse()[0];
        const ultimaResposta = ticket.respostes[lastKey];
        if (ultimaResposta) setUltimaResposta(ultimaResposta.id);

        // Recorrem totes les respostes
        ticket.respostes.forEach((resposta) => {
            if (ultimaResposta.id === resposta.id) {
                itemList.push(
                    <Element name={resposta.id} key={resposta.id}>
                        <Answer ticket={ticket} resposta={resposta} />
                    </Element>
                );
            } else {
                itemList.push(
                    <Answer ticket={ticket} key={resposta.id} resposta={resposta} />
                );
            }
        });

        // Guarda les respostes al estat per a renderitzar
        setRespostes(itemList);
    }, [ticket]);

    useEffect(() => {
        // Saltar a l'ultima resposta
        const acordionElement = document.getElementsByClassName("accordion")
        if(acordionElement[acordionElement.length -1] !== undefined){
           acordionElement[acordionElement.length -1].scrollIntoView({behavior: "smooth", block: "start"})
        }
      
    }, [ultimaResposta]);

    return (
        <>
            {respostes || ""}
            <Actions ticket={ticket} setTicket={setTicket} />
        </>
    );

};

export default TicketAnswers;

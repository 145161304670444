import React, { useEffect, useState } from "react";
import ListItemRecurrent from "./ListItemRecurrent/ListItemRecurrent";
import Table from "react-bootstrap/Table";
import FiltersBar from "./FiltersBar/FiltersBar";

import Pagination from "../../shared/Pagination/Pagination";

import axios from "axios";

export default () => {
  const [itemList, setItemList] = useState();

  // Pagination
  const [totalTicketsRecurrents, setTotalTicketsRecurrents] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  // Filters
  const [filter, setFilter] = useState();

  useEffect(() => {
    loadRecurrents();
    // eslint-disable-next-line
  }, []);

  // Si canvia la pàgina seleccionant el paginador, carregar tiquets d'aquella pàgina i amb aquells filtres
  useEffect(() => {
    if (currentPage) {
      loadRecurrents();
    }
    // eslint-disable-next-line
  }, [currentPage]);

  const loadRecurrents = () => {
    const params = {
      filtres: JSON.stringify(filter),
    };

    // Obté els tickets assignats a l'usuari X
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/tiquets/recurrents/${currentPage}/`,
        // `${process.env.REACT_APP_API_URL}api/tiquets/recurrents/`,
        {
          params,
          auth: {
            username: process.env.REACT_APP_API_USER,
            password: process.env.REACT_APP_API_PWD,
          },
          headers: {
            token: JSON.parse(localStorage.getItem("userLogged")).token,
            idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
          },
        }
      )
      .then((res) => {
        const recurrents = res.data.tiquetsRecurrents;
        renderList(recurrents);

        setTotalTicketsRecurrents(res.data.total);
        window.scrollTo(0, 0); // Anar al principi de la pàgina
      });
  };
  // Canvi de pàgina Pagination handle
  const handlePagination = (e) => {
    const selectedPage = e.selected + 1;
    setCurrentPage(selectedPage);
  };

  // Funció que recorre els tickets i els crea les files
  const renderList = (recurrents) => {
    const itemList = [];
    if (recurrents) {
      recurrents.forEach((recurrent) => {
        itemList.push(
          <ListItemRecurrent
            key={recurrent.id}
            recurrent={recurrent}
            loadRecurrents={loadRecurrents}
          />
        );
      });
      setItemList(itemList);
    } else {
      setItemList(
        <tr>
          <td colSpan="8">No s'ha trobat cap ticket recurrent</td>
        </tr>
      );
    }
  };

  return (
    <>
      <FiltersBar
        loadRecurrents={loadRecurrents}
        setFilter={setFilter}
        filter={filter}
      />
      <Table striped hover responsive="sm">
        <thead >
          <tr>
            <th style={{ width: "15%" }}>Client</th>
            <th  className='hidden lg:table-cell'style={{ width: "35%" }}>Assumpte</th>
            <th  className='hidden lg:table-cell'style={{ width: "15%" }}>Servei / Subservei</th>
            <th  className='hidden lg:table-cell'style={{ width: "5%" }}>Data inici</th>
            <th  className='hidden lg:table-cell'style={{ width: "5%" }}>Caducitat</th>
            <th  className='hidden lg:table-cell'style={{ width: "5%" }}>Freqüència</th>
            <th style={{ width: "15%" }}>Assignat</th>
            <th  style={{ width: "5%" }}>Estat</th>
            <th></th>
           
          </tr>
        </thead>
        <tbody>
          {itemList || (
            <tr>
              <td>Carregant tickets...</td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* PAGINATION */}
      {totalTicketsRecurrents > 25 ? (
        <Pagination
          handlePagination={handlePagination}
          totalRows={totalTicketsRecurrents}
        />
      ) : (
        ""
      )}
    </>
  );
};

import React, { useEffect, useState } from "react";
import Title from "../../components/shared/Title/Title";
import { Row } from "react-bootstrap";
import LogItem from "../../components/Tickets/TicketRecurrentLog/LogItem/LogItem";

import { useHistory } from "react-router-dom";
import axios from "axios";

const TicketRecurrentLog = ({ id }) => {
  const history = useHistory();

  const [logs, setLogs] = useState();
  useEffect(() => {
    // Obté el ticket X
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/logs/tiquets_recurrents/${id}/`,
        {
          auth: {
            username: process.env.REACT_APP_API_USER,
            password: process.env.REACT_APP_API_PWD,
          },
          headers: {
            token: JSON.parse(localStorage.getItem("userLogged")).token,
            idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
          },
        }
      )
      .then((res) => {
        // console.log(res);
        const logs = res.data;

        // Guardem les dades del log al state
        setLogs(logs);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  const handleEditar = () => {
    history.push("/editar-ticket-recurrent/" + id + "/");
  };

  return (
    <>
      {logs ? (
        <>
          <Title
            title="Tickets"
            title2="Tickets recurrents"
            title3={`#${id} - Logs`}
            titleLink="/tickets"
            title2Link={"/tickets-recurrents"}
            button="Editar ticket recurrent"
            buttonAction={handleEditar}
          />
          <Row>
            {logs.map((log,i) => {
              return (
                <LogItem
                key={i}
                  nomOperador={log.nomOperador}
                  fotoOperador={log.fotoOperador}
                  missatge={log.missatge}
                  data={log.data}
                  missatgesTractats={log.missatgesTractats}
                />
              );
            })}
          </Row>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default TicketRecurrentLog;

import React from "react";
import { Row, Col, InputGroup, Form } from "react-bootstrap";
import Moment from "react-moment";

export default ({ ticket, setTicket, isResposta, resposta, showEdit }) => {
    const editTicketHeader = () => {
        return <>
            <Form.Group controlId="exampleForm.ControlTextarea1">
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend">
                            Contacte
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                        type="text"
                        value={ticket.contacte}
                        maxLength="100"
                        onChange={(contacte) => {
                            setTicket({
                                ...ticket,
                                contacte: contacte.target.value,
                            });
                        }}
                    />
                </InputGroup>
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlTextarea1">
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend">
                            Email
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                        type="email"
                        value={ticket.email}
                        maxLength="100"
                        onChange={(email) => {
                            setTicket({
                                ...ticket,
                                email: email.target.value,
                            });
                        }}
                    />
                </InputGroup>
            </Form.Group>
        </>
    }
    const editTicketHeaderResponse = () => {
        return <>
            {/* CAPÇALERA TICKET I RESPOSTA - CAMPS CONTACTE/TECNIC I EMAIL */}


            <p style={{ textAlign: "left" }}>
                <>
                    <i className={"fa fa-user-o"}></i>&nbsp;&nbsp;
                    <strong>
                        {!resposta || resposta.isClient === "1"
                            ? "Contacte"
                            : "Tècnic"}
                        :{" "}
                    </strong>
                    {!resposta ? ticket.contacte : resposta.nomEmissor}
                </>
            </p>
            <p style={{ textAlign: "left" }}>
                <>
                    <i className={"fa fa-envelope-o"}></i>&nbsp;&nbsp;
                    <strong>Email: </strong>
                    <a
                        href={`mailto: ${!resposta || resposta.isClient === "1"
                            ? ticket.email
                            : resposta.emailEmissor
                            }`}
                    >
                        {!resposta ? ticket.email : resposta.correuEmissor}
                    </a>
                </>
            </p>
        </>
    }

    return (
        <>
            {resposta && (
                <div style={{ position: "absolute", right: 10, }}  >
                    <i className={"fa fa-plus"}></i>&nbsp;&nbsp;
                </div>
            )}
            <Row>

                {/* CAPÇALERA TICKET I RESPOSTA - COLUMNA ESQUERRE */}
                <Col sm={5}>
                    <div>
                        {/* EDICIO CAPÇALERA TICKET */}
                        {showEdit && !isResposta ? (editTicketHeader()) : (editTicketHeaderResponse())}
                    </div>
                    {/* CAPÇALERA TICKET I RESPOSTA - CAMP DATA */}
                    <div style={{ textAlign: "left", marginBottom: "1rem" }}>
                        <i className={"fa fa-calendar"}></i>&nbsp;&nbsp;
                        <strong>
                            {!resposta && "Data"}
                            {resposta?.tipus === "1" && "Resposta"}
                            {resposta?.tipus === "2" && "Reenviament"}
                            {resposta?.tipus === "3" && "Nota"}
                            :{" "}
                        </strong>
                        <Moment format="DD/MM/YYYY HH:mm" unix>
                            {!resposta ? ticket.data : resposta.data}
                        </Moment>

                    </div>


                    {/* CAPÇALERA TICKET - SI TE TELEFON, CAMP TELÈFON */}
                    {ticket.telefon ? (
                        <div style={{ textAlign: "left" }}>
                            {showEdit && !resposta ? (
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="inputGroupPrepend">
                                                Telèfon
                                            </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            type="text"
                                            value={ticket.telefon}
                                            maxLength="100"
                                            onChange={(telefon) => {
                                                setTicket({
                                                    ...ticket,
                                                    telefon: telefon.target.value,
                                                });
                                            }}
                                        />
                                    </InputGroup>
                                </Form.Group>
                            ) : (
                                <>
                                    <i className={"fa fa-phone"}></i>&nbsp;&nbsp;
                                    <strong>Telèfon: </strong>
                                    <a
                                        href={`tel: ${!resposta || resposta.isClient === 1
                                            ? ticket.telefon
                                            : resposta.telefonEmissor
                                            }`}
                                    >
                                        {!resposta || resposta.isClient === 1
                                            ? ticket.telefon
                                            : resposta.telefonEmissor}
                                    </a>
                                </>
                            )}
                        </div>
                    ) : (
                        ""
                    )}
                </Col>
                {/* CAPÇALERA TICKET I RESPOSTA - COLUMNA DRETA */}
                <Col sm={6}>

                    <div style={{ textAlign: "left" }}>
                        {/* CAPÇALERA RESPOSTA - CORREU DESTINATARI/RECEPTOR */}
                        {resposta && resposta.correuReceptor ? (
                            <p>
                                <i className={"fa fa-envelope-o"}></i>&nbsp;&nbsp;
                                <strong>Per a: </strong>
                                <a href={`mailto: ${resposta.correuReceptor}`}>
                                    {resposta.correuReceptor}
                                </a>
                            </p>
                        ) : (
                            ""
                        )}
                        {/* CAPÇALERA TICKET - CORREU DESTINATARI/RECEPTOR */}
                        {!resposta && ticket.destinatari ? (
                            <p>
                                <i className={"fa fa-envelope-o"}></i>&nbsp;&nbsp;
                                <strong>Per a: </strong>
                                <a href={`mailto: ${ticket.destinatari}`}>
                                    {ticket.destinatari}
                                </a>
                            </p>
                        ) : (
                            ""
                        )}
                        {resposta?.dataAct && (
                            <div style={{ textAlign: "left", marginBottom: "1rem" }}>
                                <i className={"fa fa-calendar"}></i> &nbsp;
                                <strong>Edició:</strong>&nbsp;

                                <Moment format="DD/MM/YYYY HH:mm" unix>
                                    {resposta?.dataAct}
                                </Moment>
                            </div>

                        )}
                    </div>
                    <div style={{ textAlign: "left" }}>
                        {/* CAPÇALERA TICKET - CAMP CC */}

                        {!resposta && ticket.cc ? (
                            <>
                                <i className={"fa fa-envelope-o"}></i>&nbsp;&nbsp;
                                <strong>CC: </strong>
                                {ticket.cc.map((cc, key) => {
                                    return (
                                        <>
                                            {key > 0 ? ", " : ""}
                                            <a href={`mailto: ${cc.label}`}>{cc.label}</a>
                                        </>
                                    );
                                })}
                            </>
                        ) : (
                            ""
                        )}
                        {/* CAPÇALERA RESPOSTA - CAMP CC */}
                        {resposta && resposta.cc && resposta.cc !== "[]" ? (
                            <>
                                <i className={"fa fa-envelope-o"}></i>&nbsp;&nbsp;
                                <strong>CC: </strong>
                                {resposta.ccSelect.map((cc, key) => {
                                    return (
                                        <>
                                            {key > 0 ? ", " : ""}
                                            <a href={`mailto: ${cc.label}`}>{cc.label}</a>
                                        </>
                                    );
                                })}
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                    <div style={{ textAlign: "left" }}>
                        {/* CAPÇALERA TICKET - CAMP BCC */}
                        {!resposta && ticket.bcc ? (
                            <>
                                <i className={"fa fa-envelope-o"}></i>&nbsp;&nbsp;
                                <strong>BCC: </strong>
                                {ticket.bcc.map((bcc, key) => {
                                    return (
                                        <>
                                            {key > 0 ? ", " : ""}
                                            <a href={`mailto: ${bcc.label}`}>{bcc.label}</a>
                                        </>
                                    );
                                })}
                            </>
                        ) : (
                            ""
                        )}
                        {/* CAPÇALERA RESPOSTA - CAMP BCC */}
                        {resposta && resposta.bcc && resposta.bcc !== "[]" ? (
                            <>
                                <i className={"fa fa-envelope-o"}></i>&nbsp;&nbsp;
                                <strong>BCC: </strong>
                                {resposta.bccSelect.map((bcc, key) => {
                                    return (
                                        <>
                                            {key > 0 ? ", " : ""}
                                            <a href={`mailto: ${bcc.label}`}>{bcc.label}</a>
                                        </>
                                    );
                                })}
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                </Col>
            </Row>

            <Row>
                <Col sm={12} style={{ paddingTop: "1rem" }}>
                    <hr />
                </Col>
            </Row>
        </>
    );
};

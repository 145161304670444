const TecnicStats = ({data}) => {
    
    const {foto, nom, oberts, assignats, pendents} = data
    return (
        <div  title={nom} className='col-span-1 shadow-md bg-gradient-to-r from-bootstrap relative to-blue-300  w-48 h-64'  >
            {/* <img src={foto} alt="tecnic" className='w-full h-full' />  */}
            <div className='absolute border    font-semibold text-white  w-full h-full flex  flex-col left-0 pl-1 py-2 bottom-0'>
               
            <div className='flex w-full h-full items-end justify-around mb-2'>
            <div  className=' bg-green-500 border-white border w-1/6' style={{height: `${assignats*2+20}px`}}>{assignats}</div>
            <div  className=' bg-yellow-500 border-white border  w-1/6' style={{height: `${oberts*2+20}px`}}>{oberts}</div>
            <div  className=' bg-red-500 border-white border w-1/6' style={{height: `${pendents*2+20}px `}}>{pendents}</div>

            </div>
            <span className='text-xl '>{nom}</span>
            
            </div>
            
        </div>
    )
}

export default TecnicStats

import React, { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { Form, InputGroup } from "react-bootstrap";
import Select from "react-select";
import axios from "axios";

const Filters = ({ loadRecurrents, filter, setFilter }) => {
  const { addToast } = useToasts();

  // Estat amb dades desplegables
  const [empreses, setEmpreses] = useState();
  const [serveis, setServeis] = useState();
  const [subServeis, setSubServeis] = useState();
  const [estats, setEstats] = useState();
  const [tecnics, setTecnics] = useState();
  const [grups, setGrups] = useState();

  // Al carregar la screen
  useEffect(() => {
    // Carreguem les dades dels desplegables
    // PETICIÓ API EMPRESES
    const apiEmpreses = axios.get(
      process.env.REACT_APP_API_URL + "api/empreses/",
      {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      }
    );

    // PETICIÓ API SERVEIS
    const apiServeis = axios.get(
      process.env.REACT_APP_API_URL + "api/serveis/",
      {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      }
    );

    // PETICIO API SUBSERVEIS
    const apiSubServeis = axios.get(
      `${process.env.REACT_APP_API_URL}api/serveis/subserveis/`,
      {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      }
    );

    // ESTATS
    const apiEstats = axios.get(process.env.REACT_APP_API_URL + "api/estats/", {
      auth: {
        username: process.env.REACT_APP_API_USER,
        password: process.env.REACT_APP_API_PWD,
      },
      headers: {
        token: JSON.parse(localStorage.getItem("userLogged")).token,
        idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
      },
    });

    // PETICIÓ API TECNICS
    const apiTecnics = axios.get(
      process.env.REACT_APP_API_URL + "api/tecnics/",
      {
        auth: {
          username: process.env.REACT_APP_API_USER,
          password: process.env.REACT_APP_API_PWD,
        },
        headers: {
          token: JSON.parse(localStorage.getItem("userLogged")).token,
          idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
        },
      }
    );

    // PETICIÓ API GRUPS
    const apiGrups = axios.get(process.env.REACT_APP_API_URL + "api/grups/", {
      auth: {
        username: process.env.REACT_APP_API_USER,
        password: process.env.REACT_APP_API_PWD,
      },
      headers: {
        token: JSON.parse(localStorage.getItem("userLogged")).token,
        idOperador: JSON.parse(localStorage.getItem("userLogged")).id,
      },
    });

    axios
      .all([
        apiEmpreses,
        apiServeis,
        apiSubServeis,
        apiEstats,
        apiTecnics,
        apiGrups,
      ])
      .then(
        axios.spread((...responses) => {
          const responseEmpreses = responses[0].data;
          const responseServeis = responses[1].data;
          const responseSubServeis = responses[2].data;
          const responseEstats = responses[3].data;
          const responseTecnics = responses[4].data;
          const responseGrups = responses[5].data;

          /* EMPRESES SELECT OPTIONS */
          const empresesList = [];
          responseEmpreses.forEach((empresa) => {
            empresesList.push({
              value: empresa.id,
              label: empresa.nom,
            });
          });
          setEmpreses(empresesList);

          /* SERVEIS SELECT OPTIONS */
          const serveisList = [];
          responseServeis.forEach((servei) => {
            serveisList.push({
              value: servei.id,
              label: servei.nom,
            });
          });
          setServeis(serveisList);

          /* SUBSERVEIS SELECT OPTIONS */
          const subServeisList = [];
          responseSubServeis.forEach((subServei) => {
            subServeisList.push({
              value: subServei.id,
              label: subServei.nom,
            });
          });
          setSubServeis(subServeisList);

          /* ESTATS SELECT OPTIONS */
          const estatsList = [];
          responseEstats.forEach((estat) => {
            estatsList.push({
              value: estat.id,
              label: estat.nom,
            });
          });
          setEstats(estatsList);

          /* TECNICS SELECT OPTIONS */
          const tecnicsList = [{ value: "null", label: "Sense assignar" }];
          responseTecnics.forEach((tecnic) => {
            tecnicsList.push({
              value: tecnic.id,
              label: tecnic.nom,
            });
          });
          setTecnics(tecnicsList);

          /* GRUPS SELECT OPTIONS */
          const grupsList = [];
          responseGrups.forEach((grup) => {
            grupsList.push({
              value: grup.id,
              label: grup.nom,
            });
          });
          setGrups(grupsList);
        })
      )
      .catch((errors) => {
        // TOAST ERROR
        addToast("S'ha produït un error", {
          appearance: "error",
        });
      });

    // eslint-disable-next-line
  }, []);

  // Aplicar el filtre sempre que es modifiqui el filtre state
  useEffect(() => {
    // console.log(filter);
    if (filter) {
      loadRecurrents({ reloadCurrentPage: true }); // True = Reload currentPage
    }
    // eslint-disable-next-line
  }, [filter]);

  return (
    <div className="mt-4 mx-2 text-xs lg:text-base grid lg:grid-cols-3 grid-cols-1 grid-flow-row">
      <div className='lg:-ml-2 lg:mr-2'>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text id="inputGroupPrepend">Client</InputGroup.Text>
            </InputGroup.Prepend>

            {empreses ? (
              <Select
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
                className="select"
                options={empreses}
                value={filter ? filter.idClient : ""}
                placeholder="Seleccionar client"
                isMulti
                onChange={(idClient) => {
                  setFilter({
                    ...filter,
                    idClient,
                  });
                }}
              />
            ) : (
              ""
            )}
          </InputGroup>
        </Form.Group>
      </div>
      <div className=' lg:mr-2'>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text id="inputGroupPrepend">Servei</InputGroup.Text>
            </InputGroup.Prepend>
            {serveis ? (
              <Select
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
                className="select"
                options={serveis}
                isMulti
                value={filter ? filter.idServei : ""}
                placeholder="Seleccionar serveis"
                onChange={(idServei) => {
                  setFilter({
                    ...filter,
                    idServei,
                  });
                }}
              />
            ) : (
              ""
            )}
          </InputGroup>
        </Form.Group>
      </div>
      <div className=' lg:mr-2'>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text id="inputGroupPrepend">Grup</InputGroup.Text>
            </InputGroup.Prepend>
            {grups ? (
              <Select
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
                className="select"
                options={grups}
                isMulti
                value={filter ? filter.idGrup : ""}
                placeholder="Seleccionar grups"
                onChange={(idGrup) => {
                  setFilter({
                    ...filter,
                    idGrup,
                  });
                }}
              />
            ) : (
              ""
            )}
          </InputGroup>
        </Form.Group>
      </div>
      <div className='lg:-ml-2 lg:mr-2'>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text id="inputGroupPrepend">Estat</InputGroup.Text>
            </InputGroup.Prepend>
            {estats ? (
              <Select
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
                className="select"
                options={estats}
                isMulti
                value={filter ? filter.idEstat : ""}
                placeholder="Seleccionar estats"
                onChange={(idEstat) => {
                  setFilter({
                    ...filter,
                    idEstat,
                  });
                }}
              />
            ) : (
              ""
            )}
          </InputGroup>
        </Form.Group>
      </div>

      <div className='lg:mr-2'>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text id="inputGroupPrepend">
                Sub servei
              </InputGroup.Text>
            </InputGroup.Prepend>
            {subServeis ? (
              <Select
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
                className="select"
                options={subServeis}
                isMulti
                value={filter ? filter.idSubServei : ""}
                placeholder="Seleccionar sub serveis"
                onChange={(idSubServei) => {
                  setFilter({
                    ...filter,
                    idSubServei,
                  });
                }}
              />
            ) : (
              ""
            )}
          </InputGroup>
        </Form.Group>
      </div>


      <div className='lg:mr-2'>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text id="inputGroupPrepend">Assignat</InputGroup.Text>
            </InputGroup.Prepend>
            {tecnics ? (
              <Select
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
                className="select"
                options={tecnics}
                isMulti
                value={filter ? filter.idAssignat : ""}
                placeholder="Seleccionar tècnics"
                onChange={(idAssignat) => {
                  setFilter({
                    ...filter,
                    idAssignat,
                  });
                }}
              />
            ) : (
              ""
            )}
          </InputGroup>
        </Form.Group>
      </div>
    </div>
  );
};

export default Filters;

import React from "react";
// import styles from "../../Header/Header.module.css";
import Button from "react-bootstrap/Button";
// import LangSelector from "../LangSelector/LangSelector";
import { Link } from "react-router-dom";
export default function Actions({ className }) {
	const handleLogout = () => {
		window.location.href = "/logout";
	};

	return (
		<div className={` hidden lg:block ${className} `}>
			<Link to="/dashboard">
				<Button
					type="primary"
					className="text-white transition duration-300 border-blue-900  hover:bg-white hover:text-blue-900"
				>
					Dashboard
				</Button>
			</Link>
			&nbsp;&nbsp;&nbsp;
			<Link to="/nou-ticket">
				<Button
					type="primary"
					className="text-white transition duration-300 border-blue-900  hover:bg-white hover:text-blue-900"
				>
					Crear ticket
				</Button>
			</Link>
			&nbsp;&nbsp;&nbsp;
			<Link to="/nou-email">
				<Button
					className="text-white transition duration-300 border-blue-900  hover:bg-white hover:text-blue-900"
					type="secondary"
				>
					Enviar email
				</Button>
			</Link>
			&nbsp;&nbsp;&nbsp;
			<Link to="/tickets-recurrents">
				<button className="text-white transition duration-300 bg-red-800 border-t border-b border-l border-r border-red-800  gestion-btn hover:bg-white hover:text-red-800">
					Gestionar tickets recurrents
				</button>
			</Link>
			&nbsp;&nbsp;&nbsp;
			<Button
				onClick={handleLogout}
				className="text-white transition duration-300 border-blue-900  hover:bg-white hover:text-blue-900"
				type="secondary"
			>
				Tancar sessió
			</Button>
			{/* <LangSelector className={styles.langSelector} /> */}
		</div>
	);
}

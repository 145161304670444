import React from "react";
import logoGestinet from "../../../../assets/images/logo-gestinet.png";
import logoAlbarans from "../../../../assets/images/logo-albarans.png";
import logoZammad from "../../../../assets/images/logo-zammad.png";
import { Link, useLocation } from "react-router-dom";
import { BiLink } from "react-icons/bi"

export default function Logo(props) {
  const location = useLocation();
  const currentUrl = window.location.href;

  return (
    <div className={` ${location.pathname === '/tickets' ? 'xs:hidden' : 'block'} xs:mx-auto xs:mr-24 ml-14 ${props.className}`}>
      {!currentUrl.includes('zammad') ? (
        <Link to="/">
          <img src={logoGestinet} alt="logo" className="w-24 pr-2 lg:w-52" />
        </Link>
      ) : (
        <a href="https://zammad.gestinet.com/#dashboard" className="flex flex-row items-center justify-center">
          <img src={logoAlbarans} alt="logo" className="w-24 lg:w-16" />
          <BiLink size="36" className="mx-1"/>
          <img src={logoZammad} alt="logo" className="w-24 lg:w-14" />
        </a>
      )}
    </div>
  );
}
